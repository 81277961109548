<template>
  <!-- 顶部tabbar -->
  <div class="TopBar">
    <div class="TopBar_content_l">
      <img src="@/assets/images/home/user1.png" alt="" />
      <div>
        <userSign />
      </div>

      <!-- <router-link to="/login/signIn">{{ $t("home.top.Sign") }} </router-link>
        {{ $t("home.top.or") }}
        <router-link to="/login/signIn"> {{ $t("home.top.Join") }}</router-link> -->
    </div>
    <ul class="TopBar_content_r">
      <li>
        <i
          class="iconfont icon-diqiu"
          style="margin-right: 6px; vertical-align: middle"
        ></i>

        <div>
          <language />
        </div>
      </li>
      <li>
        <div>
          <Currency />
        </div>
      </li>
      <!-- <li>
           <i class="iconfont icon-weibiaoti-3"></i>
          
          <div>
            <Nation />
          </div>
        </li> -->
    </ul>
  </div>
</template>

<script>
import userSign from "@/components/userSign.vue"; //語言切換
import language from "@/components/language.vue"; //語言切換
import Currency from "@/components/currency"; //货币
import Nation from "@/components/nation"; //国家
export default {
  components: {
    userSign,
    language,
    Currency,
    Nation,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.TopBar {
  width: 1380px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  .TopBar_content_l {
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      display: inline-block;
      margin-right: 4px;
      vertical-align: middle;
      width: 14px;
      height: 14px;
    }
    a {
      display: inline-block;
      color: $importantColor;
    }
  }
  .TopBar_content_r {
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      cursor: pointer;
      img {
        margin-right: 4px;
        display: inline-block;
      }
      div {
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
}
</style>