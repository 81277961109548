<template>
  <div class="container" >
    <div id="goodsZoom">
      <div id="zoomTop">
        <!--        小图框-->
        <div id="smallPic" @mouseover="handlerMouseOver" @mouseleave="handlerMouseLeave"
             @mousemove="handlerMouseMove">
          <img :src="smallImgSrc">
          <!--          蒙版元素-->
          <div id="mask" v-show="show"></div>
        </div>
        <!--        大图框-->
        <div id="bigPic" v-show="show">
          <img :src="bigImgSrc" id="bigImg">
        </div>
      </div>
      <div id="zoomBottom">
        <a href="javascript:;" class="prev" @click="handlerPrev"><img src="@/assets/images/supply/prev.png" alt=""> </a>
        <div id="picList">
          <ul v-if="imgList && imgList.length > 0">
            <li  v-for="(item,index) in imgList" @click="thumbnailClick(item,index)" :key="index" :class="{selected: index === selected}">
              <img :src="item.value">
            </li>
          </ul>
        </div>
        <a href="javascript:;" class="next" @click="handlerNext"> <img src="@/assets/images/supply/next.png" alt=""> </a>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {

  props: {
    imgList: {
      type: Array,
      //  required: true,
       default: function () {
      return [];
    }
    },
  },
    watch:{
    imgList:{
      handler(n,o){

       if (n!=null && n.length > 0 ) {
        this.bigImgSrc = n[0].value;
        this.smallImgSrc = n[0].value;
       }
      }
    }
  },
  data() {
    return {
      show: false,//是否显示
      bigImgSrc: '',
      smallImgSrc: '',
      start: 0,
      selected: 0, // 当前选中的缩略图索引值

    }
  },

  // created() {
  //   console.log(this.imgList)
    
 
  // },
  methods: {
    //鼠标移入事件
    handlerMouseOver() {
      this.show = true;
    },
    //鼠标移出事件
    handlerMouseLeave() {
      this.show = false;
    },
    //鼠标移动事件
    handlerMouseMove(data) {
      const smallPic = document.getElementById("smallPic");
      const bigPic = document.getElementById("bigPic");
      const bigImg = document.getElementById("bigImg");
      const zoomTop = document.getElementById("zoomTop");
      const mask = document.getElementById("mask");
      //蒙版边界设置
      let left = data.clientX - smallPic.getBoundingClientRect().left - mask.offsetWidth / 2;
      let top = data.clientY - smallPic.getBoundingClientRect().top - mask.offsetHeight / 2;
      //边界判断
      if (left < 0) {
        left = 0;
      } else if (left > smallPic.clientWidth - mask.offsetWidth) {
        left = smallPic.clientWidth - mask.offsetWidth;
      }
      if (top < -1) {
        top = -1;
      } else if (top > smallPic.clientHeight - mask.offsetHeight) {
        top = smallPic.clientHeight - mask.offsetHeight;
      }
      mask.style.left = left + "px";
      mask.style.top = top + "px";
      let scale = (smallPic.clientWidth - mask.offsetWidth) / (bigImg.offsetWidth - bigPic.clientWidth);
  
      bigImg.style.left = -left / scale + "px";
      bigImg.style.top = -top / scale + "px";
    },
    //缩略图点击效果
    thumbnailClick(data,index) {
      this.bigImgSrc = data.value;
      this.smallImgSrc = data.value;
       this.selected = index; // 更新selected属性的值
    },
    //点击前一个
    handlerPrev() {
      let ul = document.querySelector('#goodsZoom #zoomBottom #picList ul');
      let liNodes = document.querySelectorAll('#goodsZoom #zoomBottom #picList li');
      console.log()
      if (liNodes.length === 0  || liNodes.length<=6  ) {
     
        return;
      }
      //步长
      let step = (liNodes[0].offsetWidth + 20) * 2;
      this.start -= step;
      if (this.start < 0) {
        this.start = 0;
      }
      ul.style.left = -this.start + "px";
    },
    //点击下一个

handlerNext() {
  const ul = document.querySelector('#goodsZoom #zoomBottom #picList ul');
  const liNodes = document.querySelectorAll('#goodsZoom #zoomBottom #picList li');
  if (liNodes.length === 0 || liNodes.length<=6 ) {
    return;
  }
  //步长
  const step = (liNodes[0].offsetWidth + 20) * 2;
  //总体运动的距离值 = ul的宽度 - div框的宽度 = (图片的总数 - div中显示的数量) * （li的宽度 + 20）
  const endPosition = (liNodes.length - 5) * (liNodes[0].offsetWidth + 20);
  this.start += step;
  if (this.start > endPosition) {
    this.start = endPosition;
  }
  ul.style.left = -this.start + "px";

//   // 更新大图和小图的展示
//   const index = Math.floor(this.start / step);
//   this.bigImgSrc = this.imgList[index].b;
//   this.smallImgSrc = this.imgList[index].s;
},
  },
};
</script>
 
<style scoped lang="scss">
.container {
  // margin: 5px 0 15px;
  // overflow: hidden;
  background: #fff;
 
  #goodsZoom {
    #zoomTop {
      width: 100%;
      position: relative;
 
      #smallPic {
        width: 100%;
        height: 430px;
        
        position: relative;
 
        img {
            width: 100%;
            height: 100%;
        }
 
        #mask {
          width: 200px;
          height: 200px;
        background: rgb(255 255 255 / 60%);

          border: 1px solid #ddd;
          position: absolute;
          left: 0px;
          top: -1px;
        }
      }
 
      #bigPic {
        width: 400px;
        height: 400px;
        border: 1px solid #ddd;
        left: 440px;
        top: 0px;
        position: absolute;
        overflow: hidden;
        z-index: 9999;
 
        #bigImg {
          width: 800px;
          height: 800px;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
    }
 
    #zoomBottom {
      width: 100%;
      margin-top: 19px;
      display: flex;
 
      a {
        // width: 33px;
        text-align: center;
      
        display: flex;
        align-items: center;
 
        &:first-child {
          margin-right: 4px;
        }
      }
 
      #picList {
        width: 364px;
        height: 54px;
        float: left;
        overflow: hidden;
        position: relative;
 
        ul {
          white-space: nowrap;
          font-size: 0px;
          position: absolute;
          left: 0px;
          transition: 0.5s;
      .selected{
        border: 2px solid #00B968;
      }
          li {
            width: 54px;
            height: 54px;
           
            margin-right: 8px;
            display: inline-block;
            border: 2px solid none;
            border-radius: 8px;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
 
            img {
              width: 100%;
              height: 100%;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
