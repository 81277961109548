<template>
  <el-dialog
    :title="$t('send.Inquiry')"
    :visible.sync="Show"
    :close-on-click-modal="false"
    custom-class="message"
    :before-close="handleClose"
  >
    <!-- 内容区域 -->
    <h1>{{ $t("send.To") }}：{{ infoList.userName }} | {{infoList.companyName}}</h1>
    <div class="message_content">
      <div class="t_img">
        <img :src="infoList.picture" alt="" />
        <p>{{ infoList.name }}</p>
      </div>
      <div class="b_form">
        <h6>* {{ $t("send.Detailed") }}</h6>
        <p>{{ $t("send.Enter") }}</p>
        <p>
          <el-input
            type="textarea"
            :rows="4"
            maxlength="500"
            :placeholder="$t('send.please')"
            v-model="textarea"
          >
          </el-input>
        </p>
        <p>
          <el-checkbox v-model="checked">{{ $t("send.Agree") }}</el-checkbox>
        </p>
        <p>{{ $t("send.Please", { content: infoList.logonEmail }) }}</p>
      </div>
    </div>
    <el-button
      type="primary"
      :class="delay ? 'message_btn delay' : 'message_btn'"
      @click="send"
      :loading="delay"
      >{{ $t("send.btn") }}</el-button
    >
  </el-dialog>
</template>

<script>
import { sendInquire } from "@/api/supply";
export default {
  props: {
    Show: {
      type: Boolean,
    },
    infoList: {
      //详情页数据
      type: Object,
    },
  },
  data() {
    return {
      textarea: "",
      checked: false,
      delay: false,
    };
  },

  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    send() {
      if (this.checked == false) {
        this.$message(this.$t("staging.inquiry.tips"));
        return;
      }
      if (!this.textarea){
        this.$message("Content cannot be empty");
        return;
      }
      this.delay = true;
      sendInquire({
        productCode: this.$route.query.productCode
          ? this.$route.query.productCode
          : this.infoList.productCode,
        content: this.textarea,
      }).then((res) => {
        if (res.code == "200") {
          this.textarea = "";
          this.checked = false;

          // this.$message({
          //   message: res.message,
          //   type: "success",
          // });

          this.$alert("Inquiry sent successfully", "Warm reminder", {
            confirmButtonText: "Close",
            callback: (action) => {
              this.$emit("handleClose");
            },
          });
        }
        this.delay = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .message {
  border-radius: 10px;
  .el-dialog__header {
    border-bottom: 1px solid #e8e8e8;
  }
  .el-dialog__body {
    padding: 21px 24px;
    h1 {
      color: #333333;
      font-size: 16px;
      margin-bottom: 21px;
    }
  }
  .message_content {
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    background: #f6f6f6;
    .t_img {
      display: flex;
      img {
        width: 50px;
        height: 50px;
        border-radius: 4px;
      }
      p {
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
      }
    }
    .b_form {
      border-radius: 10px;
      margin-top: 20px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      h6 {
        color: #333333;
        font-size: 14px;
        margin-bottom: 12px;
      }
      p {
        color: #999999;
        margin-bottom: 12px;
        font-size: 14px;
        color: 666666;
        textarea {
          color: #999999;
          resize: none;
        }
        span {
          color: #333333;
        }
      }
    }
  }
  .message_btn {
    padding: 10px 80px;
    color: #ffffff;
    background: #00b968;
    border-radius: 18px;
    margin-top: 20px;
    width: 268px;
    display: block;
    margin: 0 auto;
    &:active {
      background: #009d58;
    }
  }
  .delay {
    pointer-events: none;
    cursor: default;
  }
}
</style>
