import request from '@/utils/request'

// 工作台首页数据统计
export function statistics() {
  return request({
    url: '/v1/web/api/panelBoard/statistics',
    method: 'post'
  })
}
// 工作台首页推荐列表
export function recommend() {
  return request({
    url: '/v1/web/api/panelBoard/recommend',
    method: 'post'
  })
}
// 工作台获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/v1/web/api/webuser/getUserInfo',
    method: 'post',
    data: data
  })
}
// 工作台修改用户信息
export function modifyUserInfo(data) {
  return request({
    url: '/v1/web/api/webuser/modifyUserInfo',
    method: 'post',
    data: data
  })
}
// 发送验证码
export function sendModifyEmail(data) {
  return request({
    url: '/v1/web/api/webuser/sendModifyEmail',
    method: 'post',
    data: data
  })
}
// 验证验证码
export function verifyModifyCaptcha(data) {
  return request({
    url: '/v1/web/api/webuser/verifyModifyCaptcha',
    method: 'post',
    data: data
  })
}
// 修改密码
export function modifyPassword(data) {
  return request({
    url: '/v1/web/api/webuser/modifyPassword',
    method: 'post',
    data: data
  })
}
// 查询单位列表
export function getAllUnit() {
  return request({
    url: '/v1/web/api/unit/getAllUnit',
    method: 'post'
  })
}
