import request from '@/utils/request'

// 登录
export function loginByCode(data) {
  return request({
    url: '/v1/web/api/login/login',
    method: 'post',
    data: data
  })
}

// 注册模块
// 发送验证码
export function sendCaptcha(data) {
  return request({
    url: '/v1/web/api/login/sendCaptcha',
    method: 'post',
    data: data
  })
}
// 注册账号
export function registerApi(data) {
  return request({
    url: '/v1/web/api/login/verifyCaptcha',
    method: 'post',
    data: data
  })
}


//忘记密码模块
// 发送邮件
export function forgetPasswordSendCodeApi(data) {
  return request({
    url: '/v1/web/api/login/sendCaptcha2Email',
    method: 'post',
    data: data
  })
}
//校验邮箱验证码
export function verifyEmailCodeApi(data) {
  return request({
    url: '/v1/web/api/login/verifyCaptcha',
    method: 'post',
    data: data
  })
}
//重置密码

export function resetPwdApi(data) {
  return request({
    url: '/v1/web/api/login/resetPwd',
    method: 'post',
    data: data
  })
}