<template>
    <div class="container">
        <!-- 导航栏组件 -->
        <BreadCrumbs />
        <div></div>
        <ExhibitionContent :exhibitionData="exhibitionData" />
        <Recommend />
        <Message />

    </div>
</template>
<script>

import BreadCrumbs from '@/components/breadCrumbs.vue'
import ExhibitionContent from './components/exhibitionContent.vue'
import Recommend from './components/recommend.vue'
import Message from '@/components/message.vue'
import {getExhibitionDetail} from '@/api/news'

export default {

    metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.exhibition.detail.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.exhibition.detail.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.exhibition.detail.description"),
          },
        ],
      };
    },
  },

    components: {
        BreadCrumbs, ExhibitionContent, Recommend, Message
    },
    data() {
        return {
            exhibitionData: ''
        }
    },

    watch: {
    "$route.path": {
      handler: function (newVal, oldVal) {
            this.getData()
      },
      immediate: true,
    },
  },
    mounted() {
        this.getData()
    },
    destroyed() {
    },
    methods: {
        getData() {
            if (this.$route.query.id) {
                getExhibitionDetail({ id: this.$route.query.id }).then((res) => {
                    if (res.code == '200') {
                        this.exhibitionData = res.data
                    }
                })
            }
        }

    }
}

</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    background: #F6F6F6;


}
</style>
