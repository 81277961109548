import request from '@/utils/request'


// 分类tree
export  function getCategoryTree(data) {
    return request({
      url: '/v1/web/api/web/category/getCategoryTree',
      method: 'post',
      data: data
    })
}

// 列表
export  function getlist (data,type) {

  return request({
    url: '/v1/web/api/web/product/'+type+'/list',
    method: 'post',
    data: data
  })
}

// 详情
export  function gedetail (data,type) {

  return request({
    url: '/v1/web/api/web/product/detail/'+type,
    method: 'post',
    data: data
  })
}

// 详情列表

export  function gedetailList (data,type) {
  return request({
    url: '/v1/web/api/web/product/acquireOthers/'+type,
    method: 'post',
    data: data
  })
}
// 查询收藏
export  function getFavoriteState (data) {
  return request({
    url: '/v1/web/api/favorite/getFavoriteState',
    method: 'post',
    data: data
  })
}

// 收藏
export  function sendFavorite (data) {
  return request({
    url: '/v1/web/api/favorite/sendFavorite',
    method: 'post',
    data: data
  })
}

// 浏览
export  function sendBrownse (data) {
  return request({
    url: '/v1/web/api/brownse/sendBrownse',
    method: 'post',
    data: data
  })
}
// 询盘
export  function sendInquire (data) {
  return request({
    url: '/v1/web/api/inquire/sendInquire',
    method: 'post',
    data: data
  })
}

// 分享
export  function sendShare (data) {
  return request({
    url: '/v1/web/api/share/sendShare',
    method: 'post',
    data: data
  })
}




