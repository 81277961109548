<template>
    <div class="contactUs">
        <img class="img" src="@/assets/images/helpCenter/contactUs.jpg" alt="">
        <div class="contactContent">
            <div class="title">{{$t('footer.Contact')}}</div>
            <div class="contentBox">
                <img class="contentImg" src="@/assets/images/helpCenter/contactLocation.png" alt="">
                <span>{{$t('Help.contactUs.address')}}</span>
            </div>
            <div class="contentBox">
                <img class="contentImg" src="@/assets/images/helpCenter/contactEmail.png" alt="">
                <span>{{$t('Help.contactUs.email')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.Contact.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.Contact.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.Contact.description"),
          },
        ],
      };
    },
  },
    data() {
        return {}
    },
    components: {},
    /*
    组件创建后
    经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
    */
    created() {
    },
    /*
    挂载后
    如果要操作当前组件的dom 最早 只能在mounted阶段执行
    */
    mounted() {

    },
    methods: {

    },
}
</script>
  
<style scoped lang="scss">
@import "@/styles/variables";
.contactUs {
    width: 100%;
    background: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        margin-left: 30px;
        margin-top: 24px;
        font-size: 24px;
        font-weight: 600;
        color: #333333;
    }

    .img {
        width: 100%;
    }

    .contactContent {
        width: $contentWidth;
        height: 248.5px;
        //   background: #fff;
        background: url('@/assets/images/helpCenter/contactBG.jpg')no-repeat center 100%;
        background-size: 100% 248.5px;
        margin-top: 20px;

        //   padding: 24px 30px 24px 30px;
        .contentBox {
            display: flex;
            margin-top: 30px;
            margin-left: 30px;

            .contentImg {
                width: 20px;
                height: 20px;
                margin-right: 10px;

            }
        }

    }
}</style>