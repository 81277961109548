<template>
    <div class="search">

        <Head />
        <HelpSearch @search="search" />
        <!-- 搜索内容 -->
        <div class="searchBox" v-if="contentListData">
            <div class="searchTitle">{{ $t('Help.search.title') }} ({{ total }})</div>
            <div class="all">{{ $t('Help.search.all') }}</div>
            <div class="line"></div>

            <div class="searchItem" v-for="(item, index) in contentListData" :key="index" @click="searchItemClick(item)">
                <div class="caption">{{ item.title }}</div>
                <div class="content">{{ deletePSpan(item.content) }}</div>
            </div>

            <div class="page" v-if="total > 0">
                <el-pagination background layout="prev, pager, next" :total=total :page-size="pageSize"
                    @current-change="currentChange">
                </el-pagination>
            </div>
        </div>
        <div v-if="!contentListData"
            style="width: 1200px; height:481px ; background: #fff; margin: 0 auto; padding: 100px 0px 0px 0px; ">
            <el-empty :image="emptyImg" :image-size="218" :description="reslutTops"></el-empty>
        </div>
    </div>
</template>

<script>
import Head from '../components/head.vue'
import HelpSearch from '../components/helpSearch.vue'
import Pagination from '@/page/supply/components/Pagination.vue';
import emptyImg from '@/assets/images/helpCenter/searchResult.png'
import { listPageApi } from '@/api/help'

export default {
    components: {
        Head, HelpSearch
    },
    metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: this.$t("tdk.helpCenter.title"),
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.helpCenter.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.helpCenter.description"),
                    },
                ],
            };
        },
    },
    data() {
        return {
            contentListData: [],
            total: 0,
            pageSize: 3,
            pageNum: 1,
            emptyImg: emptyImg,
            reslutTops: this.$t('Help.search.sorry'),

        }
    },
   
    /*
    组件创建后
    经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
    */
    created() {
    },
    /*
    挂载后
    如果要操作当前组件的dom 最早 只能在mounted阶段执行
    */
    mounted() {
        this.getDetailData(this.$route.query.keyword)
    },
    methods: {

        search(word) {
            this.pageNum = 1
            this.getDetailData(word)
        },
        deletePSpan(value) {
            return value.replace(/<[^>]+>/g, '')

        },
        getDetailData(keyword) {
            var that = this
            listPageApi({
                categoryCode: '',
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: '1',
                keyword: keyword
            }).then((res) => {
                if (res.code == '200') {
                    that.contentListData = res.data.data
                    that.total = res.data.count;
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning",
                    });
                }
            })
        },

        currentChange(data) {
            this.pageNum = data
            this.getDetailData()
        },

        searchItemClick(item) {
            let page = this.$router.resolve({
                path: '/helpCenter/qustion/content',
                query: {
                        levelCategoryCode: item.levelCategoryCode,
                        categoryCode: item.categoryCode,
                        id: item.id
                }
            })
            window.open(page.href, "_blank");
        
        }
    },
}
</script>

<style scoped lang="scss">
.search {
    width: 100%;
    background: #F6F6F6;

    .helpContent {
        margin-top: 40px;
        margin-bottom: 40px;

    }

    .searchBox {
        width: 1200px;
        margin-top: 40px;
        background: #fff;
        margin: 0 auto;
        padding: 30px 20px 30px 20px;

        .searchTitle {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
        }

        .all {
            width: 42px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            border-bottom: 2px solid #00B968;
        }

        .searchItem {
            cursor: pointer;

        }

        .line {
            height: 1px;
            background: #E6E7EB;
        }

        .caption {
            margin-top: 40px;
            color: #00B968;
            font-size: 16px;
            font-weight: 500;
        }

        .content {
            margin-top: 20px;
            color: #666666;
            font-size: 16px;
        }

        .page {
            margin-top: 50px;
            height: 36px;
            text-align: right;
        }
    }
}
</style>