import request from '@/utils/request'

// 发布供应信息或者求购信息
// type
// supply:供应, demand:求购
export function addOrUpdate(type,data) {
  return request({
    url: '/v1/web/api/user/product/'+type+'/addOrUpdate',
    method: 'post',
    data: data
  })
}
// 供应信息或者求购信息回显
// type
// supply:供应, demand:求购
export function getProductDetail(productCode) {
  return request({
    url: '/v1/web/api/user/product/echo/'+productCode,
    method: 'post'
  })
}
export function readOrNot() {
  return request({
    url: '/v1/web/api/user/product/publishPolicy/readOrNot',
    method: 'post'
  })
}
export function upImageUrl(data,heads) {
  return request({
    url: '/v1/web/api/files/uploadImage',
    method: 'post',
    data:data,
    headers:heads
  })
}
