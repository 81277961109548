<template>
    <!-- 输入框内容 -->
    <div class="content">
        <div class="title">{{$t('Register.verifyEmailTitle')}}</div>
        <div class="title-cue">{{$t('Register.sendEmailTips')}}</div>
        <div class="email-box">
            <div class="email">{{$t('Register.enterOTP') }}</div>
            <button class="sendBtn"  @click="sendCode" :disabled="!able" :class="{ active: !able}">{{ $t('Register.resendOTP') }}</button>
        </div>
        <el-input v-model="msmCode" :placeholder="$t('Register.enterOTP')"></el-input>
        <el-button type="primary" class="continueBtn"  @click="continuClick" round>{{$t('Register.continue') }}</el-button>
    </div>
</template>
<script>


export default {
    components: {

    },
    data() {
        return {
            msmCode:'',
            count: '',
            timer: null,
            able: true,
        }
    },
    created() {
        this.countDown()
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        continuClick() {
            if (this.msmCode.length == 0) {
                this.$message({
                    message: this.$t('Register.enterOTP'),
                    type: "warning",
                });
                return
            } 
            this.$emit('twoClick',this.msmCode)

        },
        countDown() {
            this.able = false
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        // console.log('倒计时开始-----')
                    } else {
                        this.able = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        // console.log('倒计时结束-----')

                    }
                }, 1000)
            }
        },
          // 重新发送验证码接口
          sendCode() {
            this.countDown()
            this.$emit('sendCode')

        },
    }
}

</script>
 
<style lang="scss" scoped>
.content {
    width: 470px;
    margin-top: 31px;
    background: linear-gradient(192deg, #E7FFF5 0%, #FFFFFF 100%);
    border-radius: 10px;
    border: 1px solid #DDDDDD;
    margin-bottom: 451px;

    .title {
        font-size: 28px;
        font-family: Arial, Arial;
        font-weight: normal;
        color: #000000;
        line-height: 32px;
        margin-top: 30px;
        margin-left: 40px;
    }

    .title-cue {
        margin-top: 10px;
        margin-left: 40px;
        margin-right: 40px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;

    }

    .email-box {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        height: 16px;
        display: flex;
        justify-content: space-between;
        .email {
            font-size: 14px;
            font-family: Arial, Arial;
            font-weight: normal;
            color: #333333;
        }


    }
    .sendBtn {
        background: none;
        color: #005CFF;
        line-height: 1px;
        border: #DDDDDD;
        cursor: pointer;

        &.active {
            color: gray;

        }
    }

    .continueBtn {
        width: 390px;
        height: 48px;
        margin-left: 40px;
        margin-top: 30px;
        background: #00B968;
        margin-bottom: 30px;
    }

}

// 做输入框穿透效果 设置输入框frame
::v-deep .el-input__inner {
    width: 390px;
    height: 48px;
    margin-left: 40px;
    margin-top: 10px;

}
</style>
