import request from '@/utils/request'

// 帮助中心 首页分类
export function hotCategoryApi(data) {
    return request({
        url: '/v1/web/api/help/hotCategoryList',
        method: 'post',
        data: data

    })
}

//帮助中心首页 分类点击接口
export function categoryListApi(data) {
    return request({
        url: '/v1/web/api/help/hotList',
        method: 'post',
        data: data

    })
}


// 分类列表
export function categoryTreeListApi(data) {
    return request({
        url: '/v1/web/api/help/categoryTreeList',
        method: 'post',
        data: data
    })
}

// 详细分类列表
export function listPageApi(data) {
    return request({
        url: '/v1/web/api/help/listPage',
        method: 'post',
        data: data

    })
}

// 列表点击之后的展示内容 是否点赞及标题等
export function detailApi(data) {
    return request({
        url: '/v1/web/api/help/detail',
        method: 'post',
        data: data

    })
}

// 内容点赞
export function insertApi(data) {
    return request({
        url: '/v1/web/api/helpRecord/insert',
        method: 'post',
        data: data

    })
}
// 意见反馈
export function surveyApi(data) {
    return request({
        url: '/v1/web/api/opinion/insert',
        method: 'post',
        data: data

    })
}

