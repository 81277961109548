<template>
    <!-- 输入框内容 -->
    <div class="emailContent">
        <div class="bgcolor"></div>
        <div class="content-box">
            <!--  -->
            <div class="title">{{ $t('Register.verifyEmailTitle') }}</div>
            <div class="title-cue"> {{ $t('Register.sendEmailTips')}}{{$store.state.home.userparm.email}}  </div>
            <div class="email-box">
                <div class="email">{{ $t('Register.enterOTP') }}</div>
                <!-- <div class="email" style="color: #005CFF; cursor: pointer;" @click="sendCode" >{{ $t('Register.resendOTP')}}</div> -->
                <button class="sendBtn"  @click="sendCode" :disabled="!able" :class="{ active: !able}">{{ $t('Register.resendOTP') }}</button>
            </div>
            <el-input v-model="msmCode" :placeholder="$t('Register.enterOTP')"></el-input>
            <el-button type="primary" class="continueBtn" @click="continueBtnClick" round>{{ $t('Register.creatFJAccount')
            }}</el-button>
            <div class="notice">
                <span>{{ $t('Login.cue1') }}</span>
                <span style="color: #005CFF;"  @click="tiaojianClick"> {{ $t('Login.cue2') }} </span>
                <span>{{ $t('Login.cue3') }}</span>
                <span style="color: #005CFF;" @click="xieyiClick"> {{ $t('Login.cue4') }}</span>
            </div>
        </div>

    </div>
</template>
<script>


export default {
    components: {

    },
    created() {
        this.countDown()
    },
    data() {
        return {
            msmCode: '',
            count: '',
            timer: null,
            able: true,

        }
    },
    mounted() {
        // console.log(this.$route)
    },
    destroyed() {

    },
    methods: {
        // 重新发送验证码接口
        sendCode() {
            this.countDown()
            this.$emit('sendCode')

        },
        continueBtnClick() {
            if (this.msmCode.length == 0) {
                this.$message({
                    message: this.$t('Register.enterOTP'),
                    type: "warning",
                });
            } else {
                this.$emit('register', this.msmCode)
            }
        },
        countDown() {
            this.able = false
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        // console.log('倒计时开始-----')
                    } else {
                        this.able = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        // console.log('倒计时结束-----')

                    }
                }, 1000)
            }
        },
            // 使用条件
        tiaojianClick() {
            let page = this.$router.resolve({
                path: '/terms/termsofUse',
            })
            window.open(page.href, "_blank");        },
        // 隐私协议
        xieyiClick(){
            let page = this.$router.resolve({
                path: '/terms/privacypolicy',
            })
            window.open(page.href, "_blank");        }
    }
}

</script>

<style lang="scss" scoped>
.emailContent {
    width: 470px;
    height: 376px;
    margin-top: 31px;
    // background: linear-gradient(192deg, #E7FFF5 0%, #FFFFFF 100%);
    border-radius: 10px;
    border: 1px solid #DDDDDD;
    margin-bottom: 181px;
    position: relative;
    box-sizing: content-box;

    .bgcolor {
        background: linear-gradient(to bottom left, #E7FFF5 0%, white 100%);
        width: 470px;
        height: 100px;
        border-radius: 10px;
        z-index: 0;
        position: absolute;

    }

    .sendBtn {
        background: none;
        color: #005CFF;
        line-height: 1px;
        border: #DDDDDD;
        cursor: pointer;
        &.active {
            color: gray;

        }
    }

    .content-box {
        position: absolute;
        top: 0px;
        width: 470px;
        // height: 630px;
        height: 376px;

        z-index: 10;

        .title {
            font-size: 28px;
            font-family: Arial Black;
            font-weight: 600;
            color: #000000;
            line-height: 32px;
            margin-top: 30px;
            margin-left: 40px;
        }

        .title-cue {
            margin-top: 10px;
            margin-left: 40px;
            margin-right: 40px;
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            word-break: break-word;
        }

        .email-box {
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 20px;
            height: 16px;
            display: flex;
            justify-content: space-between;

            .email {
                font-size: 14px;
                font-family: Arial Black;
                font-weight: 600;
                color: #333333;
            }


        }


        .continueBtn {
            width: 390px;
            height: 48px;
            margin-left: 40px;
            margin-top: 30px;
            background: #00B968;
        }

        .notice {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            width: 390px;
            margin-left: 40px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

}

// 做输入框穿透效果 设置输入框frame
::v-deep .el-input__inner {
    width: 390px;
    height: 48px;
    margin-left: 40px;
    margin-top: 10px;

}</style>
