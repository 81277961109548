<template>
    <div class="exhibition">
        <!-- 导航栏组件 -->
        <BreadCrumbs />
        <!-- 内容 -->
        <div class="content-box">
            <div class="cell-box" v-for="(item, index) in exhibitionList" :key="index" @click="cellBoxClick(item)">
                <!-- 图片 -->
                <div class="img-box" v-if="item.coverImage">
                    <img :src="item.coverImage" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer"
                        class="img">
                </div>
                <!-- 类型、主标题 、副标题、时间、发布者-->
                <div class="detail-box">
                    <div class="type" :style="{ background: exchangeColor(item.exhibitionState) }">{{
                        exchangeType(item.exhibitionState) }}</div>
                    <div class="title">{{ item.exhibitionName }}</div>

                    <div class="date-box">
                        <img src="@/assets/images/home/news_date.png" class="dateImg" alt="">
                        <span class="dateTitle">{{ item.holdStartTime | formatDate }} - {{ item.holdEndTime | formatDate
                        }}</span>
                    </div>
                    <div class="by-box" v-if="item.exhibitionSite">
                        <img src="@/assets/images/home/news_by.png" class="byImg" alt="">
                        <span class="dateTitle">{{ item.exhibitionSite }}</span>
                    </div>
                    <div class="line"></div>
                    <div class="keywords">{{ item.keyword }}</div>

                </div>
            </div>
            <pagination v-if="exhibitionList.length" :total="total" :page-size="pageSize" @page-change="handlePageChange">
            </pagination>
        </div>
        <Message />
    </div>
</template>
<script>

import BreadCrumbs from '@/components/breadCrumbs.vue'
import Message from '@/components/message.vue'
import moment from "moment"; //引入moment
import Pagination from "@/page/supply/components/Pagination";

import { getExhibitionList, getExhibitionDetail } from '@/api/news'

export default {
    metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: this.$t("tdk.exhibition.title"),
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.exhibition.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.exhibition.description"),
                    },
                ],
            };
        },
    },

    components: {
        BreadCrumbs, Message, Pagination
    },
    data() {
        return {
            bgColor: '',
            exhibitionList: [],
            exchange: {
                '1': this.$t('News.draft')
            },
            pageNum: 1,
            pageSize: 10,
            total: 0

        }
    },
    filters: {
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD");
        }

    },

    mounted() {
        this.getData()
    },
    destroyed() {
    },
    methods: {

        // draft: 'Draft',//草稿 0
        // tobegin: 'To begin',//待开始 2
        // audit: 'Audit',//待审核  1
        // progress: 'In progress',//进行中 3
        // ended: 'Ended',//已结束 4
        // shelf: 'Off-shelf ',//已下架 5
        // deleted: 'Deleted',//已删除 6
        exchangeType(value) {

            if (value == '0') {
                return 'Draft'
                //  this.$t('News.draft')
            } else if (value == '2') {
                return 'To begin'
                // this.$t('News.tobegin')
            } else if (value == '1') {
                return 'Audit'
                // this.$t('News.audit')
            } else if (value == '3') {
                return 'In progress'
                // this.$t('News.progress')
            } else if (value == '4') {
                return 'Ended'
                // this.$t('News.ended')
            } else if (value == '5') {
                return 'Off-shelf'
                //  this.$t('News.shelf')
            } else if (value == '6') {
                return 'Deleted'
                // this.$t('News.deleted')
            }
        },
        exchangeColor(value) {
            var bg = ''
            if (value == '2') {
                bg = '#00B968'
            } else if (value == '3') {
                bg = '#FF7D00'
            } else if (value == '4') {
                bg = '#AEAEAE'
            }
            return bg
        },
        cellBoxClick(item) {
            this.$router.push({
                path: '/exhibition/exhibitionDetail',
                query: {
                    id: item.id
                }
            })
        },
        getData() {
            var that = this
            getExhibitionList({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }).then((res) => {
                if(res.code == '200') {
                    that.exhibitionList = res.data.list;
                    that.total = res.data.total
                }
            })
        },
        handlePageChange(data) {
            this.pageNum = data
            this.getData()
        },
    }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.exhibition {
    width: $contentWidth;
    margin: 0 auto;
    background: #F6F6F6;


    .content-box {
        // width: 1440px;
        padding: 0px 0px 30px 0px;

        .cell-box {
            width: 100%;
            height: 303px;
            margin-bottom: 10px;
            background: #FFFFFF;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            cursor: pointer;
            .img-box {
                width: 533px;
                height: 303px;
                background: #ddd;
                flex-shrink: 0;

                .img {
                    width: 100%;
                    height: 100%;
                }

            }

            .detail-box {
                width: 1440px;
                height: 303px;
                position: relative;
                padding: 0px 0px 0px 40px;

                .type {
                    position: absolute;
                    width: 121px;
                    height: 30px;
                    line-height: 30px;
                    color: #fff;
                    display: inline-block;
                    padding: 0px 12px;
                    right: 0px;
                    text-align: center;
                    font-size: 16px;
                }

                .title {
                    margin-top: 50px;
                    margin-right: 40px;
                    font-size: 32px;
                    font-weight: 600;
                    color: #000000;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }


                .date-box {
                    height: 20px;
                    margin-top: 30px;
                    display: flex;
                    align-items: center;

                    .dateImg {
                        width: 18px;
                        height: 18px;
                    }


                }

                .by-box {
                    height: 20px;
                    width: 98%;
                    margin-top: 17px;
                    display: flex;
                    align-items: center;

                    .byImg {
                        width: 18px;
                        height: 18px;
                    }
                }

                .dateTitle {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    font-size: 18px;
                    font-weight: 500;
                    color: #666666;
                    margin-left: 3px;
                }

                .line {
                    position: absolute;
                    width: 747.5px;
                    background: #E6E7EB;
                    height: 1px;
                    bottom: 55px;
                }

                .keywords {
                    position: absolute;
                    height: 18px;
                    line-height: 18px;
                    color: #666666;
                    margin-right: 20px;
                    font-size: 18px;
                    bottom: 20px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;

                }
            }
        }
    }

}
</style>
