<template>
    <div class="helpContent">
    
        <div class="inputBox">
            <el-input :placeholder="$t('Help.home.input')" v-model="input"> </el-input>
            <el-button icon="el-icon-search" class="search" style="color: #fff;font-size: 17px;"
                @click="searchClick">{{ $t('home.Search') }}</el-button>
        </div>
     </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            input: ''
        }
    },
    mounted() {
        if(this.$route.query.keyword) {
            this.input = this.$route.query.keyword
        }
    },

    destroyed() {
    },

    methods: {
        searchClick() {
            if (this.input.length > 0) {
                var that = this
                this.$router.push({
                        path: "/helpCenter/searchResult",
                        query: {
                            keyword: that.input //页面参数
                        },
                    });
                // if (this.$route.path === '/helpCenter/searchResult') {
                //     this.$emit('search', this.input)
                // } else {
                //     this.$router.push({
                //         path: "/helpCenter/searchResult",
                //         query: {
                //             keyword: that.input //页面参数
                //         },
                //     });
                // }

            } else {
                this.$message({
                    message: this.$t('Help.home.input'),
                    type: "warning",
                });
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.helpContent {
    width: 100%;
    // position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
        width: 100%;
    }
   
    .inputBox {
        // position: absolute;
        width: 776px;
        height: 56px;
        margin: 0 auto;
        display: flex;
        ::v-deep .el-input__inner {
            width: 638px;
            height: 56px;
            border-radius: 28px 0px 0px 28px;
            border: 2px solid #00B968;
        }

        .search {
            width: 158px;
            height: 56px;
            background: #00B968;
            border-radius: 0px 28px 28px 0px;
            border: 2px solid #00B968;

        }
    }
}
</style>
