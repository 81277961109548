<template>
    <div class="content">
        <div class="type" :style="{ background: exchangeColor(exhibitionData.exhibitionState) }">
            {{ exchangeType(exhibitionData.exhibitionState) }}</div>
        <div class="title">{{ exhibitionData.exhibitionName }}
        </div>
        <!-- 表格数据 -->
        <el-table :data="tableData" :show-header=false border style="width: 100%; margin-top: 30px;">
            <el-table-column show-header=false prop="key" width="251">
            </el-table-column>
            <el-table-column prop="value" width="">
            </el-table-column>
        </el-table>
        <el-table :data="dateData" :show-header=false border style="width: 100%; margin-top: 30px;">
            <el-table-column show-header=false prop="key" width="251">
            </el-table-column>
            <el-table-column prop="value" width="">
            </el-table-column>
        </el-table>
        <!-- <div class="detailTitle" v-html="exhibitionData.exhibitionContent"></div> -->

        <div class="ql-container ql-snow">
            <div class="ql-editor">
                <div v-html="exhibitionData.exhibitionContent"></div>
            </div>
        </div>


        <!-- </div> -->
        <div class="bottomShare">
            <span class="shareTitle"> {{ $t('News.share') }} </span>
            <img src="@/assets/images/home/LinkedIN.png" class="linkedinImg" @click="linkedinClick" alt="">
            <img src="@/assets/images/home/Twitter.png" class="twitterImg" @click="twitterClick" alt="">
            <img src="@/assets/images/home/Facebook.png" class="facebookImg" @click="faceBookClick" alt="">
        </div>
    </div>
</template>
<script>

import { sendShare } from "@/api/supply";

export default {
    props: ["exhibitionData"],
    watch: {
        exhibitionData: {
            handler: function (newVal, oldVal) {
                this.share = {
                    url: window.location.href,
                    text: newVal.exhibitionName
                }
                this.tableData = [{
                    key: 'Category',
                    value: newVal.exhibitionCategoryName,
                },
                {
                    key: 'Organizer',
                    value: newVal.organizer,
                },
                {
                    key: 'Exhibition center',
                    value: newVal.exhibitionSite,
                }],
                    this.dateData = [{
                        key: 'Date',
                        value: this.$moment(newVal.holdStartTime).format("YYYY-MM-DD") + ' - ' + this.$moment(newVal.holdEndTime).format("YYYY-MM-DD")
                    }
                    ]
            },
            immediate: true,
        },
    },
    components: {},

    data() {
        return {
            tableData: [{
                key: 'Category',
                value: '',
            },
            {
                key: 'Organizer',
                value: '',
            },
            {
                key: 'Exhibition center',
                value: '',
            }],
            dateData: [{
                key: 'Date',
                value: '',
            }
            ],
            share: {
                //分享参数
                url: window.location.href,
                text: ''
            },
        }
    },
    filters: {
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD");
        }

    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        faceBookClick() {
            this.end_Share();
            // 携带参数
            function popupwindow(url, title) {
                return window.open(
                    "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
                    +"&t=" + encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);
        },
        // 推特分享
        twitterClick() {
            this.end_Share();
            //携带参数
            function popupwindow(url, title) {
                return window.open(
                    "https://twitter.com/intent/tweet?url=" +
                    encodeURIComponent(url) +
                    "&text=" +
                    encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);

        },
        linkedinClick() {
            this.end_Share();
            //携带参数
            function popupwindow(url, title) {
                return window.open(
                    "https://www.instagram.com/share?text=" +
                    encodeURIComponent(url) +
                    "&hashtags=" +
                    encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);
        },
        // 点击分享调用接口
        end_Share() {
            // sendShare({
            //     id: this.$route.query.id,
            // }).then((res) => { });
        },
        exchangeType(value) {

            if (value == '0') {
                return 'Draft'
                //  this.$t('News.draft')
            } else if (value == '2') {
                return 'To begin'
                // this.$t('News.tobegin')
            } else if (value == '1') {
                return 'Audit'
                // this.$t('News.audit')
            } else if (value == '3') {
                return 'In progress'
                // this.$t('News.progress')
            } else if (value == '4') {
                return 'Ended'
                // this.$t('News.ended')
            } else if (value == '5') {
                return 'Off-shelf'
                //  this.$t('News.shelf')
            } else if (value == '6') {
                return 'Deleted'
                // this.$t('News.deleted')
            }
        },
        exchangeColor(value) {
            var bg = ''
            if (value == '2') {
                bg = '#00B968'
            } else if (value == '3') {
                bg = '#FF7D00'
            } else if (value == '4') {
                bg = '#AEAEAE'
            }
            return bg
        },
        replacedHtml(html) {
      // 使用正则表达式替换<为&lt;，>为&gt;
      const regex = /</g,
        regex2 = />/g,
        replacedHtml = html
          .replace(regex, "&lt;")
          .replace(regex2, "&gt;");
      return replacedHtml;
    },


    }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.content {
    width: $contentWidth;
    // margin-left: 240px;
    background: #fff;
    position: relative;
    padding: 0px 30px 30px 30px;
    box-sizing: border-box;

    .type {
        position: absolute;
        height: 27px;
        line-height: 27px;
        color: #fff;
        background: #00B968;
        // display: inline-block;
        padding: 0px 12px;
        right: 0px;
        border-radius: 0px 10px 0px 10px;

    }

    .title {
        margin-top: 50px;
        font-size: 20px;
        font-family: Arial Black;
        font-weight: 600;
        color: #000000;
        overflow: hidden;
        display: inline-block;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .ql-container {
        margin-top: 20px;
        // font-size: 16px;
        // color: #333333;
        // font-weight: 450;

        ::v-deep img {
            max-width: 100%;
        }

    }

    .bottomShare {
        position: relative;
        height: 76px;
        width: 100%;
        margin-top: 18px;
        background: #F6F6F6;
        display: flex;
        align-items: center;

        .shareTitle {
            position: absolute;
            right: 140px;
            height: 20px;

        }

        .linkedinImg {
            position: absolute;
            right: 20px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }

        .twitterImg {
            position: absolute;
            right: 59px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }

        .facebookImg {
            position: absolute;
            right: 98px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }
    }

    ::v-deep .ql-container.ql-snow {
        border: none;
        .ql-editor{
            span {
                background: none !important;
            }
        }
    }

}
</style>
