import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import './styles/iconfont.css'
import router from './router'; // 导入路由实例
import store from './store'
import axios from "axios";
import i18n from './i18n'
import '@/styles/index.scss' // global css
import moment from "moment"; //引入moment
import MetaInfo from 'vue-meta-info'
// 这个是你刚刚写的index.js的路径
import tableHeight from './utils/table/index'
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
// 注册
Vue.use(MetaInfo)

// 表格自适应指令
Vue.use(tableHeight)

import { getName, getImagePath } from '@/utils/common.js';
Vue.prototype.$getName = function (param) {
  return getName(param);
};
Vue.prototype.$getImagePath = function (param) {
  return getImagePath(param);
};
Vue.prototype.$axios = axios
Vue.config.productionTip = false


Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
})
Vue.prototype.$moment = moment//挂载Vue上

// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title
//   }
// })
new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
}
}).$mount('#app')
