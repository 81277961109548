<template>
    <div class="learning">
        <div class="learning-box">
            <div class="title">{{ $t('Help.home.learning') }}</div>
            <div class="titleline"></div>
            <div class="child-box">
                <div class="child" v-for="(item, index) in childList" :key="index" @click="cellBoxClick(index)">
                    <img class="img" :src="images[index]" alt="">
                    <div class="chiltTile">{{ item }}</div>
                    <!-- <div  class="childDetail" v-if="index === 2"> 24/7 Service for Buyer </div> -->
                    <div class="childDetail" v-if="index === 3"> {{ $t('Help.home.feedBack') }} </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            childList: [
                this.$t('Help.home.buy'),
                this.$t('Help.home.vip'),
                this.$t('Help.home.service'),
                this.$t('Help.home.survey')],
            images: [
                `${require('@/assets/images/helpCenter/learning1.png')}`,
                `${require('@/assets/images/helpCenter/learning2.png')}`,
                `${require('@/assets/images/helpCenter/learning3.png')}`,
                `${require('@/assets/images/helpCenter/learning4.png')}`]
        }
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        cellBoxClick(index) {
            if (index === 0) {
                console.log('问题列表')
                this.$router.push({
                path: '/helpCenter/qustion/list',
                query: {
                   first:true
                }
            })
            } else if (index === 1) {
                this.$message({
                    message: this.$t('Help.home.commingSoon'),
                    type: "warning",
                });
            } else if (index === 2) {
                this.$message({
                    message: this.$t('Help.home.commingSoon'),
                    type: "warning",
                });
            } else if (index === 3) {
                this.$router.push('/helpCenter/survey')
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.learning {
    width: 100%;
    background: #F6F6F6;
    display: flex;
    justify-content: center;
    padding: 80px 0px 80px 0px;
    box-sizing: border-box;

    .learning-box {
        width: 1345px;

        .title {
            width: 100%;
            height: 37px;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #333333;
        }

        .titleline {
            width: 35px;
            height: 4px;
            background: #00B968;
            margin: 0 auto;

        }

        .child-box {
            width: 1345px;
            margin-top: 30px;
            height: 214px;
            padding: 0px 5px 0px 5px;
            box-sizing: border-box;
            display: flex;
            cursor: pointer;
            .child {
                width: 308px;
                height: 214px;
                margin-left: 20px;
                background: white;
                border-radius: 10px;

                .img {
                    width: 70px;
                    height: 70px;
                    margin-top: 50px;
                    margin-left: 119px;
                }

                .chiltTile {
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    text-align: center;
                    margin-top: 24px;
                }

                .childDetail {
                    font-size: 14px;
                    color: #999999;
                    text-align: center;

                }
            }
        }

    }
}</style>
