<template>
    <div class="qustionContent">
        <div class="contentBox">
            <div class="title">{{ contentData.title }}</div>
            <div class="content" v-html="contentData.content"></div>
        </div>

        <!-- 点赞模块 -->
        <div class="noReply" v-if="contentData.isClick=='0' && !done && !showReason">
            <div class="title">{{ $t('Help.submit.title') }}</div>
            <div class="replyBox">
                <div class="replyBtn" @click="replyYesClick" >
                    <img class="img" src="@/assets/images/helpCenter/help-up.png" alt="">
                    <span style=" font-size: 14px;">{{ $t('Help.submit.yes') }}</span>
                </div>
                <div class="replyBtn" @click="replyNoClick">
                    <img class="img" src="@/assets/images/helpCenter/help-down.png" alt="">
                    <span style=" font-size: 14px;">{{ $t('Help.submit.no')}}</span>
                </div>
            </div>
        </div>

        <!-- 已回复模块 -->
        <div class="replyed" v-if="done">
            <div class="title">{{ $t('Help.submit.thanks') }}</div>
            <div class="replyBtn">
                <img class="img" src="@/assets/images/helpCenter/help-done.png" alt="">
                <span style=" font-size: 14px;">{{ $t('Help.home.submitted') }}</span>
            </div>
        </div>

        <!-- 选择原因模块 -->
        <div class="selectReason" v-if="showReason">
            <div class="reasonCue">{{ $t('Help.submit.reasonTitle') }}</div>
            <div class="selecbox">
                <!-- <div class="selectBtn" v-for="item in checkList" :key="item"> -->
                <el-radio v-model="reason" :label=item v-for="item in checkList" :key="item.index">{{ item.name }}</el-radio>

                <!-- <img class="img" src="@/assets/images/helpCenter/help-done.png" alt="">
                    <span style=" font-size: 14px;">{{item}}</span> -->
                <!-- </div> -->
                <div class="button" @click="submit">{{ $t('Help.submit.submit') }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { detailApi, insertApi } from '@/api/help'
export default {
    data() {
        return {
            showReason: false,
            done: false,
            checkList: [{name:this.$t('Help.submit.reason1'),index:1},
            {name:this.$t('Help.submit.reason2'),index:2},
            {name:this.$t('Help.submit.reason3'),index:3},
            {name:this.$t('Help.submit.reason4'),index:4}],
            contentData: '',
            reason: {}
        }
    },

    components: {},
    /*
    组件创建后
    经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
    */
    created() {

    },
    /*
    挂载后
    如果要操作当前组件的dom 最早 只能在mounted阶段执行
    */
    mounted() {
        this.getData()
    },
    methods: {

        getData() {
            if (this.$route.query.id ) {

                var contentId = this.$route.query.id
                detailApi({
                    id: contentId
                }).then((res) => {
                    if (res.code == '200') {
                        this.contentData = res.data
                    }
                })
            }

        },
        //好
        replyYesClick() {
            console.log('输出时间')
            insertApi({
                helpId: this.$route.query.id,
                type: '1',
                reason: ''
            }).then((res) => {
                if (res.code == '200') {
                    this.done = true
                } 
            })
        },
        replyNoClick() {
            this.showReason = true
        },
        submit(){
            if(this.reason) {
                var that = this
            console.log('选择反馈原因',this.reason)
            insertApi({
                helpId: this.$route.query.id,
                type: '2',
                reason: this.reason.index
            }).then((res) => {
                if (res.code == '200') {
                    that.done = true
                    that.showReason = false
                } 
            })
            } else {
                console.log('请选择原因')
            }
        }
    },
}
</script>

<style scoped lang="scss">
.qustionContent {
    width: 100%;

    .contentBox {
        padding: 21px 31px 21px 31px;

        .title {
            color: #333333;
            font-weight: 600;
            font-size: 16px;
        }

        .content {
            color: #333333;
            font-weight: 500;
            font-size: 14px;
            margin-top: 30px;
            width: 100%;
            ::v-deep img {
            max-width: 100%;
        }
        }
    }

    .selectReason {
        width: 100%;
        padding: 20px 0px 20px 0px;

        .reasonCue {
            width: 100%;
            font-size: 14px;
            color: #666666;
            text-align: center;
            margin-top: 32px;
            font-weight: 500;
        }

        .selecbox {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .selectBtn {
                display: flex;
                height: 16px;
                margin-right: 20px;
                align-items: center;

                .img {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                }
            }

            .button {
                width: 61px;
                height: 24px;
                font-size: 14px;
                padding: 2px 9px 2px 9px;
                color: #fff;
                background: #00B968;
                border-radius: 4px;
                text-align: center;
                cursor: pointer;
            }

        }


        .reasonDetailTitle {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            margin-left: 20px;
            margin-top: 38px;
        }


    }


    .noReply {
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            width: 100%;
            height: 16px;
            font-size: 14px;
            line-height: 16px;
            color: #666666;
            text-align: center;
            font-weight: 500;
        }

        .replyBox {
            display: flex;
            height: 32px;
            margin-top: 12px;

            .replyBtn {
                background: #fff;
                width: 100px;
                height: 32px;
                padding: 9px 19px 9px 19px;
                display: flex;
                margin-right: 10px;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .img {
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                }
            }
        }
    }

    .replyed {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;
        }

        .replyBtn {
            background: #fff;
            width: 130px;
            height: 32px;
            padding: 9px 19px 9px 19px;
            display: flex;
            justify-content: center;
            align-items: center;

            .img {
                width: 14px;
                height: 14px;
                margin-right: 4px;
            }
        }


    }
}</style>