<template>
  <div class="Page_Content">
    <div class="Page_Content_l">
      <!-- 分类 -->

      <div class="Page_Content_c top">
        <h2>{{ $t("supply.Categories") }}</h2>
        <div class="page_tree">
          <b
            @click="CategoryClick('All', 0)"
            :class="TreeActive == 'All' ? 'active' : ''"
            >{{ $t("supply.Department") }}
          </b>
          <div
            class="tree_c"
            style="height: 256px; overflow-y: scroll"
            v-loading="Treeloading"
          >
            <dl v-for="item in Category" :key="item.id">
              <dt
                :class="item.code == TreeActive ? 'active' : ''"
                @click="CategoryClick(item, 1)"
              >
                {{ $getName(item) }}
              </dt>
              <template v-for="i in item.children">
                <dd
                  :key="i.id"
                  :class="i.code == TreeActive ? 'active' : ''"
                  @click="CategoryClick(i, 2)"
                >
                  {{ $getName(i) }}
                </dd>
              </template>
            </dl>
          </div>
        </div>
      </div>
      <div class="Page_Content_c Total">
        <h2>{{ $t("supply.Totalvolume") }}</h2>
        <div class="Total_Content">
          <div>
            <el-input
              v-model="Totalvolume.Min"
              :placeholder="$t('supply.Min')"
            ></el-input>
            <p></p>
            <el-input
              v-model="Totalvolume.Max"
              :placeholder="$t('supply.Max')"
            ></el-input>
          </div>
          <el-tag @click="chagesearch('Totalvolume')">ok</el-tag>
        </div>
      </div>
      <div class="Page_Content_c Total">
        <h2>{{ $t("supply.Totalprice") }}</h2>
        <div class="Total_Content">
          <div>
            <el-input
              v-model="Totalprice.Min"
              :placeholder="$t('supply.Min')"
            ></el-input>
            <p></p>
            <el-input
              v-model="Totalprice.Max"
              :placeholder="$t('supply.Max')"
            ></el-input>
          </div>
          <el-tag @click="chagesearch('Totalprice')">ok</el-tag>
        </div>
      </div>
      <div>
        <!-- 配送 -->
        <div class="Page_Content_c Location">
          <h2>{{ $t("supply.Method1") }}</h2>
          <div class="Location_Content">
            <el-checkbox-group v-model="Methodtype" @change="MethodSelect">
              <el-checkbox
                v-for="type in Method"
                :key="type.value"
                :label="type"
              >
                <template #default>
                  <span>
                    {{ $t(type.label) }}
                  </span>
                </template>
              </el-checkbox>
              <!-- 其他复选框 -->
            </el-checkbox-group>
          </div>
        </div>
        <!-- 所在地 -->
        <template v-if="$route.params.nav == 'supply'">
          <Location
            title="supply.supplyLocation"
            :list="types"
            @Ck_Box="SelectLocation($event, 'Location')"
          />
          <!-- 可供应地 -->
          <Location
            title="supply.Regions"
            :list="types"
            @Ck_Box="SelectLocation($event, 'Regions')"
          />
        </template>
        <!-- 求购 -->
        <template v-else>
          <Location
            title="supply.Location"
            :list="types"
            @Ck_Box="SelectLocation($event, 'Location')"
          />
        </template>
      </div>
    </div>
    <div class="Page_Content_r" v-loading="listloading">
      <template v-if="list ">
        <h3 v-show="!listloading">
          <em>{{ totalItems }}</em> results
        </h3>
        <ul>
          <li
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="OpenInfo(item)"
          >
            <img :src="item.picture" :alt="item.picture" />
            <div>
              <span> {{ item.name }}</span>
              <!-- <h4>{{ $store.state.lang.currency.symbol }} {{ item.expectedPrice }}</h4> -->
              <h4>
                <template v-if="item.priceType == 1">
                  {{ $store.state.lang.currency.symbol }}
                  {{ item.expectedPrice }}
                </template>
                <template v-if="item.priceType == 2">
                  {{ $store.state.lang.currency.symbol }}
                  {{ item.expectedPrice + "~" + item.exptHighPrice }}
                </template>
                <template v-if="item.priceType == 3">
                  {{ $t("release.requirement.negotiateDirectly") }}
                </template>
              </h4>
              <!-- <h4 v-else>{{ $t("release.requirement.Negotiable") }}</h4> -->
              <p>
                <img
                  :src="$getImagePath(item.countryFlag)"
                  v-if="item.countryFlag"
                />
                {{ item.localCountry }}
              </p>
            </div>
          </li>
        </ul>
        <pagination
          v-if="list.length"
          :total="totalItems"
          :page-size="sizi"
          @page-change="handlePageChange"
        ></pagination>
      </template>
      <!-- 搜索不到数据  -->
      <template v-else>
        <h3 v-show="!listloading">
          Your search for " <em>{{ $route.query.searchKey ||  findParentName(this.Category, $route.query.select) }}</em> " did not
          match anything. You can 
          <em @click="$router.push(getToken()?'/publish/publishSummary':'/login/signIn')" style="cursor: pointer;">
            post your needs
          </em>
           or view the following
          information we recommend for you.
        </h3>
        <ul>
          <li
            class="item"
            v-for="(item, index) in NOlist"
            :key="index"
            @click="OpenInfo(item)"
          >
            <img :src="item.picture" :alt="item.picture" />
            <div>
              <span> {{ item.name }}</span>
              <!-- <h4>{{ $store.state.lang.currency.symbol }} {{ item.expectedPrice }}</h4> -->
              <h4>
                <template v-if="item.priceType == 1">
                  {{ $store.state.lang.currency.symbol }}
                  {{ item.expectedPrice }}
                </template>
                <template v-if="item.priceType == 2">
                  {{ $store.state.lang.currency.symbol }}
                  {{ item.expectedPrice + "~" + item.exptHighPrice }}
                </template>
                <template v-if="item.priceType == 3">
                  {{ $t("release.requirement.negotiateDirectly") }}
                </template>
              </h4>
              <!-- <h4 v-else>{{ $t("release.requirement.Negotiable") }}</h4> -->
              <p>
                <img
                  :src="$getImagePath(item.countryFlag)"
                  v-if="item.countryFlag"
                />
                {{ item.localCountry }}
              </p>
            </div>
          </li>
        </ul>
      </template>
      <!-- <el-empty :image-size="200" v-else></el-empty> -->
    </div>
  </div>
</template>
<script>
import Location from "./components/Location";
import Pagination from "./components/Pagination";
import { getAllCountries, Homelatests } from "@/api/home";

import { getCategoryTree, getlist } from "@/api/supply";
import { getToken } from "@/utils/cookie";
export default {
  metaInfo() {
    //  动态meta
    return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      let title = ""; // 初始化 title 变量
      if (this.$route.params.nav == "supply") {
        title = this.$t("tdk.supply.title");
      } else {
        title = this.$t("tdk.supply.title1");
      }
      var meta = [
        {
          name: "keyWords",
          content: this.$t("tdk.supply.keywords"),
        },
        {
          name: "description",
          content: this.$t("tdk.supply.description"),
        },
      ];

      return {
        title: title,
        meta: meta,
      };
    },
  },

  components: {
    Location,
    Pagination,
  },
  data() {
    return {
      Treeloading: true, // 加载效果
      listloading: true, //list加载效果
      Category: [], //分类列表 tree
      treeItem:{}, //选中当前tree item对象
      TreeActive: "", //选中 tree
      totalItems: 0, //分页总条数
      page: 1,
      sizi: 24,
      types: [], //国家列表
      Totalvolume: {
        //交易总量
        Min: "",
        Max: "",
      },
      Totalprice: {
        //交易价格
        Min: "",
        Max: "",
      },
      Method: [
        {
          // 配送方式
          label: "supply.pickup",
          value: "1",
        },
        {
          label: "supply.Delivery1",
          value: "2",
        },
      ],
      Location: [], //选中位置
      Regions: [], //选中地区
      Methodtype: [], //配送选中
      MethodtypeVal: "", //配送选中值
      list: '', //列表渲染
      NOlist:'', //列表无数据
    
      // meta
      meta: {
        //
        classify1: "", //一级分类
        classify2: "", //二级分类
      },
    };
  },
  watch: {
    "$route.query.searchKey": "GList",
    // "$route.query.selectKey": {
    //   immediate: true,
    //   //监听search select 调用接口 并且赋值给分类选中
    //   handler(newData, loadData) {
    //     this.TreeActive = newData;
    //     this.GList();
    //   },
    // },
    "$route.query.select": {
      immediate: true,
      //监听search select 调用接口 并且赋值给分类选中
      handler(newData, loadData) {
        if (newData != undefined) {
          this.TreeActive = newData;
        } else {
          this.TreeActive = this.$route.query.selectKey;
        }
        // this.GList();
      },
    },
  },
  mounted() {
    this.getCommonApi();
    this.GList();
   
    
  },

  methods: {
    getToken() {
      return getToken(); // 调用getToken函数获取token的值
    },
    // tree点击
    CategoryClick(data, classify) {
      
      if (classify == 1) {
        this.meta.classify1 = this.$getName(data);
      } else {
        this.meta.classify2 = this.$getName(data);
      }
      if (classify == 0) {
        this.TreeActive = "All";
      } else {
        this.TreeActive = data.code;
      }
      // this.TreeActive = data.code;
      // this.GList();

      this.$router.replace({
        path: this.$route.path,
        query: {
          selectKey:
            this.findParentNode(this.Category, this.TreeActive) == null
              ? this.TreeActive
              : this.findParentNode(this.Category, this.TreeActive), //select选中code
          select: this.TreeActive,
        
        },
      });
    },
  // 获取父级code
    findParentNode(tree, targetId, parentId = null) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.code === targetId) {
          return parentId;
        }
        if (node.children && node.children.length > 0) {
          const parent = this.findParentNode(
            node.children,
            targetId,
            node.code
          );
          if (parent !== null) {
            return parent;
          }
        }
      }
      return null;
    },
    // 获取当前name
  findParentName(tree, targetId, parentName = null) {
  for (let i = 0; i < tree.length; i++) {
    console.log(tree[i])
    const node = tree[i];
    if (node.code === targetId) {
      return node.nameEn;
    }
    if (node.children && node.children.length > 0) {
      const parent = this.findParentName(
        node.children,
        targetId,
        node.nameEn
      );
      if (parent !== null) {
        return parent;
      }
    }
  }
  return null;
},

    // 选择位置选择/选择地区
    SelectLocation(data, name) {
      if (name == "Location") {
        // 位置选择
        this.Location = data;
      } else {
        // 选择地区
        this.Regions = data;
      }
      this.page = 0;
      this.GList();
    },

    // 重组数据
    checkBOx(data) {
      var array = [];
      data.map((item) => {
        array.push({
          code: item.code,
          value: this.$getName(item),
        });
      });
      return array;
    },
    // page点击
    handlePageChange(page) {
      this.page = page;

      this.GList();
    },
    // 跳转详情
    OpenInfo(item) {
      this.$router.push({
        path: "/supply/" + this.$route.params.nav + "/supplyDetail",
        query: {
          productCode: item.productCode, //页面参数
          lang: this.$store.state.lang.language.value, //语言
        },
      });
    },
    // 最高价 、最高数量
    chagesearch(type) {
      if (parseInt(this[type].Max) >= parseInt(this[type].Min)) {
        this.GList();
      } else {
        this.$message({
          message: "The maximum value cannot be less than the minimum value",
          type: "warning",
        });
      }
    },
    // 配送点击
    MethodSelect() {
      this.Methodtype.length == 2
        ? (this.MethodtypeVal = "3")
        : this.Methodtype.length
        ? (this.MethodtypeVal = this.Methodtype[0].value)
        : (this.MethodtypeVal = "");
      this.page = 0;
      this.GList();
    },
    // 零碎接口存放点
    getCommonApi() {
      // 分类接口 tree
      getCategoryTree().then((res) => {
        if (res.code == "200") {
          this.Category = res.data;
          this.Treeloading = false;
        }
      });
      //国家接口列表
      getAllCountries().then((res) => {
        if (res.code == "200") {
          this.types = res.data;
        }
      });
    },
    // 列表接口
    GList() {
       this.NOlist=[]
      this.listloading = true;
      var ty;
      if (this.$route.params.nav == "supply") {
        ty = "supply";
      } else {
        ty = "demand";
      }

      getlist(
        {
          // category: this.TreeActive == "All" ? "" : this.TreeActive, //分类参数
          category:
            this.TreeActive == "All"
              ? ""
              : this.TreeActive == undefined
              ? ""
              : this.TreeActive, //分类参数
          keyword: this.$route.query.searchKey, //查询参数
          page: this.page, //页数
          size: this.sizi, //条数
          minPrice: this.Totalprice.Min, //最小价格
          maxPrice: this.Totalprice.Max, //最大价格
          minNum: this.Totalvolume.Min, //最少数量
          maxNum: this.Totalvolume.Max, //最大数量
          deliveryMethod: this.MethodtypeVal, //交付方式 1自取 2快递 3自取 快递
          countries: this.checkBOx(this.Location),
          supplyAreas: this.checkBOx(this.Regions),
        },
        ty
      ).then((res) => {
        console.log(res)
        if (res.code == "200" && res.data.data != null) {
          (this.listloading = false), (this.list = res.data.data);
          this.totalItems = res.data.count;
        } else {
           this.list = '';
          // 搜索不到就走首页列表
          Homelatests({}, ty).then((res) => {
            if (res.code == "200") {
               this.NOlist=res.data.data
            }
          });
          
        }
        this.listloading = false;
      });
    },
  },
};
</script>

<style  >
/*
   设置滚动条的宽度 
  .Location_Content ::-webkit-scrollbar, .tree_c::-webkit-scrollbar {
  width: 0;
  
} */
</style>

<style lang="scss" scoped>
@import "@/styles/variables";

.Page_Content {
  width: $contentWidth;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .Page_Content_l {
    width: 260px;
    .Page_Content_c {
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      h2 {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 16px;
        margin-bottom: 15px;
        color: #333333;
        font-size: 18px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .top {
      height: 377px;
      width: 100%;
      box-sizing: border-box;
      .page_tree {
        cursor: pointer;
        b {
          color: #333333;
          font-size: 14px;
          margin-bottom: 10px;
          display: inline-block;
        }
        .active {
          color: #00b968;
          font-weight: bold;
        }
        dl {
          width: 100%;
          padding-left: 10px;

          dt {
            color: #333333;
            font-size: 14px;
            margin-bottom: 6px;
            cursor: pointer;
          }
          dd {
            padding-left: 10px;
            line-height: 20px;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
          }
          .active {
            color: #00b968;
            font-weight: bold;
          }
        }
      }
    }
    .Total {
      width: 100%;
      .Total_Content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          .el-input {
            width: 75px;
            height: 36px;
            font-size: 12px;

            ::v-deep input {
              color: #999999;
              height: 36px !important;
              box-sizing: border-box;
              border: 1px solid #767676 !important;
            }
          }
          p {
            background: #767676;
            height: 1px;
            width: 14px;
            margin: 0 6px;
          }
        }
        span {
          color: #767676;
          background: none;
          border: 1px solid #767676;
          height: 36px;
          line-height: 36px;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
    .Location {
      .Location_Content {
        label {
          display: block;
          color: #333333;
          font-size: 12px;
          margin-bottom: 5px;
        }
        img {
          width: 15px;
          height: 15px;
          display: inline-block;
        }
      }
    }
  }
  .Page_Content_r {
    width: 1100px;
    h3 {
      color: #333333;
      font-size: 12px;
      margin-top: 10px;

      em {
        color: #00b968;
        font-style: normal;
      }
    }
    ul {
      display: table;

      li {
        width: 260px;
        margin-right: 20px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        margin-top: 20px;
        display: inline-block;

        &:nth-child(4n) {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: 272px;
        }

        div {
          padding: 12px;
          box-sizing: border-box;
          height: 128px;

          span {
            color: #333333;
            font-size: 14px;
            width: 100%;
            height: 36px;
            line-height: 1.3em;
            display: inline-block;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }

          h4 {
            color: #333333;
            font-size: 20px;
            margin-top: 12px;
            height: 23px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            margin-top: 20px;
            color: #999999;
            font-size: 12px;

            img {
              width: 18px;
              height: 10px;
              display: inline-block;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
