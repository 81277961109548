<template>
  <!-- 底部 -->
  <div class="ft">
    <div class="content">
      <div class="Feedback" >
        <!-- <i class="iconfont icon-info"></i> -->

        <!-- {{ $t("footer.Feedback") }} -->
      </div>
      <div class="ft_top">
        <dl>
          <dt>{{ $t("footer.Supplies") }}</dt>
          <dd @click="$router.push('/supply/supply')">{{ $t("footer.Information") }}</dd>
          <dd @click="goNewTab1('supply')">{{ $t("footer.Supply") }}</dd>
        </dl>
        <dl>
          <dt>{{ $t("footer.Recycle") }}</dt>
          <dd @click="$router.push('/supply/Recycle')">{{ $t("footer.VRecycle") }}</dd>
          <dd @click="goNewTab1('demand')">{{ $t("footer.recycle") }}</dd>
        </dl>
        <dl>
          <dt>{{ $t("footer.KnowUs") }}</dt>
          <dd @click="$router.push('/aboutUs')">{{ $t("footer.About") }}</dd>
          <dd @click="$router.push('/contactUs')">{{ $t("footer.Contact") }}</dd>
           <dd @click="$router.push('/helpCenter/survey')"> {{ $t("footer.Feedback") }}</dd>
        </dl>
        <dl>
          <dt>{{ $t("footer.Support") }}</dt>
          <dd @click="$router.push('/helpCenter')">{{ $t("footer.Help") }}</dd>
          <dd @click="$router.push('/terms/termsofUse')">Terms of Use</dd>
          <dd  @click="$router.push('/terms/privacypolicy')">{{ $t("footer.Privacy") }}</dd>
        </dl>
        <dl>
          <dt>{{ $t("footer.Follow") }}</dt>
          <dd>
            <!-- <img
              src="../assets/images/footer/1.png"
              alt=""
              @click="shareTO('https://twitter.com/Feijiunet127360')"
            /> -->
          <img
              src="../assets/images/footer/2.png"
              alt=""
              @click="shareTO('https://twitter.com/Feijiunet127360')"
            />
              <!-- <img
              src="../assets/images/footer/3.png"
              alt=""
              @click="shareTO()"
            /> -->
            <!-- <img
              src="../assets/images/footer/4.png"
              alt=""
              @click="shareTO()"
            /> -->
               <img
              src="../assets/images/footer/6.png"
              alt=""
              @click="shareTO('https://www.tiktok.com/@feijiunet2024')"
            />
          </dd>
          <dd>
            <!-- <img
              src="../assets/images/footer/5.png"
              alt=""
              @click="shareTO()"
            /> -->
            <!-- <img
              src="../assets/images/footer/6.png"
              alt=""
              @click="shareTO('https://www.tiktok.com/@feijiunet2024')"
            /> -->
          </dd>
        </dl>
      </div>
      <div class="top_b">
        {{ $t("footer.Provider") }}
      </div>
    </div>
  </div>
</template>

<!-- Cloudflare Web Analytics -->
<script>
export default {
  data() {
    return {
      share_url: "www.feijiu.com", //地址
      share_title: "Feijiu.com：来自世界废旧金属在线 B2B 市场的供应商、回收商、出口商和进口商", //文案
    };
  },
  methods: {
    goNewTab1(type){
      let newUrl = this.$router.resolve({
        path: "/publish",
        query: {
          type: type,
        },
      });
      window.open(newUrl.href, "_blank");
    },
    shareFacebook() {
      // 携带参数
      function popupwindow(url, title) {
        return window.open(
          "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
          +"&t=" + encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share_url, this.share_title);
    },

    //  跳转主页
    shareTO(url) {
      if (url) {
        window.open(
          url,
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      } else {
        this.$message({
          message: "No address",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.ft {
  display: table;
  background: #3f4145;
  width: 100%;
  margin-top: 20px;
  .content {
    width: $contentWidth;
    margin: 0 auto;
     background: #3f4145;
    .Feedback {
      margin-top: 35px;
      text-align: right;
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      i {
        font-size: 24px;
      }
    }
    .ft_top {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      margin: 20px  auto 0;
      width: 1200px;
      dl {
        dt {
          font-size: 18px;
          margin-bottom: 10px;
        }
        dd {
          line-height: 28px;
          font-size: 14px;
          cursor: pointer;
          img {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .top_b {
      border-top: 1px solid #575757;
      margin-top: 40px;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      padding: 24px 0 28px;
      box-sizing: border-box;
    }
  }
}
</style>
