// import { getlanguages } from '@/api/home'
import Cookies from 'js-cookie'
const state = () => {
  return {
   
    language: { //默认语言英语
      text: 'English',
      value: 'En'
    },

    currency: { // 默认货币
      code: "USD",
      symbol: "$",
      nameDe: "Dollar",
      nameEn: "Dollar",
      nameEs: "Dólar",
      nameFr: "Dollar",
      namePt: "Dólar",
      nameRu: "доллар",
      nameTr: "Dolar",
      nameZh: "Dollar",
    },
    nation: { //默认国家 
      code: "USA",
      continentsCode: "NA",
      flagUrl: "USA.jpg",
      nameDe: "Vereinigte Staaten von Amerika",
      nameEn: "United States",
      nameEs: "Estados Unidos",
      nameFr: "États-Unis",
      namePt: "Estados Unidos",
      nameRu: "Соединенные Штаты",
      nameTr: "Birleşik Devletler",
      nameZh: "美国",
    }
  }

}
const mutations = {
  setlanguage(state, index) { //切换语言
    console.log('切换语言', index)
    state.language = index
    location.reload()

  },
  setcurrency(state, index) { //切换货币
    console.log('切换货币', index)
    state.currency = index
    location.reload()
  },
  setnation(state, index) { //切换国家
    console.log('切换国家', index)
    state.nation = index
    location.reload()
  },


  // renderLangsList(state, langs) {
  //   state.langList = langs; // 将获取到的语言列表存储到 Vuex 的 state 中的 langsList 属性中
  // }

}
const actions = {
  // getLangsList({ commit, state }, params) {
  //     return new Promise((resolve, reject) => {

  //       getlanguages()
  //           .then((res) => {
  //             console.log(res)

  //             commit('renderLangsList',  convertObjectToArray(res.data) )
  //             // commit('setUserInit', true)
  //             resolve()
  //           })
  //           .catch((error) => {
  //             reject(error)
  //           })

  //     })

  // },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
