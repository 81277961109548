<template>
  <!-- 注册登录 -->
  <div class="userSign_page">
    <template v-if="!this.getToken()">
      <!-- 没有登录需要跳转到登录页面 -->
      <router-link to="/login/signIn">{{ $t("home.top.Sign") }} </router-link>
      {{ $t("home.top.or") }}
      <router-link to="/login/creatAccount/creat"> {{ $t("home.top.Join") }}</router-link>
    </template>
    <template v-else>
      <!-- 已经登录了 -->
      <el-popover
        popper-class="UserSign"
        placement="bottom"
        title=""
        width="230"
        trigger="hover"
      >
        <ul class="langlist">
          <li
            v-for="(item, index) in userSign"
            :key="index"
            @click="tabs(item)"
          >
            {{ $t(item.key) }}
          </li>
        </ul>
        <div slot="reference">{{getNickname()}}</div>
      </el-popover>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { removeToken ,getToken ,getMessage,removeMessage,getNickname,removeNickname} from "@/utils/cookie";
export default {
  data() {
    return {
      userSign: [
        { key: "home.top.MyFeijiu", path: "/staging/home" }, //我的Feijiu（工作台）
        { key: "home.top.Disposal", path: "/staging/products" }, //处置
        { key: "home.top.Recycle", path: "/staging/recovery" }, //回收
        { key: "home.top.Messages", path: "/staging/inquiry" }, //消息
        { key: "home.top.Favorites", path: "/staging/business" }, //收藏
        { key: "home.top.Information", path: "/staging/message" }, //个人信息
          { key: "home.top.password", path: "/staging/changePassword" }, //修改个人密码
        { key: "home.top.Signout", path: "token" }, //退出登录
      ],
    };
  },
  computed: {
  },
  methods: {
    getToken(){
      return getToken();
    },
    getMessage(){
      return getMessage()
    },
    getNickname(){
      return getNickname()
    },
    tabs(item) {
      if (item.path == "token") {
        removeToken();
        removeMessage()
        removeNickname()
        location.reload()
      } else {
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>

<style lang="scss" >
.UserSign {
  padding: 10px 0 !important;
  border: none !important;
  ul {
    li {
      padding-left: 16px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables";
.userSign_page {
  a {
    display: inline-block;
    color: $importantColor;
    &:hover {
      color: $importantColor;
    }
  }
}
.langlist {
  li {
    line-height: 30px;
    cursor: pointer;
    &:hover {
      background: #f4f4f4;
      color: #00b968;
    }
  }
  .active {
    background: #00b968;
    color: #ffffff;
  }
}
</style>
