

const state = () => {
  return {
    navActive: 0, // 导航索引
    NavList: [
      { name: 'home.nav.0', value: '/' },
      { name: 'home.nav.1', value: '/supply/supply' },
      { name: 'home.nav.2', value: '/supply/Recycle' },
      { name: 'home.nav.3', value: '/helpCenter' },
      { name: 'home.nav.4', value: '/news' },
      { name: 'home.nav.5', value: '/exhibition' },
      { name: 'home.nav.6', value: '/aboutUs' },
      { name: 'home.nav.7', value: '/contactUs' },

    ],
    userparm: {} //用户数据
  }

}

const mutations = {
  setNavActive(state, index) {
    state.navActive = index
  },
  setUser(state, index) {
    state.userparm = index
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
