<template>
  <div class="container">
    <heads></heads>
    <!-- 导航栏组件 -->
    <breadCrumbs/>

    <div class="content">
      <div class="requirement">{{
          $route.query.type == 'supply' ? $t('release.information.PublishingInformation')
            : $t('release.requirement.PostRequirement')
        }}
      </div>
      <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="uForm">
        <el-form-item :label="$t('release.requirement.ProductName')" prop="name">
          <div>
            <div class="tips">{{ $t('release.requirement.EnterName') }}</div>
            <el-input v-model="form.name" style="width: 80%;" :minlength="10" :maxlength="500"
                      :placeholder="$route.query.type=='supply'?$t('release.information.ForSteel')
        :$t('release.requirement.ForSteel')"></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('release.requirement.Categories')" required prop="categoryCode">
          <el-col :span="8">
            <el-select v-model="oneCategories" :placeholder="$t('release.requirement.PleaseSelectCategories')" filterable
                       style="width: 90%;" @change="oneCategoriesChange">
              <el-option
                v-for="item in oneCategoriesList"
                :key="item.code"
                :label="$getName(item)"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select v-model="twoCategories" :placeholder="$t('release.requirement.PleaseSelectCategories')" filterable
                       style="width: 90%;" @change="twoCategoriesChange">
              <el-option
                v-for="item in twoCategoriesList"
                :key="item.code"
                :label="$getName(item)"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$route.query.type=='supply'?$t('release.information.DisposalQuantity')
        :$t('release.requirement.SourcingQuantity')" prop="number" required>
          <el-col :span="8">
            <el-input-number v-model.number="form.number" controls-position="right" style="width: 90%"
                             :placeholder="$t('release.requirement.PleaseEnter')" :max="100000"></el-input-number>
          </el-col>
          <el-col :span="8">
            <el-select v-model="form.numberUnit" style="width: 90%;">
              <el-option
                v-for="(item,index) in unitList"
                :key="item.code"
                :label="$getName(item)"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$route.query.type=='supply'?$t('release.information.SellingPrice')
        :$t('release.requirement.EexpectedPrice')" required>
          <el-radio-group v-model="form.priceType" @input="priceTypeChange"
                          style="display: flex;flex-flow: column nowrap;align-items: flex-start;">
            <el-radio :label="1">
              <el-input v-model.trim="form.expectedPrice" style="width: 60%"
                        oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                        :placeholder="$t('release.requirement.EnterPrice')"></el-input>
              <el-select v-model="form.priceCurrency" style="width: 60%;margin-left: 20px">
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="$getName(item)"
                  :value="item.code">
                </el-option>
              </el-select>

            </el-radio>
            <el-radio style="margin-top: 20px" :label="2">
              <el-input v-model.trim="form.exptLowPrice" style="width: 30%"
                        oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                        :placeholder="$t('release.requirement.EnterLowPrice')"></el-input>
              -
              <el-input v-model.trim="form.exptHighPrice" style="width: 30%"
                        oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                        :placeholder="$t('release.requirement.EnterHighPrice')"></el-input>
              <el-select v-model="form.priceCurrency" style="width: 40%;margin-left: 20px">
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="$getName(item)"
                  :value="item.code">
                </el-option>
              </el-select>
            </el-radio>
            <el-radio style="margin-top: 20px" :label="3">
              {{ $t('release.requirement.negotiateDirectly') }}
            </el-radio>
          </el-radio-group>

          <!--          <el-col :span="8">-->
          <!--            <el-checkbox v-model="form.negotiateDirectly"></el-checkbox>-->
          <!--          </el-col>-->
        </el-form-item>
        <el-form-item :label="$t('release.requirement.DetailedRequirements')" required>
          <div style="border: 1px solid #ccc;">
            <quill-editor imageType="1" ref="onEditor" v-model="html"
                          @change="onEditorFunc">
            </quill-editor>
          </div>
        </el-form-item>
        <el-form-item :label="$t('release.requirement.ProductImages')">
          <div>
            <div class="tips">{{ $t('release.requirement.PhotosFrom') }}
            </div>
            <el-col :span="2">
              <upload :limit="1" :imagePath="'image'" :image-height="98" :image-width="98" :type="'Whole'"
                      :imageType="$route.query.type=='supply'?'1':'2'"
                      @success="imageClick" :tipText="$t('release.requirement.Whole')" :avatar="whole"></upload>
            </el-col>
            <el-col :span="2">
              <upload :limit="1" :imagePath="'image'" :image-height="98" :image-width="98" :type="'Part'"
                      :imageType="$route.query.type=='supply'?'1':'2'"
                      @success="imageClick" :tipText="$t('release.requirement.Part')" :avatar="part"></upload>
            </el-col>
            <el-col :span="2">
              <upload :limit="1" :imagePath="'image'" :image-height="98" :image-width="98" :type="'Particulars'"
                      :imageType="$route.query.type=='supply'?'1':'2'"
                      @success="imageClick" :tipText="$t('release.requirement.particulars')" :avatar="particulars"></upload>
            </el-col>
            <upload :limit="5" :imagePath="'image'" :image-height="98" :image-width="98" :type="'Others'"
                    :imageType="$route.query.type=='supply'?'1':'2'"
                    @success="imageClick" :tipText="$t('release.requirement.Others')" :showFileList="others"></upload>
          </div>
        </el-form-item>
        <el-form-item :label="$t('release.information.Location')" v-if="$route.query.type=='supply'" required>
          <el-select
            v-model="form.localCountry.code" filterable
            class="nation_select"
          >
            <el-option
              v-for="item in JSONData"
              :key="item.code"
              :label="$getName(item)"
              :value="item.code"
              :icon="$getImagePath(item.flagUrl)">
              <img
                :src="$getImagePath(item.flagUrl)"
                alt=""
                style="
              display: inline-block;
              vertical-align: middle;
              margin-right: 3px;
              width: 27px;
            "
              />
              <span>{{ $getName(item) }}</span>
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item :label="$route.query.type=='supply'?$t('release.information.AvailableRegions')
        :$t('release.requirement.PurchaseLocation')" required>
          <el-col :span="6" v-for="(item,index) in form.supplyAreas" :key="index" style="margin-top: 10px">
            <el-select
              v-model="item.code" filterable clearable
              class="nation_select"
            >
              <el-option
                v-for="item in JSONData"
                :key="item.code"
                :label="$getName(item)"
                :value="item.code"
                :icon="$getImagePath(item.flagUrl)">
                <img
                  :src="$getImagePath(item.flagUrl)"
                  alt=""
                  style="display: inline-block;vertical-align: middle;margin-right: 3px;width: 27px; "
                />
                <span>{{ $getName(item) }}</span>
              </el-option>
            </el-select>

            <el-button v-if="index===form.supplyAreas.length-1&&form.supplyAreas.length<10" type="primary"
                       @click="addSupplyAreas"
                       style="margin-left: 20px">{{$t('release.Add')}}
            </el-button>
            <el-button v-else plain @click="deleteSupplyAreas(index)" style="margin-left: 20px">
              {{$t('staging.Delete')}}
            </el-button>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('release.requirement.DeliveryMethod')" required>
          <el-checkbox v-model="SelfPickup" label="1">{{ $t('release.requirement.SelfPickup') }}</el-checkbox>
          <el-checkbox v-model="Delivery" label="2">{{ $t('release.requirement.Delivery') }}</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <div class="tips1">{{ $t('release.requirement.ReceiveReplyEmail') }}: {{ email }}</div>
      <div class="tips2">{{ $t('release.requirement.WantDisturbed') }}</div>
      <div class="tips3">{{ $t('release.requirement.ReplaceAddress') }}</div>
    </div>
    <div class="select">
      <div v-if="false">
        <el-checkbox v-model="supplierChecked">I agree to share my business card with the intended
          supplier.
        </el-checkbox>
      </div>
      <div style="margin-top: 10px">
        <el-checkbox v-model="rulesChecked">{{ $t('release.requirement.Understood') }}
          <el-link href="/terms/inforreleaserules" target="_blank" type="primary">
            {{ $t('release.requirement.Rules') }}
          </el-link>
        </el-checkbox>
      </div>
      <div style="height: 80px"></div>
      <div style="display: flex;justify-content: center;margin-bottom: 30px">
        <el-button style="width: 310px;height: 48px;" type="primary" @click="release" :disabled="disabled">
          {{ $t('release.requirement.publish') }}
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
import breadCrumbs from "@/components/breadCrumbs";
import upload from "@/components/upload";
import heads from "@/page/publish/components/heads";
import quillEditor from "./components/quillEditor.vue";
import {getCategoryTree} from "@/api/category";
import {getAllCountries, getcurrencies} from "@/api/home";
import {addOrUpdate, getProductDetail, readOrNot} from "@/api/product";
import {getAllUnit, getUserInfo} from "@/api/webuser";
import SuppliesHead from "@/page/publish/components/suppliesHead";
import {removeToken} from "@/utils/cookie";

export default {
  name: "releaseSaleProducts",

   metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.publishing.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.publishing.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.publishing.description"),
          },
        ],
      };
    },
  },

  components: {
    heads,
    breadCrumbs, quillEditor, upload
  },
  data() {
    return {
      supplierChecked: true,
      rulesChecked: false,
      html: '<p></p>',
      text:"",
      toolbarConfig: {excludeKeys: ["headerSelect", "blockquote"]},//toolbarKeys :["bold","underline","italic","fontSize"]
      editorConfig: {placeholder: this.$t('release.requirement.LookingFor')},
      mode: 'default',
      twoCategories: '',
      oneCategories: '',
      whole: '',
      part: '',
      particulars: '',
      others: [],
      oneCategoriesList: [],
      twoCategoriesList: [],
      JSONData: [],
      form: {
        name: "",
        categoryCode: "",
        number: 1,
        numberUnit: "",
        expectedPrice: "",
        exptHighPrice: "",
        exptLowPrice: "",
        priceCurrency: "USD",
        priceType: 1,
        supplyAreas: [{code: "", value: ""}],
        localCountry: {code: "", value: ""},
        deliveryMethod: "",
        images: []
      },
      showList: [],
      unitList: [],
      Delivery: false,
      SelfPickup: false,
      disabled:false,
      options: [],
      rules: {
        name: [{required: true, message: this.$t('release.requirement.PleaseCharacters10'), trigger: 'blur'},
          {min: 10, message: this.$t('release.requirement.PleaseCharacters10'), trigger: 'blur'},
          {max: 500, message: this.$t('release.requirement.PleaseCharacters500'), trigger: 'blur'},
        ],
        categoryCode: [{
          required: true,
          message: this.$t('release.requirement.PleaseSelectCategories'),
          trigger: 'blur'
        }],
        number: [{
          required: true,
          message: this.$route.query.type == 'supply' ? this.$t('release.information.TheDisposalGreater')
            : this.$t('release.requirement.PurchaseQuantity'),
          trigger: 'blur'
        },],
      },
      toolbar: null,
      email: "",
    }
  },
  watch: {
    "$route.query.type": "init",
  },
  mounted() {
    this.init()
  },
  methods: {
    priceTypeChange(res){
      if (res==1){
        this.form.exptLowPrice=""
        this.form.exptHighPrice=""
      }else if (res==2){
        this.form.expectedPrice=""
      }else {
        this.form.exptLowPrice=""
        this.form.exptHighPrice=""
        this.form.expectedPrice=""
      }
    },
    // 富文本编辑器监听
    onEditorFunc({ editor, html, text }) {
      this.text=text
      this.html = html
    },
    imageClick(data) {
      if (data.type == "Whole") {
        this.whole = data.data[0]
      } else if (data.type == "Part") {
        this.part = data.data[0]
      } else if (data.type == "Particulars") {
        this.particulars = data.data[0]
      }
      // else if (data.type == "Others") {
      //   this.others = []
      //   data.data.forEach((item, index) => {
      //     this.others.push({
      //       name: index,
      //       url: item
      //     })
      //   })
      // }

      this.form.images=this.form.images.filter(item => item.code !== data.type)
      console.log(this.form.images)
      data.data.forEach((item) => {
        this.form.images.push({code: data.type, value: item})
      })
    },
    release() {
      let than = this
      if (!this.rulesChecked) {
        this.$message({
          message: than.$t('release.PleaseReadAgreeRules'),//
          type: 'warning'
        })
        return
      }
      this.$refs.uForm.validate(async valid => {
        if (valid) {
          if (parseFloat(this.form.number) > 100000) {
            this.$message({
              message: than.$route.query.type == 'supply' ? than.$t('release.information.TheDisposalGreater')
                : than.$t('release.requirement.PurchaseQuantity'),
              type: 'warning'
            })
            return
          }

          if (this.form.priceType == 1) {
            if (!this.form.expectedPrice || parseFloat(this.form.expectedPrice) > 999999) {
              this.$message({
                message: than.$t('release.requirement.PriceInputRange'),
                type: 'warning'
              })
              return
            }
          } else if (this.form.priceType == 2) {
            if (!this.form.exptLowPrice || parseFloat(this.form.exptLowPrice) > 999999) {
              this.$message({
                message: than.$t('release.requirement.PriceInputRange'),
                type: 'warning'
              })
              return
            }
            if (!this.form.exptLowPrice && !this.form.exptHighPrice &&
              parseFloat(this.form.exptLowPrice) > parseFloat(this.form.exptHighPrice)) {
              this.$message({
                message: than.$t('release.requirement.EnteredPriceAgain'),
                type: 'warning'
              })
              return
            }
          }
          console.log(this.text)
          if (!this.text || this.text.length < 50) {
            this.$message({
              message: than.$t('release.requirement.PleaseCharacters'),
              type: 'warning'
            })
            return;
          }

          if (this.text.length > 1000) {
            this.$message({
              message: than.$t('release.requirement.PleaseCharacters1000'),
              type: 'warning'
            })
            return;
          }

          if (this.$route.query.type == 'supply' && !this.form.localCountry.code) {
            this.$message({
              message: than.$t('release.information.PleaseLocation'),
              type: 'warning'
            })
            return
          }

          var isSupplyAreas = false
          this.form.supplyAreas.forEach((item, index) => {
            if (item.code) {
              isSupplyAreas = true
            }
          })

          if (this.$route.query.type == 'supply' && !isSupplyAreas) {
            this.$message({
              message: than.$t('release.information.PleaseSelectRegions'),
              type: 'warning'
            })
            return
          }

          if (this.$route.query.type != 'supply' && !isSupplyAreas) {
            this.$message({
              message: than.$t('release.requirement.PleaseLocation'),
              type: 'warning'
            })
            return
          }

          if (!this.SelfPickup && !this.Delivery) {
            this.$message({
              message: than.$t('release.requirement.PleaseMethod'),
              type: 'warning'
            })
            return
          }

          this.form.supplyAreas = this.form.supplyAreas.filter(item => {
            return item.code !== undefined && item.code !== null && item.code !== '';
          });
          let uniqueArray = Array.from(new Set(this.form.supplyAreas.map(item => item.code))).map(code => {
            return this.form.supplyAreas.find(obj => obj.code === code);
          });
          this.form.supplyAreas=uniqueArray
          if (this.SelfPickup && this.Delivery) {
            this.form.deliveryMethod = 3
          } else if (this.SelfPickup) {
            this.form.deliveryMethod = 1
          } else if (this.Delivery) {
            this.form.deliveryMethod = 2
          }
          if (this.form.priceType == 2) {
            this.form.expectedPrice = this.form.exptLowPrice
          }
          this.disabled=true
          this.form.description = this.html
          addOrUpdate(this.$route.query.type, this.form).then((res) => {
            if (res.code == 200) {
              this.disabled=false
              if (this.$route.query.code) {
                window.close()
              } else {
                this.$message({
                  message: this.$t('release.PublishedSuccessful'),
                  type: "success"
                })
                this.form={
                  name: "",
                  categoryCode: "",
                  number: 1,
                  numberUnit: "",
                  expectedPrice: "",
                  exptHighPrice: "",
                  exptLowPrice: "",
                  priceCurrency: "USD",
                  priceType: 1,
                  supplyAreas: [{code: "", value: ""}],
                  localCountry: {code: "", value: ""},
                  deliveryMethod: "",
                  images: []
                }
                this.twoCategories = ''
                this.oneCategories = ''
                this.whole = ''
                this.part = ''
                this.particulars = ''
                this.others = []
                this.Delivery=false
                this.SelfPickup= false
                this.html= '<p></p>'
                // 清空表单
                this.$nextTick(() => {
                  this.$refs.uForm.resetFields();
                });
              }
            }else if (res.code == 401) {
              this.disabled=false
              removeToken()
              this.$router.push({
                path: "/login/signIn"
              })
            }else if (res.code==555){
              setTimeout(() => {
                this.disabled=false
              }, 2000)
            }else {
              this.disabled=false
            }
          })
        }else {
          window.scrollTo(0,0)
        }
      })

    },
    init() {

      this.getCategory()
      this.getCurrencies()
      this.selectData()
      this.getUnit()
      this.readNot()
      this.getInfo()
    },
    getInfo() {
      getUserInfo().then((res) => {
        this.email = res.data.email
      })
    },
    readNot() {
      readOrNot().then((res) => {
        this.rulesChecked = res.data
      })
    },
    getUnit() {
      getAllUnit().then((res) => {
        if (res.code == 200) {
          this.unitList = res.data
          this.form.numberUnit = this.unitList[0].code
        }
      })
    },
    // 转义富文本文案
    decodeHtml(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
    getDetail() {
      getProductDetail(this.$route.query.code).then((res) => {
        if (res.code == 401) {
          removeToken()
          this.$router.push({
            path: "/login/signIn"
          })
        }else if (res.code==200){
          this.form = res.data
          if (res.data.priceType==2){
            this.form.exptLowPrice=res.data.expectedPrice
            this.form.expectedPrice=""
          }
          this.html = this.decodeHtml(this.form.description)
          this.form.number = res.data.number
          this.form.images.forEach((item, index) => {
            if (item.code == "Whole") {
              this.whole = item.value
            } else if (item.code == "Part") {
              this.part = item.value
            } else if (item.code == "Particulars") {
              this.particulars = item.value
            } else if (item.code == "Others") {
              this.others.push({
                name: index,
                url: item.value
              })
            }
          })
          if (this.form.deliveryMethod == 1) {
            this.SelfPickup = true
          } else if (this.form.deliveryMethod == 2) {
            this.Delivery = true
          } else {
            this.SelfPickup = true
            this.Delivery = true
          }
          this.oneCategoriesList.forEach((item, index) => {
            if (this.form.categoryCode == item.extendCode) {
              this.oneCategories = item.extendCode
              this.twoCategoriesList = item.children
            } else {
              if (item.children) {
                item.children.forEach((item1, index) => {
                  if (this.form.categoryCode == item1.code) {
                    this.oneCategories = item.extendCode
                    this.twoCategoriesList = item.children
                    this.twoCategories = item1.code
                  }
                })
              }
            }
          })
        }
      })
    },
    addSupplyAreas() {
      this.form.supplyAreas.push({code: "", value: ""})
    },
    deleteSupplyAreas(index){
      this.form.supplyAreas.splice(index, 1)
    },
    // 国家数据
    selectData() {
      getAllCountries().then((res) => {
        this.JSONData = res.data;
      });
    },
    getCurrencies() {
      getcurrencies().then((res) => {
        this.options = res.data
      })
    },
    twoCategoriesChange(data) {
      this.form.categoryCode = data
    },
    oneCategoriesChange(data) {
      this.twoCategories = ""
      this.form.categoryCode = data
      this.oneCategoriesList.forEach((item, index) => {
        if (item.code == data) {
          this.twoCategoriesList = item.children
        }
      })
    },
    getCategory() {
      getCategoryTree().then((res) => {
        this.oneCategoriesList = res.data
        if (this.$route.query.code) {
          this.getDetail()
        }
      })
    },

  }
}
</script>

<style lang="scss">
.container {
  width: 100%;
  background: #F6F6F6;

  .content {
    background: #FFFFFF;
    width: 1380px;
    margin: 0 auto;
    // margin-left: 240px;
    padding: 20px;

    .requirement {
      font-size: 24px;
      font-weight: normal;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .tips {
      font-size: 14px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }

  .bottom {
    display: flex;
    margin: 20px auto;
    width: 1380px;
    padding: 30px 30px 0px 30px;

    .tips1 {
      color: #333333;
      font-size: 14px;
    }

    .tips2 {
      color: #666666;
      font-size: 14px;
      margin-left: 4px;
    }

    .tips3 {
      color: #005CFF;
      font-size: 14px;
      margin-left: 4px;

    }
  }

  .select {
    display: block;
    margin: 0px auto;
    width: 1380px;
    padding: 0px 0px 0px 30px;
  }

}

::v-deep .el-form .el-form-item__label {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  line-height: 18px;
}

//::v-deep .el-checkbox-group .el-checkbox {
//  display: flex;
//  align-items: center;
//}
</style>
