import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../page/home/index.vue'
import Login from '../page/login/signIn/index.vue'
import ForgetPassword from '../page/login/forgetPassword/index.vue'
import CreatAccount from '../page/login/creatAccount/index.vue'
import Staging from '../page/staging/index'
import News from '../page/news/index.vue'
import Publish from '../page/publish/index.vue'
import NewsDetail from '../page/newsDetail/index.vue'
import Exhibition from '../page/exhibition/index.vue'
import ExhibitionDetail from '../page/exhibitionDetail/index.vue'
import HelpCenter from '../page/helpCenter/index.vue'
import Qustion from '../page/helpCenter/qustion/index.vue'
import Survey from '../page/helpCenter/survey/index.vue'
import SearchResult from '../page/helpCenter/searchResult/index.vue'
import AboutUs from '../page/aboutUs/index.vue'
import ContactUs from '../page/contactUs/index.vue'
import Supply from '../page/supply/index.vue'
import supplyDetail from '../page/supply/supplyDetail/index.vue'
import publishSummary from "@/page/publish/publishSummary";
import { getToken } from "@/utils/cookie";


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
//修改原型对象中的push方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/', // 首页
    name: 'home',
    component: Home,
  },

  {
    path: '/login/signIn', // 登录
    name: 'login',
    component: Login,
  },
  {
    path: '/login/forgetPassword/:step', // 忘记密码
    name: 'forgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/login/creatAccount/:step', // 创建账户
    name: 'creatAccount',
    component: CreatAccount,
  },
  {
    path: '/supply/:nav', // 物资列表
    name: 'supply',
    component: Supply,
  },
  {
    path: '/supply/:nav/supplyDetail/', // 物资详情
    name: 'supplyDetail',
    component: supplyDetail,
  },
  {
    path: '/news', // 新闻
    name: 'news',
    component: News,

  },
  {
    path: '/publish', // 发布
    name: 'publish',
    component: Publish,
  },
  {
    path: '/publish/publishSummary', // 发布
    name: 'publishSummary',
    component: publishSummary,
  },
  {
    path: '/news/newsDetail', // 新闻详情
    name: 'newsDetail',
    component: NewsDetail,
  },
  {
    path: '/exhibition', // 展览
    name: 'exhibition',
    component: Exhibition,
  },
  {
    path: '/exhibition/exhibitionDetail', // 展览详情
    name: 'exhibitionDetail',
    component: ExhibitionDetail,
  },
  {
    path: '/aboutUs', // 关于我们
    name: 'aboutUs',
    component: AboutUs,
  },
  {
    path: '/contactUs', // 联系我们
    name: 'contactUs',
    component: ContactUs,
  },
  {
    path: '/helpCenter', // 帮助中心
    name: 'helpCenter',
    component: HelpCenter,
  },
  {
    path: '/helpCenter/qustion/:step', // 问题列表
    name: 'qustion',
    component: Qustion,
  },
  {
    path: '/helpCenter/survey', // 意见反馈
    name: 'survey',
    component: Survey,
  },
  {
    path: '/helpCenter/searchResult', //搜索结果
    name: 'searchResult',
    component: SearchResult,
  },
  // 工作台从这里开始
  {
    path: "/staging/home",//工作台-首页
    name: "Home",
    meta: {
      icon: 'home',
      title: 'Home',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/home',
        name: 'homePage',
        meta: {
          icon: 'home',
          title: 'home',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/home/homePage')
      },
    ]
  },
  {
    path: "/staging/products",//工作台-处置
    name: "Sale Product",
    meta: {
      icon: 'sale_products',
      title: 'SaleProducts',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/products',
        name: 'products',
        meta: {
          icon: 'sale_products',
          title: 'SaleProducts',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/products/saleProducts')
      },
    ]
  },
  {
    path: "/staging/recovery",//工作台-回收
    name: "Recovery",
    meta: {
      icon: 'recovery',
      title: 'Recycle',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/recovery',
        name: 'recovery',
        meta: {
          icon: 'recovery',
          title: 'Recovery',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/recovery/recoveryList')
      },
    ]
  },
  {
    path: "/staging/inquiry",//工作台-处理
    name: "Inquiry",
    meta: {
      icon: 'inquiry',
      title: 'Inquiry',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/inquiry',
        name: 'inquiry',
        meta: {
          icon: 'inquiry',
          title: 'Inquiry',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/inquiry/inquiryList')
      },
    ]
  },
  {
    path: "/staging/business",//工作台-商机
    name: "Business",
    meta: {
      icon: 'business',
      title: 'Business',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/business',
        name: 'business',
        meta: {
          icon: 'business',
          title: 'business',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/business/businessList')
      },
    ]
  },
  {
    path: "/staging/message",//工作台-消息列表
    name: "Message",
    meta: {
      icon: 'message',
      title: 'Messages',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/message',
        name: 'message',
        meta: {
          icon: 'message',
          title: 'Message',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/message/messageList')
      },
    ]
  },

  // {
  //   path: "/staging/email",//工作台-邮箱
  //   name: "Email Sevices",
  //   meta: {
  //     icon: 'email_sevices',
  //     title: 'EmailServices',
  //     isStaging: true,
  //   },
  //   component: Staging,
  //   children: [
  //     {
  //       path: '/staging/email',
  //       name: 'email Sevices',
  //       meta: {
  //         icon: 'email_sevices',
  //         title: 'EmailServices',
  //       },
  //       component: () => import('@/page/staging/email/emailServices')
  //     },
  //   ]
  // },
  {
    path: "/staging/changePassword",//工作台-修改密码
    name: "Change password",
    meta: {
      icon: 'change_password',
      title: 'ChangePassword',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/changePassword',
        name: 'change password',
        meta: {
          icon: 'change_password',
          title: 'ChangePassword',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/changePassword/index')
      },
    ]
  },
  {
    path: "/staging/settings",//工作台-设置
    name: "Settings",
    meta: {
      icon: 'settings',
      title: 'Settings',
      isToken: true,
      isStaging: true,
    },
    component: Staging,
    children: [
      {
        path: '/staging/settings',
        name: 'settings',
        meta: {
          icon: 'settings',
          title: 'Settings',
          isToken: true,
          isStaging: true,
        },
        component: () => import('@/page/staging/settings/settingsConfig')
      },
    ]
  },
  // 工作台结束


  // 邮箱验证页面
  {
    path: '/system/verifyCode/:id',
    name: 'verifyCode',
    component: () => import('@/page/verify-workspace')
  },
  // 使用条款
  {
    path: '/terms/termsofUse',
    name: 'Terms1',
    component: () => import('@/page/terms/termsofUse'),

  },
  //隐私政策
  {
    path: '/terms/privacypolicy',
    name: 'Terms2',
    component: () => import('@/page/terms/privacypolicy'),

  },
  //信息发布规则
  {
    path: '/terms/inforreleaserules',
    name: 'Terms3',
    component: () => import('@/page/terms/inforreleaserules'),

  },
  //个人信息跨境传输同意函
  {
    path: '/terms/infortransmission',
    name: 'Terms4',
    component: () => import('@/page/terms/infortransmission'),

  },
  {// 立即聊天页面
    path: '/openIm',
    name: 'im',
    component: () => import('@/page/openIm/index'),

  },

  {
    path: "/404",
    name: "NotFound",
    component: () => import("../page/404.vue"),
    hidden: true,
    meta: { title: "404" },
  },
  { path: "/:pathMatch(.*)", redirect: "/404", name: "notMatch", hidden: true },





]

const router = new VueRouter({
  //  hash history
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})
//需要登录的页面
const loginWhiteList=["/publish","/publish/publishSummary"]

router.beforeEach((to, from, next) => {

  console.log('to', to, "from", from)
  if ((loginWhiteList.includes(to.path)||(to.params && to.params.isToken)) && !getToken()) {
    next('/login/signIn'); // 判断是否需要登录跳转登录
  } else if (to.path === '/home') { // 如果目标路由是首页
    next('/'); // 跳转到首页
  } else {
    next(); // 继续正常跳转
  }

})
export default router
