<template>
    <div class="reconContent">
        <div class="title">{{ $t('Exhibition.recommendedEvents') }} </div>
        <div style="width: 100%;">

        
        <div class="cell-box" v-for="(item, index) in list" :key="index" @click="itemClick(item)">
            <div class="img-box" v-if="item.coverImage">
                <img :src="item.coverImage" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer"
                    class="img">
            </div>
        
            <!-- 类型、主标题 、副标题、时间、发布者-->
         
            <div class="detail-box">
                <div class="detailTitle">{{ item.exhibitionName }}</div>
                <div class="detailDate">{{ item.holdStartTime | formatDate }} - {{ item.holdEndTime | formatDate }} </div>
                <div class="detailKeywords">{{ item.keyword }}</div>

            </div>
        </div>
    </div>
    </div>
</template>
<script>

import { getExhibitionTopList } from "@/api/news";
import moment from "moment"; //引入moment

export default {
    components: {
    },
    data() {
        return {
            list: []
        }
    },
    filters: {
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD");
        }
    },
    mounted() {
        this.getData()
    },
    destroyed() {
    },
    methods: {
        getData() {
            getExhibitionTopList({ num: 10,id:this.$route.query.id }).then((res) => {
                if (res.code == '200') {
                    this.list = res.data.list
                }
            })
        },
        itemClick(item) {
            this.$router.push({
                path: '/exhibition/exhibitionDetail',
                query: {
                    id: item.id
                }
            })
        },
    }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.reconContent {
     width: $contentWidth;
    background: #F6F6F6;
    // background:red;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    // margin-left: 240px;

    .title {
        width: 100%;
        height: 90px;
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        padding: 50px 0px 12px 0px;
    }
    div{
        width: 100%;
        .cell-box {
        width: 680px;
        // height: 161px;
        // margin-right: 20px;
        background: #FFFFFF;
        margin-bottom: 10px;
        display: inline-block;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
            &:nth-child(2n){
                margin-left: 20px !important;
            }
        .img-box {
            width: 120px;
            height: 120px;
            background: #ddd;
            flex-shrink: 0;
            border-radius: 50%;

            .img {
                width: 120px;
                height: 120px;
                border-radius: 50%;

            }

        }
        .detail-box {
            padding: 18px 20px 18px 20px;
            box-sizing: border-box;
            height: 100%;

            .detailTitle {
                height: 20px;
                line-height: 20px;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                overflow: hidden;
                display: -webkit-box;
                /* 设置最大显示行数 */
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            .detailDate {
                height: 17px;
                line-height: 17px;
                font-size: 16px;
                margin-top: 12px;
                color: #00B968;
                overflow: hidden;
                display: -webkit-box;
                /* 设置最大显示行数 */
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            .detailKeywords {
                height: 17px;
                line-height: 17px;
                font-size: 16px;
                margin-top: 12px;
                color: #999999;
                overflow: hidden;
                display: -webkit-box;
                /* 设置最大显示行数 */
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }
    }
        
    }
 


}
</style>
