<template>
  <div class="aboutUs">
    <img class="img" src="@/assets/images/helpCenter/aboutUs.jpg" alt="">
    <div class="aboutsContent">
        <div class="title">About Feijiu</div>
        <div class="htmlText">{{$t('Help.AboutUs.title')}}</div>

    </div>
  </div>
</template>

<script>
export default {
     metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.About.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.About.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.About.description"),
          },
        ],
      };
    },
  },
  
  data() {
    return {}
  },
  components:{},
  /*
  组件创建后
  经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
  */ 
  created() {
  },
  /*
  挂载后
  如果要操作当前组件的dom 最早 只能在mounted阶段执行
  */ 
  mounted() {
        
  },
  methods: {
        
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.aboutUs{
    width: 100%;
    background:#F6F6F6 ;
    display: flex;
    flex-direction: column;
     align-items: center;
    .img {
        width: 100%;
    }
    .aboutsContent{
        width: $contentWidth;
        background: #fff;
        margin-top: 20px;
        padding: 24px 30px 24px 30px;

        .title {
          font-size: 24px;
          color: #333333;
          font-weight: 600;
        }
    }
    .htmlText {
      margin-top: 20px;
      white-space: pre-wrap;

    }
}
</style>