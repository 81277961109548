<template>
  <!-- 公共头部 -->
  <div class="contnet_head">
      <taBbar />
    <suppliesHead />
  </div>
</template>
<script>
import suppliesHead from "@/page/publish/components/suppliesHead";
import taBbar from '@/components/tabBar'
// import userSign from "@/components/userSign.vue"; //語言切換
// import language from "@/components/language.vue"; //語言切換
// import Currency from "@/components/currency"; //货币
// import Nation from "@/components/nation"; //国家
export default {
  components: {
    suppliesHead,
    taBbar
    // userSign,
    // language,
    // Currency,
    // Nation,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.contnet_head {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;

  .headt {
    border-bottom: 1px solid #e6e7eb;
  }
}
</style>
