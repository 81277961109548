import Vue from 'vue'
import Vuex from 'vuex'
import lang from './modules/lang'
import home from './modules/home'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    lang,
    home,

  },
})
