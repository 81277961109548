
<template>
    <div class="detailContent">
        <div class="type">{{ newsDetailData.categoryName }}</div>
        <div class="title">{{ newsDetailData.title}}</div>
        <div class="share">
            <img src="@/assets/images/home/news_date.png" class="dateImg" alt="">
            <span class="dateTitle">{{ newsDetailData.createTime | formatDate }}</span>
            <img src="@/assets/images/home/news_look.png" class="lookImg" alt="">
            <span class="dateTitle">{{ newsDetailData.browseNum }}</span>
            <!-- <img src="@/assets/images/home/news_by.png" class="lookImg" alt="">
            <span class="dateTitle">{{ newsDetailData.author }}</span> -->
            <img src="@/assets/images/home/LinkedIN.png" class="linkedinImg" @click="linkedinClick" alt="">
            <img src="@/assets/images/home/Twitter.png" class="twitterImg" @click="twitterClick" alt="">
            <img src="@/assets/images/home/Facebook.png" class="facebookImg" @click="faceBookClick" alt="">
        </div>
        <img :src="newsDetailData.coverImage" class="contentImg" alt="" v-if="newsDetailData.coverImage">
        <!-- <div class="detail-Title" v-html="newsDetailData.content"></div> -->
        <div class="ql-container ql-snow">
            <div class="ql-editor">
                <div v-html="newsDetailData.content"></div>
            </div>
        </div>
        <div class="bottomShare">
            <span class="shareTitle"> {{ $t('News.share') }} </span>
            <img src="@/assets/images/home/LinkedIN.png" class="linkedinImg" @click="linkedinClick" alt="">
            <img src="@/assets/images/home/Twitter.png" class="twitterImg" @click="twitterClick" alt="">
            <img src="@/assets/images/home/Facebook.png" class="facebookImg" @click="faceBookClick" alt="">
        </div>
        <div class="source" v-if="newsDetailData.sources">News sources:{{newsDetailData.sources}}</div>
    </div>
</template>
<script>
import moment from "moment"; //引入moment
import { sendShare } from "@/api/supply";
export default {
    props: ["newsDetailData"],
    watch: {
        "newsDetailData": {
            handler: function (newVal, oldVal) {
                this.share = {
                    url: window.location.href,
                    text: newVal.title
                }
            },
            immediate: true,
        },
    },
    components: {

    },
    data() {
        return {
            share: {
                //分享参数
                url: window.location.href,
                text: ''
            },
        }
    },
    filters: {
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD");
        }
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        faceBookClick() {
            this.end_Share();
            // 携带参数
            function popupwindow(url, title) {
                return window.open(
                    "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
                    +"&t=" + encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);
        },
        // 推特分享
        twitterClick() {
            this.end_Share();
            //携带参数
            function popupwindow(url, title) {
                return window.open(
                    "https://twitter.com/intent/tweet?url=" +
                    encodeURIComponent(url) +
                    "&text=" +
                    encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);

        },
        linkedinClick() {
            this.end_Share();
            //携带参数
            function popupwindow(url, title) {
                return window.open(
                    "https://www.instagram.com/share?text=" +
                    encodeURIComponent(url) +
                    "&hashtags=" +
                    encodeURIComponent(title),
                    "_blank",
                    "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
                );
            }
            popupwindow(this.share.url, this.share.text);
        },
        // 点击分享调用接口
        end_Share() {
            // sendShare({
            //     id: this.$route.query.id,
            // }).then((res) => { });
        },
    }
}

</script>

<style lang="scss" scoped>
.detailContent {
    width: 925px;
    margin-left: 0px;
    background: #fff;
    padding: 32px;
    border-radius: 10px;

    .type {
        height: 27px;
        border-radius: 19px;
        line-height: 27px;
        color: #fff;
        background: #00B968;
        display: inline-block;
        padding: 0px 12px;
        margin-top: 8px;
    }

    .title {
        margin-top: 20px;
        font-size: 20px;
        font-family: Arial Black;
        font-weight: 600;
        color: #000000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .share {
        height: 27px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        position: relative;

        .dateImg {
            width: 18px;
            height: 18px;
        }

        .dateTitle {
            font-size: 14px;
            color: #666666;
            margin-left: 2px;
            height: 18px;
            line-height: 18px;
            font-weight: 600;
        }

        .lookImg {
            width: 18px;
            height: 18px;
            margin-left: 40px;
            cursor: pointer;
        }

        .linkedinImg {
            position: absolute;
            right: 0px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }

        .twitterImg {
            position: absolute;
            right: 39px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }

        .facebookImg {
            position: absolute;
            right: 78px;
            width: 28px;
            height: 27px;
            cursor: pointer;
        }


    }

    .contentImg {
        max-width: 100%;
        max-height: 100%;
        margin-top: 30px;

    }

    .detail-Title {
        display: inline-block;
        width: 100%;
        margin-top: 21px;
        // font-size: 16px;
        color: #333333;
        word-wrap: break-word;

        ::v-deep img {
            max-width: 100%;
        }

    }
    .ql-container {
        margin-top: 20px;
        // font-size: 16px;
        // color: #333333;
        // font-weight: 450;

        ::v-deep img {
            max-width: 100%;
        }

    }

    .bottomShare {
        position: relative;
        height: 76px;
        width: 100%;
        margin-top: 18px;
        background: #F6F6F6;
        display: flex;
        align-items: center;

        .shareTitle {
            position: absolute;
            right: 140px;
            height: 20px;

        }

        .linkedinImg {
            position: absolute;
            right: 20px;
            width: 28px;
            height: 27px;
        }

        .twitterImg {
            position: absolute;
            right: 59px;
            width: 28px;
            height: 27px;
        }

        .facebookImg {
            position: absolute;
            right: 98px;
            width: 28px;
            height: 27px;
        }
    }
    .source{
        color: #666666;
        margin-top: 20px;
    }
    ::v-deep .ql-container.ql-snow {
        border: none;
        .ql-editor{
            span {
                background: none !important;
            }
        }
    }
}
</style>
