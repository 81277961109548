<template>
  <!-- 留言 -->
  <div class="message">
    <div class="leave">
      <div class="leave_content">
        <b>{{ $t("home.For.title") }}</b>
        <el-input
          v-model="message.keyword"
          :placeholder="$t('home.For.Name')"
          maxlength="30"
        ></el-input>
        <el-input
          type="textarea"
          :rows="2"
          maxlength="500"
          :placeholder="$t('home.For.Descripition')"
          v-model="message.descripition"
        >
        </el-input>
        <el-input
          v-model="message.email"
          maxlength="64"
          :placeholder="$t('home.For.Email')"
        ></el-input>
        <el-button 
        :loading="message.Forbutton"
        type="primary"
         @click="form_btn">{{
          $t("home.For.button")
        }}</el-button>
      </div>
    </div>
    <div class="Subscribe" >
      <div>
        <h6>{{ $t("home.notify") }}</h6>
        <el-input
          :placeholder="$t('home.For.Email')"
          v-model="email"
            maxlength="64"
          class="input-with-select"
        >
          <el-button 
           :loading="Forbutton"
          slot="append" 
          @click="Subscribe">{{
            $t("home.Subscribe")
          }}</el-button>
        </el-input>
      </div>
    </div>
    <div class="Publish">
      <router-link to="/publish/publishSummary">{{
        $t("release.requirement.publish").toUpperCase()
      }}</router-link>
    </div>

    <el-dialog
      title="Thanks for subscribing Product Alerts"
      :visible.sync="dialogVisible"
      custom-class="mseeageTips"
      width="569px"
    >
      <div>
        <span
          >We've sent a confirmation e-mail to {{dialogVisibleText}}. Click on the
          link in the email to verify your account.
          <br />
          <br />
          Note: If you can't find our e-mail in your inbox, please check your
          spam/junk folder.</span
        >
        <div style="display:none">
          <dl>
            <dt>
              Upgrade your account now. Get more FREE services of
              GlobalSources.com:
            </dt>
            <dd>- Send inquiries and chat with suppliers directly.</dd>
            <dd>
              - Get Buyer Center to manage all your inquiries, chat messages,
              orders,
            </dd>
            <dd>favorites, subscriptions and more.</dd>
          </dl>
         <button @click="$router.push('/supply/supply')">Upgrade Your Account</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { subscribeLatest, sendFeedback } from "@/api/home";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleText:'', //记录一下邮箱
      
      message: {
        keyword: "",
        descripition: "",
        email: "",
        Forbutton:false
      },
      email: "",
      Forbutton:false
    };
  },
  mounted() {},
  methods: {
    form_btn() {
         if (!this.message.keyword.length) {
           return this.$message({
          message: "Please enter the product name or keyword",
          type: "warning",
        });
      }
       if (this.message.descripition.length<5) {
           return this.$message({
          message: "Please enter characters more than 5 and less than 500.",
          type: "warning",
        });
      }
      if (!this.validateEmail(this.message.email)) {
        return this.$message({
          message: "Please enter the correct email address",
          type: "warning",
        });
      }
  
      this.message.Forbutton=true
      sendFeedback(this.message).then((res) => {
        if (res.code == 200) {
          this.message = {
            keyword: "",
            descripition: "",
            email: "",
           
          };
            this.$message({
              message: 'Send successfully!',
              type: 'success'
        });
        }else{
              this.$message({
              message: res.message,
              type: 'warning'
        });
        }
        this.message.Forbutton=false
      });
    },
    Subscribe() {
         if (!this.validateEmail(this.email)) {
        return this.$message({
          message: "Please enter the correct email address",
          type: "warning",
        });
      }
      this.Forbutton=true
      subscribeLatest({
        email: this.email,
      }).then((res) => {
        if (res.code == "200") {
           this.dialogVisibleText=this.email
           this.email=''
         this.dialogVisible=true
        
        }
        this.Forbutton=false
      });
    },
    validateEmail(email) {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return emailRegex.test(email);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.message {
  width: $contentWidth;
  margin: 0 auto;
  .leave {
    width: 100%;
    height: 450px;
    background: url("@/assets/images/home/messge1.jpg") no-repeat center;
    position: relative;
    .leave_content {
      width: 544px;
      position: absolute;
      right: 80px;
      top: 47px;
      background: #fff;
      padding: 24px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      font-weight: 600;
      b {
        color: #333333;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 15px;
        display: inline-block;
      }
      ::v-deep input {
        height: 44px;
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: bold;
        color: #000;
      }
      ::v-deep textarea {
        height: 96px;
        font-size: 14px;
        margin-bottom: 12px;
        resize: none;
        font-weight: bold;
        color: #000;
      }
      button {
        float: right;
        height: 37px;
      }
    }
  }
  .Subscribe {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 10px;
    height: 218px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    h6 {
      font-size: 18px;
      color: #333333;
      margin-bottom: 16px;
      text-align: center;
    }
    .input-with-select {
      border-radius: 100px;
      border: 2px solid #00b968;
      overflow: hidden;
      width: 500px;
      background: #00b968;
      ::v-deep input {
        border: none;
      }
      ::v-deep .el-input-group__append {
        border: none;
        button {
          color: #ffffff;
          font-size: 16px;
          background: #00b968;
          border: none;
          border-radius: 0;
          display: flex;
        }
      }
    }
  }
  .Publish {
    width: 100%;
    height: 120px;
    background: url("@/assets/images/home/messge2.jpg") no-repeat center;
    background-size: 100% 100%;
    a {
      float: right;
      width: 137px;
      height: 39px;
      line-height: 39px;
      background: #ffffff;
      border-radius: 19px;
      text-align: center;
      color: #1e9654;
      font-size: 15px;
      font-weight: normal;
      margin-top: 38px;
      margin-right: 90px;
      font-weight: bold;
    }
  }
  ::v-deep .mseeageTips {
    border-radius: 10px;
    .el-dialog__body {
      border-top: 1px solid #e8e8e8;
      padding: 19px 24px;
      div {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        div {
          background: #f6f6f6;
          padding: 20px;
          border-radius: 10px;
          margin-top: 20px;
          dl {
            dt {
              color: #666666;
              margin-bottom: 12px;
              font-weight: bold;
            }
            dd {
              color: #666666;
            }
          }
          button {
            width: 221px;
            height: 36px;
            background: #00b968;
            border-radius: 24px;
            outline: none;
            border: none;
            cursor: pointer;
            margin: 0 auto;
            display: block;
            color: #FFFFFF;
            margin-top: 20px;
          }
        }
      }
    }
  }
  
::v-deep .el-textarea__inner {
  font-family: Arial, Helvetica, sans-serif !important;
}
}
</style>
