<template>
  <div class="pulish">
    <supplies-head></supplies-head>
    <div class="head">
      <div style="width: 827px">
        <div style="font-size: 46px;font-weight: normal;color: #FFFFFF;line-height: 54px;text-align: center">
          {{$t('release.summary.FeijiuTradingPlatform')}}</div>
        <div style="display: flex;margin-top: 40px;justify-content: space-around;font-size: 25px;">
          <div style="display: flex;align-items: center">
            <img src="@/assets/images/publish/fast.png" width="24px" height="24px">
            <div style="margin-left: 10px">{{$t('release.summary.Fast')}}</div>
          </div>
          <div style="display: flex;align-items: center">
            <img src="@/assets/images/publish/efficient.png" width="24px" height="24px">
            <div style="margin-left: 10px">{{$t('release.summary.Efficient')}}</div>
          </div>
          <div style="display: flex;align-items: center">
            <img src="@/assets/images/publish/simple.png" width="24px" height="24px">
            <div style="margin-left: 10px">{{$t('release.summary.Simple')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="font-size: 24px;font-weight: normal;color: #333333;line-height: 28px;text-align: center;margin-top: 40px">
      {{$t('release.summary.SelectPublish')}}
    </div>
    <div style="margin-top: 44px;display: flex;justify-content: center">
      <div style="cursor: pointer;width: 570px;background-color: #FFFFFF;height: 195px;align-items: center;display: flex;margin-right: 10px" @click="goNewTab('supply')">
        <img src="@/assets/images/publish/supplies.png" width="80px" height="80px" style="margin-left: 30px">
        <div style="display: block;align-items: center;margin-left: 20px">
          <div style="font-size: 16px;font-weight: normal;color: #333333;line-height: 20px;">{{$t('release.summary.Supplies')}}</div>
          <div style="font-size: 14px;font-weight: normal;color: #666666;line-height: 20px;margin-top: 10px">{{$t('release.summary.ScrapSell')}}</div>
          <el-button type="primary" style="margin-top: 20px;width: 172px;height: 37px" @click.stop="goNewTab('supply')">{{$t('release.summary.PublishNow')}}</el-button>
        </div>
      </div>
      <div style="cursor: pointer;width: 570px;background-color: #FFFFFF;height: 195px;align-items: center;display: flex;margin-left: 10px" @click="goNewTab('demand')">
        <img src="@/assets/images/publish/procurementRequirement.png" width="80px" height="80px" style="margin-left: 30px">
        <div style="display: block;align-items: center;margin-left: 20px">
          <div style="font-size: 16px;font-weight: normal;color: #333333;line-height: 20px;">{{$t('release.summary.procurementRequirement')}}</div>
          <div style="font-size: 14px;font-weight: normal;color: #666666;line-height: 20px;margin-top: 10px">{{$t('release.summary.QuicklyFaster')}}</div>
          <el-button type="primary" style="margin-top: 20px;width: 172px;height: 37px" @click.stop="goNewTab('demand')">{{$t('release.summary.PublishNow')}}</el-button>
        </div>
      </div>
    </div>
    <div style="height: 60px"></div>
  </div>
</template>

<script>
import SuppliesHead from "@/page/publish/components/heads";

export default {
  name: "publishSummary",
  components: {SuppliesHead},
  methods:{
    goNewTab(type){
      window.open(window.location.origin + "/publish?type=" + type, "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
.pulish{
  .head{
    background-image: url('@/assets/images/publish/head.jpg'); /* 这里将'路径/到/你的/图片.jpg'替换为你自己的图片路径 */
    width: 100%; /* 设置宽度 */
    height: 540px; /* 设置高度 */
    background-position: center; /* 居中对齐背景图片 */
    background-repeat: no-repeat; /* 不重复平铺背景图片 */
    background-size: cover; /* 根据容器大小等比例缩放背景图片，直到完全覆盖容器 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }
}
</style>
