<template>
    <div class="login">
        <!-- Logo -->
        <img class="logo" src="@/assets/images/login/logo.png" alt="">

        <!-- 输入框内容 -->
        <div class="content">
            <div class="bgcolor"></div>
            <div class="content-box">
                <div class="title">{{ $t('Login.sign') }}</div>
                <div class="account">{{ $t('Login.account') }}</div>
                <el-input v-model="loginData.account" :placeholder="$t('Login.email')"></el-input>
                <div class="password-box">
                    <div class="password-title">{{ $t('Login.password') }}</div>
                    <div class="password-title" style="color: #005CFF;  cursor: pointer;" @click="forgetPasswordClick">
                        {{ $t('Login.forgetPassword') }}</div>
                </div>
                <!-- show-password -->
                <el-input v-model="loginData.pwd" :type="showPassword ? 'password' : 'text'" @keyup.enter.native="loginBtn"
                    :placeholder="$t('Login.password')">
                    <img slot="suffix" class="el-input__icon" :src="showPassword ? require('@/assets/images/login/eye.png')
                        : require('@/assets/images/login/eye1.png')"
                        style="width: 24px;height: 24px; margin-right: 10px;margin-top: 15px;cursor: pointer;"
                        @click="handleToggleShowPwd"></img>
                </el-input>

                <el-button type="primary" class="loginBtn" round @click="loginBtn">{{ $t('Login.sign') }}</el-button>
                <div class="notice">
                    <span>{{ $t('Login.cue1') }}</span>
                    <span style="color: #005CFF; cursor: pointer;" @click="tiaojianClick"> {{ $t('Login.cue2') }} </span>
                    <span>{{ $t('Login.cue3') }}</span>
                    <span style="color: #005CFF; cursor: pointer;" @click="xieyiClick"> {{ $t('Login.cue4') }}</span>
                </div>

            </div>

        </div>

        <!-- 创建账号 -->
        <div class="creat-box">
            <div>
                <span
                    style="width:147px;height:1px;background:#E8E8E8; display: inline-block;vertical-align:middle;margin-left:40px"></span>
                {{ $t('Login.newtofiejiu') }}
                <span
                    style="width:147px;height:1px;background:#E8E8E8; display: inline-block;vertical-align:middle "></span>
            </div>
            <el-button type="primary" class="creatBtn" round @click="creatClick">{{ $t('Login.creatAccount') }}</el-button>
            <div style="margin-left: 50px;margin-top: 10px;">
                <span>{{ $t('Login.need') }}</span>
                <span @click="contactUsClick" style="color: #005CFF;cursor: pointer; "> {{ $t('Login.contact') }} </span>
            </div>
        </div>

        <!-- 底部文案 -->
        <div class="bottom-box">@2023, Feijiu.com. All
            rights reservea.</div>


    </div>
</template>
<script>
import { loginByCode } from '@/api/login'
import { setToken, addLocalMessage, getMessage ,setNickname} from '@/utils/cookie'
import { Loading } from 'element-ui';

export default {

    metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: this.$t("tdk.login.title"),
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.login.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.login.description"),
                    },
                ],
            };
        },
    },


    components: {

    },


    data() {
        return {
            fromPath:'',
            loginData: {
                account: '',
                pwd: '',
            },
            options: {
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            showPassword: true

        }
    },
    // 记录上个页面的路由
    beforeRouteEnter(to,from,next) {
        next(vm =>{
            vm.fromPath = from.fullPath
        })
    },
    mounted() {


    },
    destroyed() {
    },
    methods: {
        test() {
            // console.log('点击了回车键')
        },
        // 登录点击
        loginBtn() {
    
         if (this.loginData.account.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.emailTips'),
                    type: "warning",
                });
                return
            } else if (this.loginData.pwd.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.passwordTips'),
                    type: "warning",
                });
                return
            }
            var that = this
            // 增加loading
            Loading.service(
                that.options
            );
            let loadingInstance = Loading.service(that.options);

            loginByCode({
                account: this.loginData.account,
                pwd: this.loginData.pwd,
            }).then((res) => {
                loadingInstance.close();

                var data = res.data
                if (res.code == '200') {
                    let token = data.token
                    var nickName =  data.nickName
                    // 缓存token 和nickname
                    setNickname(nickName)
                    setToken(token)
                    // 如果有上级路由跳转过去，如果没有直接到首页
                    if (this.fromPath) {
                        if(this.fromPath.includes('privacypolicy') || this.fromPath.includes('termsofUse')) {
                            this.$router.replace('/')
                        } else {
                            this.$router.push(this.fromPath)
                        }
                    } else {
                        that.$router.replace('/')
                    }

                }

            })
        },
        // 创建账号点击
        creatClick() {
            this.$router.push('/login/creatAccount/creat')

        },
        // 忘记密码点击
        forgetPasswordClick() {
            this.$router.push('/login/forgetPassword/email')

        },
        // 联系我们
        contactUsClick() {
            let page = this.$router.resolve({
                path: '/contactUs',
            })
            window.open(page.href, "_blank");
        },
        // 使用条件
        tiaojianClick() {
            let page = this.$router.resolve({
                path: '/terms/termsofUse',
            })
            window.open(page.href, "_blank");
        },
        // 隐私协议
        xieyiClick() {
            let page = this.$router.resolve({
                path: '/terms/privacypolicy',
            })
            window.open(page.href, "_blank");
        },
        handleToggleShowPwd() {
            this.showPassword = !this.showPassword;
        },

    },

}

</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    //纵向排列
    flex-direction: column;
    //居中
    align-items: center;

    //logo
    .logo {
        margin-top: 160px;
        width: 152px;
        height: 44px;
    }



    //输入框box
    .content {
        z-index: 1;
        width: 470px;
        height: 419px;
        margin-top: 31px;
        margin-bottom: 40px;
        border-radius: 8px;
        border: 1px solid #DDDDDD;
        box-sizing: content-box;
        position: relative;

        .bgcolor {
            // background: linear-gradient(192deg, #E7FFF5 0%, #FFFFFF 100%);
            background: linear-gradient(to bottom left, #E7FFF5 0%, white 100%);
            top: 0px;
            left: 0px;
            width: 470px;
            height: 150px;
            border-radius: 8px;

        }

        .content-box {
            width: 470px;
            height: 100%;
            position: absolute;
            top: 0px;
            z-index: 22;

            .title {
                font-size: 28px;
                font-weight: 700;
                color: #000000;
                line-height: 32px;
                margin-top: 30px;
                margin-left: 40px;
            }

            .account {
                font-size: 14px;
                font-weight: 600;
                color: #333333;
                line-height: 16px;
                margin-top: 30px;
                margin-left: 40px;
            }

            .password-box {
                margin-left: 40px;
                margin-right: 40px;
                margin-top: 20px;
                height: 16px;
                // background: orange;
                display: flex;
                justify-content: space-between;

                .password-title {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                }
            }

            .loginBtn {
                width: 390px;
                height: 48px;
                margin-left: 40px;
                margin-top: 30px;
                background: #00B968;
                font-weight: 600;
            }

            .notice {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                width: 390px;
                margin-left: 40px;
                margin-top: 10px;
            }
        }


    }

    //创建账号box
    .creat-box {

        width: 490px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        margin-bottom: 116px;

        .creatBtn {
            border-radius: 24px;
            border: 1px solid #00B968;
            width: 390px;
            height: 48px;
            background: #ffffff;
            color: #00B968;
            margin-top: 20px;
            margin-left: 40px;
            // margin: 0 auto;
        }
    }

    //底部文案
    .bottom-box {
        width: 100%;
        height: 75px;
        line-height: 75px;
        text-align: center;
        background: #F4F4F4;
        color: #666666;
    }

    // 做输入框穿透效果 设置输入框frame
    ::v-deep .el-input__inner {
        width: 390px;
        height: 48px;
        margin-left: 40px;
        margin-top: 10px;

    }

    ::v-deep .el-input__suffix {
        // background-color: red;
        margin-right: 35px;
        margin-top: 5px;
    }

}
</style>
