export default {
  //中文
  Login: {//登录
    sign: '登录',
    account: '账号',
    email: '邮箱地址',
    password: '密码',
    forgetPassword: '忘记密码？',
    newtofiejiu: '新用户',
    //隐私协议声明
    cue1: '继续操作即表示您同意Feijiu的',
    cue2: '使用条件',
    cue3: '和',
    cue4: '隐私声明',
    creatAccount: '创建账号',
    need: '需要帮助？',
    contact: '联系我们.',
  },
  // 注册
  Register: {
    creatAccount: '创建账号',
    yourName: '你的姓名',
    nameTips: '名字和姓氏',
    passwordTips: '至少6个字符',
    passwordTry: '再次输入密码',
    continue: '继续',
    verifyEmail: '验证邮箱',

    verifyEmailTitle: '验证邮箱地址',
    sendEmailTips: '带有一次性密码（OTP）的文本已发送到您的邮箱：',
    enterOTP: '输入验证码',
    resendOTP: '重新发送',
    creatFJAccount: '创建你的Feijiu账户'
  },
  // 忘记密码
  Password: {
    passwordAssistance: '密码帮助',
    passwodrTips: '输入与您的Feijiu帐户关联的电子邮件地址。',
    newPassword: '新密码',
    creatNewPassword: '创建一个新密码',
    saveAndLogin: '保存并登录'
  },
  LoginTips: {
    emailTips: '请输入邮箱',
    passwordTips: '请输入密码',
    passwordWrong: '两次密码不一致，请重新填写',
    passwordLenght: '密码不正确，请重新输入',
    loginSuccess: '登录成功',
    registerSuccess: '注册成功',
    passwordSuccess: '密码设置成功',
    passwordAgain: '请再次输入密码',
    nameTips: '请输入名字和姓氏',
    msmCodeSuccess: '发送验证码成功，请注意查收'

  },
  // 帮助中心
  Help: {
    home: {
      input: '请输入问题/关键词。如：支付',
      learning: '学习中心',
      hotTipcs: '热门话题',
      buy: '回收/处置方',
      vip: '付费用户',
      service: '在线服务',
      survey: '调查',
      feedBack: '留下反馈',
      submitted: '已提交',
      commingSoon: '即将上线，敬请期待',
      category: '分类'
    },
    submit: {
      title: '对你有帮助吗？',
      yes: '是的',
      no: '不是',
      thanks: '感谢反馈',
      reasonTitle: '请选择以下原因：',
      reason1: '无关的答案',
      reason2: '描述不清楚',
      reason3: '内容不正确/过期',
      reason4: '不友好的规则/策略/流程',
      submit: '提交',
    },
    search: {
      title: '搜索结果',
      all: '全部',
      sorry: '很抱歉，没有结果，请尝试其他关键字。'

    },
    feedBack: {
      feedBack: '意见反馈',
      title: '您如何看待 Feijiu.com？（只选择一个答案）',
      satisfied: '满意',
      unsatisfied: '不满意',
      satisTitle: '您之所以满意，是因为：（只选择一个 答案）',
      satis1: '商机信息很多',
      satis2: '使用方便顺畅',
      satis3: '为我带来了收益',
      satis4: '其他',
      unsatisfiedTitle: '您之所以不满意，是因为：（只选择一个答案）',//不满意的标题 待补充
      unSatis1: '使用不方便',
      unSatis2: '无关的答案',
      unSatis3: '无益的解决方案',
      unSatis4: '服务不满意',
      cue: '如果您对我们Feijiu.com有任何其他建议/投诉，请随时通知我们。非常感谢您的宝贵信息。',
      feedSuccess: '感谢您的反馈，您的宝贵意见将帮助我们变得更好。'
    },
    AboutUs: {
      title: "Feijiu 网（国际站：www.feijiu.com）致力于让废旧生意更轻松，帮助全球再生资源顺利循环流通，助力循环经济快速发展。我们为物资处理企业和回收客户提供免费信息发布和快速变现废旧物资的服务，同时让回收客户可以快速找到货源，实现双赢局面。Feijiu网通过助力再生资源行业数字化转型，实现上下游生态共赢，为可持续发展贡献力量。\n\nFeijiu网是中国废旧闲置物资在线交易B2B门户网站，秉承让废旧生意更轻松为使命，为交易双方提供废旧物资交易信息的合作平台，促进废旧物资循环效率。平台提供废金属的供应、求购信息发布、展示、交易服务。\n在中国，Feijiu网的便捷发布体验，匹配效率和优质的服务备受用户赞誉。怀着成为全球废旧产业第一服务商的企业愿景，Feijiu网国际站2024年全面上线，为全球废旧物资处置的委托方与回收方提供服务。\n\nFeijiu 网（中文网：www.feijiu.net）成立于2004年，作为中国废旧物资行业B2B信息门户平台，专注为中小微企业提供资产处置、招标拍卖、行情报价、供求信息、行业资讯等内容，平台通过数字化打通再生资源产业链多个环节，将传统发展模式的废旧行业与互联网深度融合，依托自建的废旧行业数据中台，用数据精准连接上下游客户，成为“互联网+工业”的典型案例。"
    },
    contactUs: {
      address: "总部：中国河北省石家庄市长安区乐汇城8楼",
      email: "邮箱：service@feijiu.com"
    }

  },
  News: {
    hotNews: '热门信息',
    share: '分享'
  },
  Exhibition: {
    category: '类别',
    organizer: '组织者',
    exhibitionCenter: '展览中心',
    date: '日期',
    recommendedEvents: '推荐活动',

  },

  home: { //首页
    top: { //顶部
      Enterprise: '企业处置废旧物资',
      Sign: '登录',
      Join: '注册',
      or: '/',
      aera: '处置或回收国家',
      // 登录成功
      MyFeijiu: '我的Feijiu（工作台）',
      Disposal: '处置',
      Recycle: '回收',
      Messages: '消息',
      Favorites: '收藏',
      Information: '个人信息',
      Signout: '退出登录'

    },
    nav: { //nav
      0: '首页',
      1: '供应',
      2: '回收 ',
      3: '帮助中心',
      4: '新闻',
      5: '展览',
      6: '关于我们',
      7: '联系我们'
    },
    labelIcon: {//分类
      Millions: '数以百万计的废料可供选择'
    },
    list: { //首页列表
      Products: '最新供应信息',
      Recycling: '最新回收信息',
      More: '更多'
    },
    For: {//咨询报价
      title: '申请报价',
      Name: '产品名称或关键词',
      Descripition: '产品描述',
      Email: '您的邮箱',
      button: '立即发出',
    },
    Publish: '免费发布信息',
    slogan: '企业处置废旧物资就来Feijiu.com',
    SearchVal: '搜索金属名称或关键字',
    Search: '搜索',
    Subscribe: '订阅',
    notify: '把Feijiu.com的最新信息发给我。'

  },
  supply: {//供应,求购
    Filters: '筛选',
    Categories: '分类',
    Department: '所有分类',
    Totalvolume: '总交易量',
    Min: '最小值',
    Max: '最大值',
    Totalprice: '交易总价',
    Method: '配送方式',
    Method1: '配送方式',
    pickup: '自提',
    Delivery: '配送',
    Location: '所在地',
    supplyLocation: '所在地',
    Regions: '可供应地',
    result: '没有结果',

    results: '',
    // 详情
    Favorites: '收藏夹',
    Share: '分享',
    price: '价格',
    Supplier: '处置商',
    Recycler: '回收商',
    ContactSupplier: '联系处置商',
    ChatNow: '立即聊天',
    Description: '产品描述',
    popularproducts: '处置商的热门产品'
  },
  footer: {//底部
    // 一级
    Supplies: '供应商',
    Recycle: '回收者',
    KnowUs: '了解我们',
    Support: '支持',
    Follow: '关注我们',
    Feedback: '反馈',
    Provider: '©2023, Feijiu.com . All rights reserved.',
    // 二级

    Information: '浏览供应列表',
    Supply: '免费发布供应信息',
    VRecycle: '浏览回收列表',
    recycle: '免费发布回收信息',
    About: "关于Feijiu.com",
    Contact: '联系我们',
    Help: '帮助中心',
    Subscribe: '订阅',
    Privacy: '隐私政策'


  },
  release: {//发布
    Add: "添加",
    PleaseReadAgreeRules: "请阅读并同意《供求信息发布规则》",
    OperationSuccessful: "操作成功",
    OperationFailed: "操作失败",
    requirement: {
      PostRequirement: "发布你的求购信息",
      ProductName: "产品名称",
      EnterName: "输入产品名称",
      ForSteel: "例如：【纽约】求购20吨废钢",
      Categories: "分类",
      SourcingQuantity: "求购数量",
      PleaseEnter: "请输入",
      EexpectedPrice: "期望价格",
      EnterPrice: "输入价格",
      EnterLowPrice: "输入低价",
      EnterHighPrice: "输入高价",
      negotiateDirectly: "面议",
      DetailedRequirements: "详细说明",
      LookingFor: "我正在找...",
      ProductImages: "产品图片",
      PhotosFrom: "不同角度的照片让产品更清晰",
      Whole: "整体",
      Part: "局部",
      particulars: "细节",
      Others: "其他",
      PurchaseLocation: "求购地",
      DeliveryMethod: "配送方式",
      SelfPickup: "自提",
      Delivery: "物流",
      ReceiveReplyEmail: "接受回信邮箱",
      WantDisturbed: "不想被打扰？",
      ReplaceAddress: "如您需更换邮箱，请邮件联系service@feijiu.com",
      Understood: "我已阅读、理解并同意遵守",
      Rules: "《供求信息发布规则》",
      publish: "发布",
      PleaseCharacters10: "请填写产品标题，不少于10个字符。",
      PleaseCharacters500: "请输入最多500个字符的产品标题。",
      PleaseSelectCategories: "请选择类别。",
      PurchaseQuantity: "购买数量应为整数且不大于10万。",
      PriceInputRange: "价格必须为数字,最多允许两位小数。输入范围0.01-999999",
      EnteredPriceAgain: "输入的高价应大于低价，请重新填写",
      PleaseCharacters: "请填写详细需求，不少于50个字符。",
      PleaseCharacters1000: "请输入最多1000个字符的详细需求。",
      PleaseLocation: "请最少选择一个求购地",
      PleaseMethod: "请最少选择一种配送方式",

    },
    information: {
      PublishingInformation: "发布您的供应信息",
      ForSteel: "例如：【纽约】供应20吨废钢",
      DisposalQuantity: "处理数量",
      SellingPrice: "出售价格",
      DetailedIntroduction: "详细说明",
      Location: "所在地",
      AvailableRegions: "可供应地区",
      TheDisposalGreater: "供应数量应为整数且不大于10万。",
      PleaseCharacters50: "请填写详细介绍，不少于50个字符。",
      PleaseCharacters1000: "请输入最多1000个字符的详细介绍。",
      PleaseLocation: "请选择所在地",
      PleaseSelectRegions: "请最少选择一个可供应地区",
    },
    summary: {
      FeijiuTradingPlatform: "Feijiu.com -全球废旧物资交易平台",
      Fast: "快速",
      Efficient: "高效",
      Simple: "简单",
      SelectPublish: "选择您要发布的信息类型",
      Supplies: "供应",
      ScrapSell: "我有废金属物资要卖",
      PublishNow: "立即发布",
      procurementRequirement: "采购需求",
      ScrapMetalSell: "我有废金属物资要卖",
      QuicklyFaster: "30s快速发布需求，精准推荐意向客户，响应更快！",
    },
    unit: ["打",
      "码",
      "个",
      "套",
      "公克",
      "英寸",
      "公斤",
      "升",
      "公吨",
      "米",
      "盎司",
      "磅",
      "平方米",
      "立方米"]
  },

  staging: {//工作台
    //竖向导航
    Home: "首页",
    SaleProducts: "销售产品",
    Recycle: "回收",
    Inquiry: "询盘",
    Business: "商机",
    Messages: "消息",
    EmailServices: "电子邮件服务",
    Settings: "设置",
    Help: "帮助",
    ChangePassword: "修改密码",

    //横向导航
    IM: "即时通信",
    Feedback: "反馈",

    TipsTitle: "小贴士",
    Fold: "收起",
    Unfold: "展开",
    keyWord: "关键词",
    Search: "搜索",
    Reset: "重置",
    Operation: "操作",
    Edit: "编辑",
    Delete: "删除",
    Previous: "上一个",
    Next: "下一个",
    InputKeyWords: "请输入产品名称或关键词",
    PleaseCategory: "请选择一个分类",
    All: "全部",
    AreDelete: "确认删除吗？",
    Cancel: "取消",
    Confirm: "确认",
    PleaseSelectInformation: "请选择删除信息的原因",
    CompletedTransaction: "已成交",
    OfflineCompleted: "已线下处理",
    TooInformation: "骚扰信息太多",
    DissatisfiedService: "不满意服务",


    //首页
    homePage: {
      NewInquiry: "新询盘",
      Audit: "待审核",
      Fall: "审核失败",
      AddSaleProducts: "添加出售信息",
      AddRecycleProducts: "添加回收信息",
      IndustryRecommendations: "行业推荐",
      SendInquiry: "发送询盘",
      More: "更多",
    },


    //工作台-处置
    saleProducts: {
      ManageSaleProducts: "管理出售信息",
      Tips1: "1.您添加的产品在上架之前需要获得批准。产品审核时间范围更新：24-72 小时。如遇特殊情况，会略有延迟。感谢您的支持。",
      Tips: ["2. 如果您的信息被投诉，投诉经核实确认后您的信息将被下架，可联系网站客服申诉。", "3. 请检查您添加的信息是否符合Feijiu发布规则。不符合规定的信息将无法通过审核。您可以查看信息未通过审核的原因。"],
      Approved: "审核通过",
      TobeApproved: "审核中",
      ApprovalFailure: "审核失败",
      ProductGroup: "信息分类",
      ProductInformation: "产品信息",
      InformationViews: "信息浏览量",
      NumberOfInquirys: "询盘数量",
      LastUpdateTime: "最后更新时间",
      reason: "原因",
    },

    recovery: {
      ManageRecycleProducts: "管理回收信息",
    },

    inquiry: {
      Flag: "旗",
      Type: "类型",
      InquiriesReceived: "收到的询盘",
      InquiryIssued: "发送的询盘",
      GarbageInquiry: "垃圾询盘",
      InquiringForProduct: "询盘信息",
      InquiryInformation: "询盘内容",
      InformationOfTheInquirer: "询盘者信息",
      country: "国家",
      OnlineReply: "在线回复",
      EmailReply: "邮件回复",
      SetGarbage: "设置为垃圾询盘",
      SendEmails: "发送邮件",
      CopyEmail: "复制邮箱",
      tips: '请点击“同意与供应商共享名片”，以便对方快速反馈您的询盘',
      inquiryTime: "询盘时间",
    },

    bussiness: {
      Bussiness: "商机",
      Favorite: "喜欢",
      Share: "分享",
      Browse: "浏览",
      InformationType: "信息类型",
      InfoCategory: "信息分类",
      ReleaseTime: "发布时间",
      CollectionTime: "收藏时间",
      ShareTime: "分享时间",
      BrowseTime: "浏览时间",
      CancelFavorite: "取消收藏",


    },

    changePassword: {
      ChangePassword: "修改密码",
      TheCurrentPassword: "现在密码",
      PasswordStrength: "密码强度",
      PasswordStrengthList: ["弱", "中等", "强"],
      IdentityVerification: "认证",
      tip: "为了你的账户安全，请验证身份。验证成功后进行下一步操作。",
      UseVerification: "用****验证",
      SendVerification: "发送验证码至邮箱",
      VerificationCode: "输入验证码",
    },

    createPassword: {
      CreatePassword: "创建新密码",
      PasswordTips: "请使用大小写字母、数字和符号组合的密码，密码长度至少为6个字符。",
      NewPassword: "新密码",
      ReenterPassword: "再次输入密码",
      LeastCharacters: "至少6个字符",
      Save: "保存",
    },

    settings: {//设置
      Tips: "1、请填写真实有效的个人信息，以便其他人与你取得联系;",
      Tips1: ["2、你的信息不会直接展示在前台，请放心填写;", "3、名称不得违反任何法律法规或平台规则。例如，不得包含任何侵犯第三方版权或禁止或限制销售的产品条款，不得包含任何引导线下交易（如第三方网站、PayPal等）的文字，也不得侵犯他人合法权益;"],
      PersonalInformation: "个人信息",
      HeadSculpture: "头像",
      PersonalName: "姓名",
      PleaseEnterName: "请输入姓名",
      CorporateName: "公司名称",
      PleaseEnterCorporateName: "请输入公司名称",
      DateBirth: "出生日期",
      YourCountry: "你的国家",
      PleaseCountry: "请选择你的国家",
      ResidentialAddress: "居住地址",
      PleaseAddress: "请输入居住地址",
      Telephone: "电话号码",
      PleaseTelephone: "请输入电话号码",
      Email: "电子邮件",
      SetForeground: "设置为前台可见",
    },

    emailServices: {//邮件服务
      EmailServices: "电子邮件服务",
      tips: "1、对您的电子邮件订阅设置的任何更改都是免费的",
      tips1: ["2、取消订阅后，请留出最多七天的时间让更改生效",
        "3、我们不建议您取消订阅通知电子邮件，因为您可能会错过某些重要信息（包括但不限于与您在Feijiu网上的交易相关的信息）。如果您决定取消订阅，您将全权负责任何相关索赔和后果。"
        , "4、 尽管您已取消订阅此电子邮件列表，但请注意，您仍可能从飞久网收到某些重要通知。"],
      EmailPreferences: "邮件首选项",
      SelectReceive: "选择您想要接收的电子邮件",
      Verify: "验证",
      CurrencyType: "货币类型",
      PleaseType: "请选择货币类型",
      Language: "语言",
      PleaseLanguage: "请选择语言",
      AllNotificationEmails: "所有通知电子邮件",
      EmailsActivities: "通知您重要帐户更新和 Feijiu.com 活动的电子邮件",
      GeneralNotification: "一般通知电子邮件",
      EmailsCommunications: "由您在平台上的活动触发的电子邮件，包括您的帐户、通信等的更新。",
      DisputeUpdates: "争议更新",
      InProgress: "如果发生任何争议，而您是其中的一方（无论是否由您发起），我们将通知您并向您更新其进展。",
      AllMarketingEmails: "所有营销电子邮件",
      PromotionalMessages: "包含有关 Feijiu.com 产品和服务的促销信息的电子邮件",
      GeneralMarketing: "一般营销电子邮件",
      EmailNotifying: "通知您活动和服务更新的电子邮件，包括可能使您的业务受益的产品折扣、特别优惠、活动和服务产品",
      Surveys: "调查",
      BetterServe: "邀请您提供反馈的电子邮件，以便我们改进我们的产品，以便更好地为您服务",
      Frequency: "频次",
      FrequencyEmails: "接收Feijiu.com 电子邮件的频次",
      Everyday: "每天",
      Weekly: "每周",
      Monthly: "每月",
      UnsubscribeFromAll: "全部退订",
      SavePreferences: "保存首选项",
      AreYouSure: "确认操作？",
      UpdatesUpcoming: "您可能会错过重要的业务更新和即将发布的消息。",
      Unsubscribe: "取消订阅",
      GoBack: "返回",
    },

    message: {
      Tips: "1、 查看有关产品更新、信息或付款的系统提醒以及通知或公告",
      Tips1: ["2、 您的信息将保存12个月"],
      SystemMessage: "系统消息",
      Account: "账户",
      Other: "其他",
      ReadStatus: "读取状态",
      Read: "已读",
      Unread: "未读",
      Status: "状态",
      Subject: "主题",
      Type: "类型",
      DateSending: "发送时间",
      Contacts: "联系人",
      DeleteMessage: "删除信息",
      Close: "关闭",
    },

  },
  send: { //询盘
    Inquiry: '发送询盘',
    To: '发给',
    Detailed: '详细需求',
    Enter: '输入产品详细信息，例如生产商、重量、运费等以及其他规格要求，以获得准确的报价。',
    please: '请输入信息',
    Agree: '同意与供应商共享名片。',
    Please: '请确保您的联系方式正确（{content}）。 您的消息将直接发送给收件人，并且不会公开显示。 请注意，如果收件人是金牌供应商，他们可以查看您的联系信息，包括您注册的电子邮件地址。 未经您的明确许可，飞酒绝不会向第三方分发或出售您的个人信息。',
    btn: '立即发送询价'
  },
  tdk: {  // tdk
    index: { // 首页
      title: 'Feijiu.com：来自世界废旧金属在线 B2B 市场的供应商、回收商、出口商和进口商',
      keywords: '废金属供应,废金属求购,国际废金属交易,废钢供应,废铁求购,废铜供应,废铝求购,废金属回收,废金属处理,金属废料供应,金属废料求购,废金属回收价格',
      description: '在我们的废金属贸易网站寻找全球优质供应商、出口商、进口商、买家- Feijiu网'
    },
    supply: { //供应
      title: 'Feijiu网最新供应信息      ',
      title1: 'Feijiu网最新回收信息      ',
      title2: '供应的【{content}】,【{content1}】信息 | Feijiu网',
      keywords: '废金属供应,国际废金属交易,废钢供应,废铁供应,废铜供应,废铝供应,废金属供应,废金属处理,金属废料供应,金属废料处理,废金属回收价格',
      keywords1: ' 废金属回收,国际废金属交易,废钢求购,废铁回收,废铜供求购废铝回收,废金属求购,废金属回收,金属废料求购,金属废料回收,废金属回收价格 ',
      keywords2: '[{content}]供应,[{content1}]供应,废金属供应,国际废金属交易,',
      description: '免费发布废金属供应/处理信息，快速变现废金属物资，免费对接全球回收客户。废金属信息尽在Feijiu.com',
      detail: {
        title: '【标题】,【一级分类】处理 | Feijiu网',
        title1: '【标题】,【二级分类】供应,【一级分类】处理 | Feijiu网',
        keywords: '废金属供应,国际废金属交易,废钢供应,废铁供应,废铜供应,废铝供应,废金属供应,废金属处理,金属废料供应,金属废料处理,废金属回收价格',
        description: '免费发布废金属供应/处理信息，快速变现废金属物资，免费对接全球回收客户。废金属信息尽在Feijiu.com',

      }
    }

  },
  // // 使用条款
  // agreement: {
  //   title: '使用条款',
  //   introduce1: '欢迎来到www.feijiu.com！使用条款描述了适用于您访问和使用本网站的条款和条件。本文件是您作为本网站用户（以下简称“您”、“您的”或“用户”）与www.feijiu.com之间具有法律约束力的协议（“协议”）。',
  //   introduce2: '请查看以下您使用和访问本网站的基本规则（“协议”）。另请注意，您对本网站的使用或访问即表示您无条件同意遵守本协议并受本协议的约束，尽管您可以“收藏”本网站的特定部分，从而绕过本协议。如果您不接受本协议的所有条款，请不要使用或访问本网站。',
  //   clause1: '1. 使用条款的适用',
  //   clause1_1: '1.1 您对本网站及其服务、软件和产品（统称为“服务”）的使用和访问受与本协议相关的所有条款和条件以及安全和隐私政策、知识产权政策以及Feijiu.com可能不时发布的任何其他网站规则和政策的约束。本协议以及本网站的其他规则和政策在此统称为“条款”。同时，对于您在本网站上的购买和交易，您作为买方或卖方与Feijiu.com之间的注册协议和相关条款和条件将对您具有约束力。因此，我们建议您仔细阅读本协议以及所有相关的附加条款、条件和信息，包括但不限于《注册协议》、《安全和隐私政策》以及《知识产权政策》。',
  //   clause1_2: '1.2 请注意，您对本网站的使用和/或访问还受适用于某些特定会员服务（定义见下文）的任何条款和条件的约束。如果上述条款、条件和信息与适用于该特定会员服务的条款和条件之间存在任何冲突或不一致，则以适用于该特定会员服务的条款和条件为准。',
  //   clause1_3: '1.3 会员服务是指Feijiu.com仅为经过特定注册或验证过程的特定付费用户保留的某种特性或功能。此类会员服务的内容和费用结构应由Feijiu.com独立确定，并不时通过网站上发布的通知、电子邮件或其他方式向相关用户报告。',
  //   clause1_4: '1.4 您承认并同意，Feijiu.com可以随时通过在网站上发布相关的修订和重述条款来修改任何条款。继续使用服务或网站，即表示您同意修订后的条款适用于您',
  //   clause1_5: '1.5 如果Feijiu.com已经发布或提供了条款英文版本的翻译，您同意提供翻译只是为了方便起见，并且英文版本将约束您对服务或网站的使用。在本条款存在中文与外文不同版本且内容发生冲突的情况下，以中文版本为准。',
  //   clause1_6: '1.6 除非Feijiu.com的授权人员以书面形式修改本条款，否则不得以其他方式修改。',

  //   clause2: '2. 网站的使用',
  //   clause2_1: '2.1 通过使用或访问本网站接受这些使用条款，您声明您 （a） 已达到法定年龄并能够在您居住的司法管辖区和您使用服务的司法管辖区签订具有法律约束力的合同，以及 （b）根据您居住的司法管辖区的法律允许接收任何服务，并且您从中使用服务业。如果您不同意（或不能遵守）本第 2.1 条，请勿使用本网站。',
  //   clause2_2: '2.2 本网站提供的所有账单和注册信息必须真实、准确、合法。每个用户应对网站上发布的信息和内容承担全部责任。提供不真实或不准确或不合法的信息将构成对本使用条款的违反。',
  //   clause2_3: '2.3 所有材料，包括图像、文本、插图、设计、图标、照片、结账过程，您同意接受并支付所购买的物品。您意识到交货付款过程中的任何延迟都可能构成违约，您应对此类违约行为承担全部责任。',

  //   clause3: '3.一般用户',
  //   clause3_1: '3.1 作为您访问和使用网站或服务的条件，您同意在访问或使用网站或服务时遵守所有适用的法律和法规。',
  //   clause3_2: '3.2 您同意（a） 您不会复制、拷贝、下载、重新发布、出售、分发或转售任何服务或网站上或通过网站提供的任何信息、文本、图像、图形、视频剪辑、声音、目录、文件、数据库或列表等（“网站内容”），并且 （b） 您不会复制、拷贝、下载、编译或以其他方式使用任何网站内容来经营与 Feijiu.com 竞争的业务， 或以其他方式对网站内容进行商业利用。未经 Feijiu.com 书面许可，禁止从网站系统地检索网站内容，以直接或间接创建或编译集合、汇编、数据库或目录（无论是通过机器人、蜘蛛、自动设备还是手动过程）。禁止将网站上的任何内容或材料用于条款中未明确允许的任何目的。',
  //   clause3_3: '3.3 您必须阅读以下文件，这些文件管理 Feijiu.com 拥有的用户个人信息的保护和使用：',
  //   clause3_3_1: '对于访问或使用与Feijiu电子商务平台相关的网站的用户，Feijiu.com 隐私政策（统称为“隐私政策”）。',
  //   clause3_4: '3.4 您同意不采取任何行动破坏 Feijiu.com 和/或任何其他用户的计算机系统或网络的完整性，也同意未经授权访问此类计算机系统或网络。',
  //   clause3_5: '3.5 您同意不采取任何可能破坏Feijiu.com反馈系统完整性的行为，例如使用会员ID或通过第三方为自己留下正面反馈，或为其他用户留下未经证实的负面反馈。',
  //   clause3_6: '在适用法律允许的范围内，通过发布或展示产品列表中的任何徽标、商标、服务标志、品牌、描述/信息，以及网站上的任何其他信息、内容或材料（任何此类信息、内容或材料，统称为“用户内容”），或向 Feijiu.com 及其代表提供任何用户内容， 您授予不可撤销的、永久的、全球性的、免版税的和可再许可的（通过多层）许可给 Feijiu.com 和/或其关联公司及其各自的代表，以显示、传输、分发、复制、发布、拷贝、改编、修改、编辑、翻译、使用用户内容创建衍生作品、删除其任何部分（包括但不限于用户内容所带有的水印或标记）， 以及以其他方式在网站、Feijiu渠道和/或第三方渠道上以任何形式、媒体或技术使用任何或所有用户内容，并用于任何可能直接或间接有益于 Feijiu.com、网站运营、提供任何服务和推广服务和/或用户业务的目的。您向 Feijiu.com 确认并保证，您拥有授予上述许可和用户内容所需的所有权利、权力和权限，并且 Feijiu.com 和/或其关联公司根据此类许可使用此类用户内容（包括衍生作品）不会侵犯或违反任何第三方权利（定义见条款第 5.4 条）。在法律允许的最大范围内，您放弃对 Feijiu.com 和/或其关联公司、受让人或分许可人行使您在用户内容中的知识产权的权利，这些权利与使用与服务相关的此类用户内容有关。受数据保护法保护的信息将仅在遵守这些法律的情况下使用和保存。',
  //   clause3_7: '3.7 每个用户理解并接受，网站上列出的产品价格和/或为产品支付的价格。',
  //   clause3_8: '3.8 您了解，通过使用和访问本网站或本网站提供的任何服务，您可能会遇到某些人可能认为具有攻击性、不雅或令人反感的内容，这些内容可能会被识别为此类内容，也可能不被识别为此类内容。您同意自行承担使用本网站和任何服务的风险，Feijiu.com对可能被视为冒犯性、不雅或令人反感的内容不承担任何责任。',
  //   clause3_9: '3.9 每个用户理解并接受，本网站上列出或显示的产品和内容可能与第三方的版权、商标、商业秘密、专利和其他个人或专有权利有关。此外，每个用户都同意，在网站上上传或列出该内容和信息的用户应对任何侵犯第三方权利的行为承担全部责任。本网站和Feijiu.com不对与第三方的知识产权和/或其他个人或专有权利有关的任何侵权或购买纠纷负责。',
  //   clause3_10: '3.10 当合法权益受到损害的政府、执法机构或权利人的要求，或被传票或其他法律文件强制执行时，Feijiu.com可能会披露用户的身份和联系方式。用户同意不就此类披露对Feijiu.com提起任何诉讼或索赔。',
  //   clause3_10_1: '您确认，因交易活动须向有关政府机构申报并提供买家的相关身份信息、为了提供更优服务，您同意将您的身份信息（包括但不限于姓名及或单位名称、身份证件号码及或统一社会信用代码、住址、联系电话等）提供给政府机构、其他上下游客户、Feijiu.com及其关联公司用于向政府机构申报、提供更优服务之用，具体提供方式为：在您报名时提供的相关身份信息。',
  //   clause3_11: '3.11 本网站可能允许用户通过超链接（文字链接、横幅、渠道或其他形式）、API或其他方式访问第三方提供的内容、产品或服务。在使用此类网站之前，请仔细阅读此类网站的条款和条件和/或隐私政策。您承认Feijiu.com无法控制此类第三方的网站，不监控此类网站，并且Feijiu.com不对此类网站或此类网站上提供的任何内容、产品或服务负责或承担任何责任。',

  //   clause4: '4. 买卖双方之间的交易',
  //   clause4_1: '4.1 每个用户理解并同意Feijiu.com不是传统的卖家或市场。通过本网站，Feijiu.com 供基于网络的电子平台，用于产品和服务的买卖双方之间交换信息。此外，Feijiu.com 还提供基于网络的电子交易平台，供用户根据交易服务协议的条款在网站内在线下达、接受、缔结、管理和履行提供产品和服务的订单。但是，对于任何服务，Feijiu.com 在特定交易中均不代表卖方或买方。Feijiu.com 不控制网站上出售的产品或服务的质量、安全性、合法性或可用性、卖方完成销售的能力或买方完成购买的能力，也不承担任何责任。',
  //   clause4_2: '4.2 特此告知用户，与以虚假借口行事的人打交道可能存在风险。Feijiu.com 使用多种技术来验证付费用户在网站上注册付费会员服务时提供给我们的某些信息的准确性。但是，由于在互联网上进行用户验证很困难，因此 Feijiu.com 不能也不会确认每个用户（包括但不限于付费会员）的声称身份。我们鼓励您使用各种手段和常识来评估您正在与谁打交道。',
  //   clause4_3: '4.3 访问或使用网站或服务的买方和卖方应承担与网站或服务有关或通过网站或服务进行任何买卖交易的风险。访问或使用本网站或服务的买方和卖方还应完全承担因与本网站交易标的的产品或服务相关的任何后续活动而引起或与之相关的任何责任或损害的所有风险。此类风险的示例包括但不限于产品和服务的虚假陈述、欺诈计划、产品质量不令人满意、不符合规格、有缺陷或危险的产品、非法产品、延迟或拖欠交货或付款、成本计算错误、违反保证、违约、运输事故、制造、进口、出口、分销、 提供、展示、购买、销售和/或使用网站上提供或展示的产品或服务可能违反或可能被声称侵犯第三方权利，以及用户可能因第三方主张第三方权利而产生辩护费用或其他费用的风险，或与任何一方声称他们有权就主张权利进行辩护或赔偿有关的任何索赔有关， 第三方权利索赔人的要求或索赔。此类风险的示例还包括消费者、其他购买者、产品最终用户或其他第三方声称他们因使用通过网站或服务获得的产品而遭受伤害或伤害的风险。上述所有风险均称为“交易风险”。Feijiu.com 对任何损害、索赔、责任、成本、伤害、不便、业务中断或可能因任何交易风险而产生或与之相关的任何类型的支出概不负责。',
  //   clause4_4: '4.4 网站上的买家和卖家全权负责制定和履行在网站或服务上、通过网站或服务或因使用网站或服务而进行的交易的条款和条件，包括但不限于有关付款、退货、保修、运输、保险、费用、税收、所有权、执照、罚款、许可证、处理、运输和储存的条款， 受交易服务协议（特别是第 3.2 条）规定的任何额外义务的约束。',
  //   clause4_5: '4.5 用户同意提供 Feijiu.com 合理要求的所有信息和材料，与您在网站或服务上、通过网站或服务进行的交易有关，或因使用网站或服务而进行的交易。如果用户未能提供所需的信息和材料，Feijiu.com 有权暂停或终止任何用户的账户，而无需对因此类暂停或终止而引起或与之相关的任何损失或损害承担任何责任。',
  //   clause4_6: '4.6 如果任何用户与交易的任何一方发生争议，该用户同意免除 Feijiu.com以及我们的代理人、关联公司、董事、高级职员和雇员）因此类争议或交易而引起或与之相关的所有索赔、要求、诉讼、成本、费用和损害（包括但不限于任何实际的、特殊的、偶然的或后果性的损害）。',

  //   clause5: '5. 责任限制',
  //   clause5_1: '5.1. 在法律允许的最大范围内，Feijiu.com在“原样”、“可用”和“所有事实”的基础上提供本网站。Feijiu.com对本网站的运营或本网站所包含的信息、内容、材料或产品不作任何明示或暗示的陈述或保证。在适用法律允许的最大范围内，Feijiu.com特此明确否认任何和所有明示或暗示的保证，包括但不限于对条件、质量、耐用性、性能、准确性、可靠性、非侵权性、适销性或特定用途适用性的任何保证。在不限制前述规定的情况下，Feijiu.com对本网站提供的任何商品不作任何明示或暗示的保证。特此排除所有此类保证、陈述、条件和承诺。您承认，通过使用或访问本网站，您使用或访问本网站的风险由您自行承担。本免责声明不适用于该物品制造商提供的任何产品保修。本免责声明构成本协议的重要组成部分。',
  //   clause5_2: '5.2. 在任何情况下，根据任何法律或衡平法理论，无论是侵权行为、合同、严格责任还是其他方面，Feijiu.com或其任何关联公司、员工、董事、高级职员、代理人、供应商或服务商均不对您或任何其他人因使用或无法使用本网站而引起或与之相关的任何性质的任何间接、特殊、偶然或后果性损失或损害负责， 包括但不限于利润损失、商誉损失、数据丢失、停工、结果准确性或计算机故障或故障的损害赔偿，即使Feijiu.com的授权代表已被告知或应该知道此类损害的可能性。',
  //   clause5_3: '5.3. 此外，在任何情况下，Feijiu.com均不对以下任何一种造成的损害负责，无论是特殊的、直接的、间接的、惩罚性的、偶然的或后果性的损害，还是与合同、疏忽、侵权或其他方面有关的损害：',
  //   clause5_3_a: 'a） 与通过本网站从卖方或第三方购买或获得的商品、服务或信息有关的任何争议，包括但不限于有关此类商品、服务或信息的质量、安全、保修、合法性或可用性的争议; ',
  //   clause5_3_b: 'b） 本网站上任何侵犯第三方权利的行为;',
  //   clause5_3_c: 'c） 未经授权访问本网站上任何用户的数据或私人信息;',
  //   clause5_3_d: 'd） 本网站任何用户的陈述或行为。',
  //   clause5_4: '5.4. 尽管有上述任何规定，如果Feijiu.com、我们的员工、代理人、关联公司、代表或代表我们行事的任何人被认定有责任，我们的责任将不超过您在索赔发生之日前三个月内因使用本网站而支付的佣金。',
  //   clause5_5: '5.5. Feijiu.com不对任何特殊的、直接的、间接的、惩罚性的、偶然的或后果性的损害或任何损害（包括但不限于利润或储蓄损失、业务中断或信息丢失的损害）负责，无论是合同、疏忽、侵权、衡平法或其他原因，还是因以下任何原因导致的任何其他损害： ',
  //   clause5_5_1: '（1） 使用或无法使用本网站或服务，',
  //   clause5_5_2: '（2） 通过本网站从用户或任何其他第三方购买或获得的商品、样品、数据、信息或服务的任何缺陷，',
  //   clause5_5_3: '（3） 任何侵犯第三方权利或索赔或要求用户制造、进口、出口、分销、提供、展示、购买、 销售和/或使用本网站上提供或展示的产品或服务可能违反或被指控侵犯第三方权利或索赔的任何部分，他们有权就第三方权利索赔人的权利主张、要求或索赔进行辩护或赔偿，',
  //   clause5_5_4: '（4） 第三方未经授权访问任何用户的数据或私人信息，',
  //   clause5_5_5: '（5） 本网站任何用户的声明或行为，',
  //   clause5_5_6: '（6） 与服务有关的任何事项，无论其如何产生，包括因疏忽而产生的。',
  //   clause5_6: '5.6. 本条款对您的责任限制和排除应在法律允许的最大范围内适用，无论Feijiu.com是否已被告知或应该意识到任何此类损失的可能性，均应适用。',

  //   clause6: '6. 赔偿',
  //   clause6_title: '您同意为Feijiu.com及其关联公司、董事、高级职员和员工辩护、赔偿并使其免受任何和所有损失、索赔、责任、损害、成本和费用，包括律师费，这些损失、索赔、责任、损害、成本和费用，由以下原因引起或与之相关：',
  //   clause6_content: '（1）您使用本网站，（2）您违反您向Feijiu.com作出的任何陈述和/或保证，以及（3）第三方权利索赔人或其他第三方就所提供的产品提出的索赔，或显示在网站上。',

  //   clause7: '7. 不可抗力',
  //   clause7_content: '在任何情况下，Feijiu.com均不对通过本网站提供的内容或服务的任何延迟、故障或中断负责，这些延迟、故障或中断直接或间接地由于自然行为、力量或超出我们合理控制范围的原因，包括但不限于互联网故障、计算机、电信或任何其他设备故障、电力故障、罢工、劳资纠纷、 骚乱、叛乱、内乱、劳动力或材料短缺、火灾、洪水、风暴、爆炸、天灾、战争、政府行为、国内外法院或法庭的命令或第三方的不履行。',

  //   clause8: '8. 著作权、商标权和其他知识产权',
  //   clause8_1: '8.1 除非另有说明，本网站上的所有内容均为Feijiu.com或其关联公司拥有、控制或许可的版权、商标、商业外观和/或其他知识产权，并受中华人民共和国和国际版权、专利、商标和其他知识产权法律的保护。本网站上所有内容的汇编（即收集、整理和汇编）是Feijiu.com的专有财产，也受国际版权、专利、商标和其他知识产权法的保护。    ',
  //   clause8_2: '8.2 Feijiu.com及其供应商和/或许可方明确保留本网站上出现的所有文本、程序、产品、流程、技术、内容和其他材料的所有知识产权。访问本网站并不授予也不应被视为授予任何人Feijiu.com或任何第三方知识产权下的任何许可。Feijiu.com的名称和徽标以及所有相关的产品和服务名称、设计标志和标语均为Feijiu.com的商标。所有其他标志均为其各自公司的财产。本网站所包含的材料不授予任何商标、服务标志或徽标许可。访问本网站并不授权任何人以任何方式使用任何名称、徽标或标记。严禁未经授权使用这些名称、徽标或标记。',
  //   clause8_3: '8.3 本网站上对第三方的任何名称、标志、产品或服务的引用，或对第三方网站或信息的超文本链接，仅为方便您而提供，并不以任何方式构成或暗示Feijiu.com对第三方、信息、产品或服务的认可、赞助或推荐。Feijiu.com不对任何第三方网站的内容负责，也不对任何此类网站上的内容或材料的准确性做出任何陈述。如果您决定链接到任何此类第三方网站，您将完全自行承担风险。    ',

  //   clause9: '9. 终止',
  //   clause9_content: '除非您或Feijiu.com终止，否则这些使用条款将一直有效。您可以随时终止本协议，前提是您停止对本网站的任何进一步使用。Feijiu.com也可以随时终止本协议，并可以立即终止本协议，恕不另行通知，并因此拒绝您访问本网站，如果Feijiu.com自行决定您未能遵守本协议的任何条款或规定。在您或Feijiu.com终止本协议后，您必须立即销毁从本网站下载或以其他方式获得的所有材料，以及此类材料的所有副本，无论是根据使用条款还是其他方式制作的。Feijiu.com对任何内容的权利，以及第2.6、3、4、5、6、8、10和11条的规定，在本协议终止后继续有效。',

  //   clause10: '10. 通知',
  //   clause10_1: '10.1 除非另有明确说明，否则向Feijiu.com发出的所有通知或要求均应以书面形式发出，并通过邮寄方式送达Feijiu.com，地址为：中国河北省石家庄市长安区乐汇城C座7层，收件人：法务部。Feijiu.com以上述任何一种方式收到通知后，即视为有效。    ',
  //   clause10_2: '10.2 所有向用户发出的通知或要求，如果亲自送达，通过电子邮件发送到在注册过程中提供给Feijiu.com的电子邮件地址（不时更新，如适用），或将此类通知或要求张贴在本网站可免费公开访问的区域，则应有效。在以下情况下，向用户发出的通知应被视为该用户已收到：（1）Feijiu.com能够证明通信（无论是物理形式还是电子形式）已发送给该用户，或（2）Feijiu.com在网站可免费公开访问的区域发布此类通知后立即发送。    ',
  //   clause10_3: '10.3 您同意，Feijiu.com以电子方式发送给您的所有协议、通知、要求、披露和其他通信均符合法律要求，即此类通信应采用书面形式。',

  //   clause11: '11. 一般条款',
  //   clause11_1: '11.1 Feijiu.com保留随时更新或修改本使用条款的权利，恕不另行通知。最新版本的使用条款将约束本网站的使用。 如果Feijiu.com自行决定进行重大更改，Feijiu.com将通过电子邮件通知您与您的帐户关联的电子邮件地址。您在任何此类更改后使用本网站即表示您无条件同意遵守经修订的使用条款并受其约束。    ',
  //   clause11_2: '11.2 本协议代表双方就您使用本网站和服务达成的完整协议，并取代用户与Feijiu.com之间关于同一主题的所有先前书面或口头协议和陈述。',
  //   clause11_3: '11.3 本使用条款中使用的标题仅供参考，绝不定义或限制本节的范围。',
  //   clause11_4: '11.4 如果本协议的任何条款因任何原因被认定为不可执行，则该条款应在必要的范围内删除或修改，以使其可执行，本协议的其他条款应保持完全有效。',
  //   clause11_5: '11.5 Feijiu.com未能对您或他人违反本协议的行为采取行动，并不构成放弃，也不限制Feijiu.com对此类违约或任何后续违约行为的权利。',
  //   clause11_6: '11.6 Feijiu.com和您是独立承包商，本条款无意或创建任何代理、合伙、合资、雇主-雇员或特许人-特许经营者关系。',
  //   clause11_7: '11.7 Feijiu.com未能执行任何权利或未能就您违反本条款的行为采取行动，并不构成对该权利的放弃，也不构成Feijiu.com对后续或类似违约行为采取行动的权利的放弃。',
  //   clause11_8: '11.8 Feijiu.com可自行决定将本合同及其全部或部分权利、义务和利益转让给任何一方或实体;但是，用户不得将其在本合同项下的权利、义务和利益转让给任何一方或实体。',
  //   clause11_9: '11.9 因本协议或您使用本网站而引起或与之相关的任何诉讼或程序，必须提交石家庄仲裁委员会进行仲裁，仲裁应按照申请仲裁时有效的仲裁规则进行。仲裁裁决为终局裁决，对双方均有约束力。',

  // },

  // // 个人信息跨境传输同意函
  // infortransmission: {
  //   title: '个人信息跨境传输同意函',
  //   introduce1: '尊敬的用户:  ',
  //   introduce2: '感谢您选择使用Feijiu.com服务。为了向您提供更好的信息服务，我们需要收集和传输您的个人信息，为了确保您的个人信息得到妥善保护，我们特别制定了《Feijiu.com个人信息跨境传输同意函》以明确我们在处理您个人信息时的权责关系。请您仔细阅读下文，并在同意的情况下签署。  ',

  //   clause1: '一、信息的收集和使用  ',
  //   clause1_1: '为了完成Feijiu.com服务，我们需要收集您的个人信息，包括但不限于姓名、联系方式、地址等。这些信息将用于确认您的身份、联系您以及提供准确的信息服务。我们承诺只在必要的范围内收集和使用这些信息，并且严格按照相关法律法规的要求进行保护。  ',

  //   clause2: '二、信息的跨境传输  ',
  //   clause2_1: '为了给您提供全球范围的信息服务，我们可能需要将您的个人信息传输至国外。在这种情况下，我们会确保数据传输符合适用法律的要求，并采取必要的措施保护您的个人信息的安全。同时，我们也会与接收方签署合同或其他法律文件，确保其同样会对您的个人信息予以妥善保护。  ',

  //   clause3: '三、同意函的签署和生效  ',
  //   clause3_1: '请您在阅读并理解本同意函后，如同意将个人信息传输至国外，请您在下方点击【同意签署】，您的个人信息将在我们收到您的同意函后开始跨境传输。  ',

  //   clause4: '四、其他事项  ',
  //   clause4_1: '1.我们会尽力保护您的个人信息安全，采取合理的安全措施防止信息泄露、滥用或变造。然而，由于互联网的开放性和不确定性，我们无法保证您的个人信息完全安全。  ',
  //   clause4_2: '2.如您对个人信息的收集、使用以及本同意函有任何疑问或意见，请通过Feijiu.com官方网站或客户服务渠道与我们联系。  ',

  //   introduce3: '请您在确认并签署本同意函前，再次仔细阅读以上内容。如果您同意并愿意接受我们的个人信息跨境传输政策，请您在下方点击【同意签署】。  ',
  //   introduce4: '感谢您对Feijiu.com的信任与支持!  ',

  //   introduce5: '本人声明：本人已仔细阅读并充分理解上述所有条款，并且本人已取得签署本授权书的必要授权。本人自愿作出上述授权。  ',
  // }

}

