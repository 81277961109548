<template>
    <div class="container"  >
    <Indexhead  v-show="!headShow" />
     <div class="banner">
        <headH    ref="headH" />
        <div class="Search_item">
             <h3>{{$t('home.slogan')}}</h3>
             <Search/>
        </div>
     </div>
        <LabeIcon  />
        <IndexList
            title="home.list.Products"
            url="/supply/supply"
            type='supply'
        />
        <IndexList
          title="home.list.Recycling"
           url="/supply/Recycle"
           type='demand'
         />
        <Message />
    </div>
</template>
<script>

 import headH from './components/head' //默认展示
  import Indexhead from '@/components/indexhead' //下滑展示
 import Search from '@/components/Search'//搜索
  import LabeIcon from './components/labeicon'//废旧icon
  import IndexList from './components/IndexList'//列表
import Message from '@/components/message'//留言

export default {
   metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.index.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.index.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.index.description"),
          },
        ],
      };
    },
  },

    components: {
        headH ,
        Indexhead,
        Search,
        LabeIcon,
        IndexList,
        Message

    },
    data(){
        return{
            headShow:true, //悬浮导航

        }
    },
    mounted(){

      

      window.addEventListener('scroll', this.handleThrottledScroll);


    },
   destroyed() {
      window.removeEventListener('scroll', this.handleThrottledScroll);
  },
    methods:{
        handleThrottledScroll: throttle(function() {
            const scrolledDistance = window.scrollY;
            if (scrolledDistance >= 30) {
                this.headShow = false;
            } else {
                this.headShow = true;
            }
            }, 100) // 设置节流时间为 100ms
        }


}
// 节流函数
function throttle(func, delay) {
  let timer = null;
  return function() {
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, arguments);
        timer = null;
      }, delay);
    }
  };
}

</script>
<style lang="scss" scoped>
    .container{
      .headt{
          position: fixed;
        z-index: 2001;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        height: 90px;
        ::v-deep .headt_content{
             height: 90px;
          
        }


      }
        background: #F6F6F6;
        .head{
            position: relative;
            z-index: 2;
        }
            .banner{
                position: relative;
                display: table;
                width: 100%;
                  height: 600px;
                background: url('@/assets/images/home/banner.jpg')no-repeat center;
                        background-size: 100% 100%;
                .Search_item{
                  width: 776px;
                    margin: 117px auto 0;
                    text-align: center;
                  h3{
                      color: #FFFFFF;
                      font-size: 46px;
                      height: 104px;
                      margin-bottom: 47px;
                  }
                }

            }
    }
</style>
