<template>
  <!-- 选择货币 -->
  <div class="currency">
    <el-popover
      popper-class="currencyContent"
      placement="bottom"
      title=""
      width="230"
      trigger="hover"
    >
      <ul class="currencylist">
        <li
          v-for="item in currencyList"
          :key="item.code"
          :class="item.code === currency.code ? 'active' : ''"
          @click="tabs(item)"
        >
  
          {{ $getName(item) }}
          
        </li>
      </ul>
      <div slot="reference">{{ $getName(currency) }}</div>
     
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getcurrencies } from "@/api/home";
export default {
  data() {
    return {
      currencyList: []
    };
  },
  computed: {
    ...mapState('lang', ['currency', 'language']),
 
  },
  mounted () {
      getcurrencies().then(res=>{
        this.currencyList=res.data
      })
  },

  methods: {
    ...mapMutations('lang', ['setcurrency']),
    tabs(item) {
      this.setcurrency(item);
    },

  }
};
</script>

<style lang="scss">
.currencyContent {
  padding: 10px 0 !important;
  border: none !important;
  
  ul {
    li {
      padding-left: 16px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}
</style>

<style lang="scss" scoped>
.currency {
  display: inline-block;
  span {
    display: inline-block;
  }
}
.currencylist {
  li {
    line-height: 30px;
    cursor: pointer;
    &:hover {
      background: #f4f4f4;
      color: #00b968;
    }
  }
  .active {
    background: #00b968;
    color: #ffffff;
  }
}
</style>
