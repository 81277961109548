<template>
    <div class="hotTopics">
        <div class="hotContent">
            <div class="title">{{ $t('Help.home.hotTipcs') }}</div>
            <div class="titleline"></div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick(activeName)"
                style="width: 1320px; margin-top: 30px; background: #fff; border-radius: 4px; height:56px;">
                <el-tab-pane :label=item.categoryName :name=item.code v-for="(item, index) in categoryList" :key="index"
                    :title="item.categoryName">
                    <el-tooltip slot="label" effect="dark" :content="item.categoryName" placement="bottom-start">
                        <span class="tmp">{{ item.categoryName }}</span>
                    </el-tooltip>
                </el-tab-pane>
            </el-tabs>
            <div class="hotTagbox">
                <div class="tag" v-for="(item, index) in linkList" :key="index" @click="tagClick(item)">{{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { hotCategoryApi, categoryListApi } from '@/api/help'
export default {
    data() {
        return {
            activeName: '',
            categoryList: '',
            linkList: [],
        }
    },
    components: {},
    /*
    组件创建后
    经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
    */
    created() {
    },
    /*
    挂载后
    如果要操作当前组件的dom 最早 只能在mounted阶段执行
    */
    mounted() {
        this.getData()
    },
    methods: {
        handleClick(item) {
            this.getHotList(item)
        },
        tagClick(item) {
            this.$router.push({
                path: '/helpCenter/qustion/content',
                query: {
                    // parm: {
                    levelCategoryCode: item.levelCategoryCode,
                    categoryCode: item.categoryCode,
                    id: item.id
                    // }
                }
            })
        },
        getData() {
            hotCategoryApi({
                type: '1'
            }).then((res) => {
                if (res.code == '200') {
                    this.categoryList = res.data.data;
                    var firstData = this.categoryList[0]
                    this.activeName = firstData.code
                    this.getHotList(firstData.code)
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning",
                    });
                }
            })
        },
        getHotList(code) {

            categoryListApi({
                hotCategoryCode: code,
                type: '1'
            }).then((res) => {
                if (res.code == '200') {
                    this.linkList = res.data.data;
                }
            })
        }

    },
}
</script>

<style  lang="scss" scoped>
.hotTopics {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F6F6F6;

    .hotContent {
        background: #F6F6F6;
        width: 1320px;
        display: flex;
        //    justify-content: center;//水平居中
        flex-direction: column;
        align-items: center; //垂直居中

        .title {
            width: 100%;
            height: 37px;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #333333
        }

        .titleline {
            width: 35px;
            height: 4px;
            background: #00B968;
            margin: 0 auto;

        }

        ::v-deep .el-tabs__item.is-active {
            color: #FFFFFF;
            background: #00B968;
            width: 220px;
            border-top-left-radius: 4px;
        }
       ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
        height: 56px;
       }
        ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
            width: 219.7px;
            text-align: center;
        }

        ::v-deep .el-tabs__item {
            color: #000000;
            font-weight: 600;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 56px;
            line-height: 56px;
        }


    }

    .hotTagbox {
        display: flex;
        margin-top: -15px;
        width: 1320px;
        height: 164px;
        padding: 50px 0px 50px 0px;
        flex-wrap: wrap;
        background: #fff;

        .tag {
            text-align: center;
            width: 440px;
            height: 16px;
            line-height: 16px;
            margin-bottom: 30px;
            color: #666666;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                color: #00B968;
            }
        }
    }

}
</style>
