<template>
    <div class="container">
        <!-- 导航栏组件 -->
        <breadCrumbs />
        <!-- 内容 -->
        <div class="content-box">
            <!-- 列表 -->
            <NewsList :categoryCode="categoryCode"/>
            <div class="select-box">
                <!-- 选择分类 -->
                <NewsCagetory @categoryClick="categoryClick"/>
                <!-- 热门 -->
                <HotNews />
            </div>
        </div>

    </div>
</template>
<script>

import NewsList from './components/newsList.vue'
import NewsCagetory from './components/newsCagetory.vue'
import HotNews from './components/hotNews.vue'
import breadCrumbs from '@/components/breadCrumbs.vue'

export default {

   metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.news.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.news.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.news.description"),
          },
        ],
      };
    },
  },
    
    components: {
        NewsList, NewsCagetory, HotNews,breadCrumbs
    },
    data() {
        return {
          CategoryList:null,
          categoryCode:"",
        }
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
      categoryClick(data){
        console.log(data)
       this.CategoryList='',
       this.pageNum=1,
       this.pageSize=10,
        this.categoryCode=data
      },

    }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.container {
    width: $contentWidth;
    background: #F6F6F6;
    margin: 0 auto;

.content-box {
    display: flex;
}
    .select-box {
        width: 672px;
    }

}
</style>
