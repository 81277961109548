<template>
  <div class="headContent">
    <div style="width: 100%;border-bottom: 1px solid #E6E7EB;">
    <taBbar />
    </div>
    <div style="width: 1380px; margin: 0 auto;">
      <div class="img-box">
        <img class="img" src="@/assets/images/login/logo.png" alt="" @click="backHome">
        <span style="height: 30px; width: 1px; background: #E6E6E6; margin-left: 10px; "></span>
        <span class="img-title">{{ $t('home.nav.3') }}</span>
      </div>
    </div>

  </div>
</template>
<script>


import taBbar from '@/components/tabBar'
export default {
  components: {
    taBbar
  
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  destroyed() {
  },
  methods: {
    backHome() {
      this.$router.push('/')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.headContent {
  width: 100%;
  height: 127px;
  background: #fff;

  .user {
    width: 1440px;
    margin: 0 auto;
    text-align: right;
    line-height: 36px;

  }

  .img-box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    
    .img {
      width: 152px;
      height: 44px;
      cursor: pointer;
    }

    .img-title {
      font-size: 24px;
      color: #333333;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  

}</style>
