
<template>
  <div class="newsDetail">
    <BreadCrumbs />
    <!-- 内容 -->
    <div class="content-box">
      <DetailContent :newsDetailData = "newsDetailData"/>
      <HotNews />
    </div>
    <Message />
  </div>
</template>
<script>

import BreadCrumbs from '@/components/breadCrumbs.vue'
import DetailContent from './components/newsDetailContent.vue'
import HotNews from '@/page/news/components/hotNews.vue'
import Message from '@/components/message.vue'
import { getNewsDetail } from "@/api/news";
export default {
     metaInfo() {
    //  动态meta
      return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      return {
        title: this.$t("tdk.news.detail.title"),
        meta: [
          {
            name: "keyWords",
            content:  this.$t("tdk.news.detail.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.news.detail.description"),
          },
        ],
      };
    },
  },
  components: {
    BreadCrumbs, DetailContent, HotNews, Message
  },
  data() {
    return {
      newsDetailData: ''
    }
  },
  watch: {
    "$route.query.id": "getData"
  },
  mounted() {
    this.getData()
  },
  destroyed() {
  },
  methods: {
    getData() {
      if (this.$route.query.id) {
        getNewsDetail({ id: this.$route.query.id }).then((res) => {
          if (res.code == '200') {
            this.newsDetailData = res.data
            console.log('详情页展示数据',this.newsDetailData)
          }
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.newsDetail {
  width: $contentWidth;
  margin: 0 auto;
  background: #F6F6F6;

  .content-box {
    display: flex;
    widows: 100%;
    margin-bottom: 20px;
  }

}
</style>
