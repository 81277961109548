<template>
  <div class="upload-wrapper">
    <!--
action	必选参数，上传的地址
headers	设置上传的请求头部
multiple	是否支持多选文件
data	上传时附带的额外参数
show-file-list	是否显示已上传文件列表
limit	最大允许上传个数
file-list 上传的文件列表 imagePath=='camera'?require('@/assets/images/camera.png'):require('@/assets/images/image_default.png')
-->

    <el-upload v-if="limit==1" class="avatar-uploader" :action='actionUrl' :headers='headersD'
               :show-file-list="false" :on-success="onSuccess"  :before-upload="beforeUpload"
               :on-error="onError" accept="image/jpeg, image/png, image/jpg">
      <div v-if="avatarImage" style="position: relative;">
<!--        <el-popover-->
<!--          placement="top-start"-->
<!--          width="98"-->
<!--          trigger="hover"-->
<!--        >-->
<!--          <el-button size="mini" @click="handleRemoveAvatar">{{ $t('staging.Delete') }}</el-button>-->
<!--          <img class="avatar" :src="avatarImage" slot="reference">-->
<!--        </el-popover>-->
        <img class="el-icon-error" style=" position: absolute;z-index: 100;top: 0;right: 0;color: #e1251b;
font-size: 20px" @click.stop="handleRemoveAvatar"/>
        <img class="avatar" :src="avatarImage" slot="reference">
      </div>
      <div v-else class="avatar" style="justify-content: center;align-items: center;"
           :style="{ border:imagePath=='camera'? '1px solid #CDCDCD':'',borderRadius:imagePath=='camera'? '2px':'' }" >
        <img
          :src="imagePath=='camera'?require('@/assets/images/camera.png'):require('@/assets/images/image_default.png')"
          :height="imageHeight" :width="imageWidth"/>
      </div>
    </el-upload>

    <el-upload v-else list-type="picture-card" :action='actionUrl' :headers='headersD' :limit='limit'
               :on-success="onSuccess" :on-exceed="handleExceed"  :before-upload="beforeUpload"
               :on-error="onError" :file-list="fileList" accept="image/jpeg, image/png, image/jpg">
      <img slot="default" class="el-icon-plus" src="@/assets/images/image_default.png" :height="imageHeight"
           :width="imageWidth"/>
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" :height="imageHeight"
             :width="imageWidth"/>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>

    </el-upload>
    <!-- 图片下面预提示文字 -->
    <slot name="tip">{{ tipText }}</slot>
    <!-- 图片方法方法 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from '@/utils/cookie'

export default {
  name: "upload",
  data() {
    return {
      // 图片方法查看
      dialogImageUrl: '',
      // 是否查看
      dialogVisible: false,
      // 请求头
      headersD: {'X-Access-Token': getToken(), 'imageType': this.imageType},//'Access-Control-Allow-Origin':'*',
      // 地址
      actionUrl: process.env.VUE_APP_BASE_API + '/v1/web/api/files/uploadImage',
      // 存放的是后台返回的url
      imageUrlArray: [],

      fileList: [],
      avatarImage: '',
    }
  },
  created() {

  },
  props: {
    // 上传的个数
    limit: {
      type: Number,
      default: 1,
    },
    // 图片显示
    showFileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 预提示文字
    tipText: {
      type: String,
      default: "",
    },
    imagePath: {
      type: String,
      default: 'camera',
    },
    imageWidth: {
      type: Number,
      default: 40
    },
    imageHeight: {
      type: Number,
      default: 34
    },
    type: {
      type: String,
      default: 'camera',
    },
    avatar: {
      type: String,
      default: '',
    },
    imageType: {
      type: String,
      default: '5'
    }
  },
  watch: {
    avatar: {
      handler(n, o) {
        this.avatarImage = n
      }
    },
    showFileList: {
      handler(n, o) {
        this.fileList = n
        for (var item of n) {
          this.imageUrlArray.push(item.url)
        }
      }
    }
  },
  mounted() {
    // if (this.showFileList.length > 0) {
    this.fileList = this.showFileList
    for (var item of this.showFileList) {
      this.imageUrlArray.push(item.url)
    }
    this.avatarImage = this.avatar
    // }
  },
  methods: {
    beforeUpload(file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png'||file.type === 'image/jpg') {
        // 判断上传文件是否超过2MB（2 * 1024 * 1024 字节）
        const isLt10M = file.size / 1024 / 1024 < 2;

        if (!isLt10M) {
          this.$message.error('The upload file size cannot exceed 2 MB!');
          return false; // 返回false阻止上传
        } else {
          return true; // 返回true则继续执行上传
        }
      }else{
        this.$message.error('Upload avatar images can only be in JPG or PNG format!');
      }
    },
    handleExceed(files, fileList) {
      this.$message.error('Up to 5 images can be uploaded. Please delete the uploaded files and continue uploading');
    },
    // 上传成功的回调函数
    onSuccess(response, file, fileList) {
      if (this.limit != 1) {
        this.fileList = fileList
        if (response.code == 200) {
          let url = process.env.VUE_APP_IMAGE_URL + response.data[0]
          this.imageUrlArray.push(url)
        }

      } else {
        if (response.code == 200) {
          let url = process.env.VUE_APP_IMAGE_URL + response.data[0]
          this.avatarImage = url
          this.imageUrlArray[0] = url
        }
      }
      this.$emit("success", {
        type: this.type,
        data: this.imageUrlArray
      });
    },
    // 上传失败的回调函数
    onError(response, file, fileList) {
      this.$message.error('Error uploading image, please upload again')
    },

    // 图片查看
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemoveAvatar() {
      this.avatarImage = ""
      this.$emit("success", {
        type: this.type,
        data: []
      });
    },
    // 自定义点击删除按钮的逻辑
    handleRemove(file) {
      this.fileList.forEach((v, index) => {
        if (file.name === v.name && file.url === v.url) {
          this.fileList.splice(index, 1);
          this.imageUrlArray.splice(index, 1);
        }
      });
      this.$emit("success", {
        type: this.type,
        data: this.imageUrlArray
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-wrapper {
  display: inline-block;
  font-size: 12px;
}

.el-upload-list__item div {
  height: 100%;
}

.child-component {
  position: absolute; /* 必需 */
  top: 50%; /* 水平居中 */
  left: 50%; /* 垂直居中 */
  transform: translate(-50%, -50%); /* 较新版本的浏览器支持该属性 */
}

::v-deep .avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar {
  width: 98px;
  height: 98px;
  display: flex;
}

::v-deep .el-upload--picture-card {
  background-color: #00000000;
  border: 0px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: auto;
  height: auto;
  cursor: pointer;
  line-height: 40px;
  vertical-align: top;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 98px;
  height: 98px;
  margin: 0 8px 0px 0;
  display: inline-block;
}
</style>
