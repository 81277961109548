<template>
    <div class="hotNews" v-if="list">
        <div class="title" >{{$t('News.hotNews')}}</div>
        <div class="cell-box" v-for="(item, index) in list" :key="index" @click="cellBoxClick(item)">
            <div class="img-box" v-if="item.coverImage">
                <img :src="item.coverImage" referrer="no-referrer|origin|unsafe-url"
                    referrerPolicy="no-referrer" class="img">
            </div>
            <div class="detail-box">
                <div class="detailTitle">{{item.title}}</div>

                <div class="date-box">
                    <span class="typetitle">{{item.browseNum}}</span>
                    <div class="typeLine"></div>
                    <img src="@/assets/images/home/news_date.png" class="dateImg" alt="">
                    <span class="dateTitle">{{item.createTime | formatDate}}</span>
                </div>

            </div>
            <span class="line" v-if="index+1 != list.length"></span>
        </div>
    </div>
</template>
<script>


import {getNewsTopList} from "@/api/news";
import moment from "moment"; //引入moment

export default {
    components: {
    },
    data() {
        return {
            list:''
        }
    },
    filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
    mounted() {
      this.getTopList()

    },
    destroyed() {
    },
    methods: {
      getTopList(){
        getNewsTopList({num:5}).then((res) => {
          this.list = res.data.list;
        })
      },
      cellBoxClick(data) {
            this.$router.push({
              path:'/news/newsDetail',
              query:{
                id:data.id
              }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.hotNews {
    margin-top: 0px;
    margin-left: 20px;
    width: 432px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
    .title {
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: 600;
    }
    .cell-box {
        height: 140px;
        position: relative;
        // background: red;
        overflow: hidden;
        display: flex;
        cursor: pointer;

        .img-box {
            width: 100px;
            height: 100px;
            display: flex;
            margin-top: 20px;
            background: #ddd;
            flex-shrink: 0;
            .img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 7px;

            }

        }
        .detailTitle {
                margin-top: 20px;
                font-size: 18px;
                font-family: Arial Black;
                font-weight: 600;
                color: #000000;
                margin-left: 10px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
            .date-box {
                display: flex;
                position: absolute;
                bottom: 20px;
                height:18px ;

                .typetitle {
                    font-size: 14px;
                    color: #00B968;
                    margin-left: 10px;
                    height: 18px;
                    line-height: 18px;
                    font-weight: 600;
                }
                .typeLine {
                    margin-left: 5px;
                    height: 11.5px;
                    background: #D1D1D1;
                    width: 1px;
                }
                .dateImg {
                    width: 18px;
                    height: 18px;
                    margin-left: 5px;
                }
                .dateTitle {
                    font-size: 14px;
                    color: #333333;
                    margin-left: 2px;
                    height: 18px;
                    line-height: 18px;
                }


            }
        .line {
            position: absolute;
            width:392px;
            height:1px;
            background:#E8E8E8;
            display: inline-block;
            vertical-align:middle;
            bottom: 0px;
        }
    }
}

</style>
