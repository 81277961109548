<template>
  <div id="app"  ref="vueDom" >

       <Head  v-if="showhead"  />
         <!-- <keep-alive :include="['supplyDetail']"> -->
      <router-view   :key="$route.fullPath"  />
         <!-- </keep-alive> -->
      <Foote  v-if="showfoote"/>

  </div>
</template>

<script>
import Head from '@/components/head'
import Foote from '@/components/foote'

export default {
  name: 'App',
  components: {
     Head,
     Foote
  },
    data() {
    return {
      loading:true,
      showhead: false,
      showfoote: false,
    }
  },
    watch: {
    "$route.path": {
      handler: function (newVal, oldVal) {
      // console.log('页面---------',newVal)

        if(newVal=='/' || newVal.includes('supply') || newVal.includes('news') || newVal.includes('exhibition') || newVal.includes('aboutUs') || newVal.includes('contactUs')|| newVal.includes('helpCenter')){
            this.showfoote = true
        }

      },
      immediate: true,
    },
  },
  mounted() {


//  setTimeout(()=>{
//     console.log( this.$refs.vueDom.style.displa)
//         this.$refs.vueDom.style.display = 'block'
//     },1000)

      this.loading=false
      //  console.log("mounted" + this.$route.path)
      this.showhead = true
      this.showfoote = true

  },
  beforeUpdate() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        // 若为移动设备，则跳转到M站
            window.location.href='https://m.feijiu.com'
      }
    // console.log(this.$route)
    // console.log("beforeUpdate" + this.$route)
    // 不需要头

    if (this.$route.name== 'login' ||this.$route.name== 'creatAccount'  || this.$route.path=='/' || this.$route.path.includes('helpCenter')||
      this.$route.path.includes('staging')|| this.$route.path.includes('publish')|| this.$route.path.includes('forgetPassword')) {
      this.showhead = false
    }else {
      this.showhead = true
    }

    // 不需要底
     if (this.$route.path.includes('staging') || this.$route.name=='verifyCode' || this.$route.name== 'creatAccount' || this.$route.name== 'login' ){
        this.showfoote = false
     }

    },

   created() {

        // // 在页面加载时读取localStorage
        // if (localStorage.getItem('store')) {
        //   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))));
        // }

        this.$i18n.locale=this.$store.state.lang.language.value
        // 在页面刷新时将store保存到localStorage里
        window.addEventListener('beforeunload', () => {
          localStorage.setItem('store', JSON.stringify(this.$store.state));
        });
      },
}
</script>
<style>
body{
  width: 100%;
   background: #F6F6F6;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
/* 所有弹出层增加层级 */
.el-message{
  z-index: 9999 !important;
}
</style>
