<template>
    <div class="qustion">

        <Head />
        <HelpSearch />
        <div class="qustionBox">
            <div class="cagetroy">
                <div class="title">{{ $t('Help.home.category') }}</div>
                <div class="line"></div>
                <el-menu :default-active="activeCode" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
                    @select="select">
                    <div v-for="(item, index) in leftCategoryListData" :key="index">
                        <el-submenu v-if="item.children.length" :index="item.code">
                            <template slot="title">
                                <span
                                    style="display:block;width:90%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"
                                    :title="item.categoryName">{{ item.categoryName }}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item
                                    style="display:block;width:90%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"
                                    :index="detailItem.code" :title="detailItem.categoryName"
                                    v-for="(detailItem, detailIndex) in item.children" :key="detailIndex">{{
                                        detailItem.categoryName }}

                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-menu-item :index="item.code" v-else>
                            <span style="display:block;width:90%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"
                                slot="title" :title="item.categoryName">{{ item.categoryName }}</span>
                        </el-menu-item>
                    </div>
                </el-menu>
            </div>

            <div class="cagetroyContent">
                <div class="contentTitle">{{ label }}</div>
                <div v-if="this.$route.path === '/helpCenter/qustion/list'" v-loading.fullscreen.lock="fullscreenLoading">
                    <!-- 问题列表模块 -->
                    <div class="list" :v-if="contentListData" v-for="(item, index) in contentListData" :key="index"
                        @click="spanClick(item)">
                        {{ item.title }}
                    </div>
                    <div style=" font-size: 16px;color: #666666; width:100%;height:300px;text-align: center;line-height: 300px;"
                        v-if="!contentListData && loading">There are no articles in this category.Please try another
                        category.</div>
                </div>
                <Content v-if="this.$route.path === '/helpCenter/qustion/content'" />
            </div>
        </div>
    </div>
</template>

<script>

import Head from '../components/head.vue'
import HelpSearch from '../components/helpSearch.vue'
import Content from './components/content.vue'
import { listPageApi, categoryTreeListApi } from '@/api/help'

export default {
    components: {
        Head, HelpSearch, Content
    },
    metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: this.$t("tdk.helpCenter.title"),
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.helpCenter.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.helpCenter.description"),
                    },
                ],
            };
        },
    },
    data() {
        return {
            //左侧分类列表
            leftCategoryListData: '',
            contentListData: '',
            numBool:false,
            id: '',
            label: this.$t('Help.home.category'),
            activeCode: '',
            oneCode: '',
            twoCode: '',
            fullscreenLoading: false,
            loading: true
        }
    },
  
    /*
    组件创建后
    经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
    */
    created() {
    },
    /*
    挂载后
    如果要操作当前组件的dom 最早 只能在mounted阶段执行
    */
    mounted() {
        this.getData()
    },
    methods: {
        // 接口请求(左侧分类列表)
        getData() {
            categoryTreeListApi({
                type: '1'
            }).then((res) => {
                if (res.code == '200') {
                    this.leftCategoryListData = res.data
                    // 如果是搜索或者帮助中心首页点击进来的 需要选中对应的下标
                    var categoryCode = this.$route.query.categoryCode
                    var levelCategoryCode = this.$route.query.levelCategoryCode
                    var id = this.$route.query.id
                    var first = this.$route.query.first
                    if (categoryCode || levelCategoryCode) {
                        this.checkCode(this.leftCategoryListData)
                    } else {
                        if (first) {
                            this.getFirstCodeWith(this.leftCategoryListData)
                        }
                    }
                }
            })
        },

        getDetailData(code) {
            this.loading = false
            var that = this
            that.contentListData = ''
            this.fullscreenLoading = true
            listPageApi({
                categoryCode: code,
                pageNum: 1,
                pageSize: 1000,
                type: '1'
            }).then((res) => {
                if (res.code == '200') {
                    this.contentListData = res.data.data
                 
                }
                setTimeout(() => {
                    this.fullscreenLoading = false
                    this.loading = true
                }, 500);

            })
        },

        // 无二级的点击回调
        select(key) {
            this.getCategoryName(key)
            if (this.$route.path === '/helpCenter/qustion/content') {
                this.$router.push({
                    path: '/helpCenter/qustion/list',
                    query: {
                        categoryCode: this.twoCode,
                        levelCategoryCode: this.oneCode
                    }
                })
            } else {
                this.getDetailData(key)

            }

        },
        // 有二级的点击回调
        handleOpen(key, keyPath) {

            this.getCategoryName(key)
            if (this.$route.path === '/helpCenter/qustion/content') {
                this.$router.push({
                    path: '/helpCenter/qustion/list',
                    query: {
                        categoryCode: this.twoCode,
                        levelCategoryCode: this.oneCode
                    }
                })
            } else {
                this.getDetailData(key)
            }
        },

        handleClose(key, keyPath) {
            this.activeCode = ''

        },

        spanClick(item) {
            this.id = item.id
            // console.log('跳转之前的参数', this.id)
            this.$router.push({
                path: '/helpCenter/qustion/content',
                query: {
                    id: item.id,
                    levelCategoryCode: item.levelCategoryCode,
                    categoryCode: item.categoryCode
                }
            })
        },

        getCategoryName(code) {
            // console.log('进入方法', code, this.leftCategoryListData)
            this.activeCode = ''
            for (let i = 0; i < this.leftCategoryListData.length; i++) {
                var item = this.leftCategoryListData[i]
                var oneName = item.categoryName
                // 如果当前点击的为1级 直接拼接name 返回
                if (code == item.code) {
                    this.oneCode = code
                    this.activeCode = code
                    this.label = this.$t('Help.home.category') + '/' + oneName
                    return
                }
                // 遍历二级查看
                if (item.children.length > 0) {
                    var childrenList = item.children
                    for (let t = 0; t < childrenList.length; t++) {
                        var childrenItem = childrenList[t]
                        if (code == childrenItem.code) {
                            this.oneCode = item.code
                            this.twoCode = childrenItem.code
                            this.activeCode = childrenItem.code

                            this.label = this.$t('Help.home.category') + '/' + oneName + '/' + childrenItem.categoryName
                            return
                        }
                    }
                }
            }
        },

        checkCode(list) {
            var parm = this.$route.query;
            var oneCode = parm.levelCategoryCode
            var seconedCode = parm.categoryCode

            for (let i = 0; i < list.length; i++) {
                var item = list[i]
                if (item.code == oneCode) {
                    this.activeCode = item.code
                    var oneName = item.categoryName
                    this.label = this.$t('Help.home.category') + '/' + oneName

                    if (item.children && seconedCode) {
                        var children = item.children
                        for (let t = 0; t < children.length; t++) {
                            var childrenItem = children[t]
                            if (childrenItem.code == seconedCode) {
                                this.activeCode = childrenItem.code
                                this.getDetailData(this.activeCode)
                                this.label = this.$t('Help.home.category') + '/' + oneName + '/' + childrenItem.categoryName
                                return
                            }
                        }
                    } else {
                        this.getDetailData(this.activeCode)

                    }
                }
            }
        },
        getFirstCodeWith(list) {
            // console.log('进入默认选中的方法')
            var item = list[0]
            var oneName = item.categoryName
            if (item.children.length) {
                var childrenItem = item.children[0]
                this.activeCode = childrenItem.code
                this.label = this.$t('Help.home.category') + '/' + oneName + '/' + childrenItem.categoryName

            } else {
                this.label = this.$t('Help.home.category') + '/' + oneName
                this.activeCode = item.code
            }
            this.getDetailData(this.activeCode)
        }

    },
}
</script>

<style scoped lang="scss">
.qustion {
    width: 100%;
    background: #F6F6F6;

    .helpContent {
        margin-top: 40px;
    }

    .qustionBox {
        width: 1201px;
        display: flex;
        padding: 40px 0px 40px 0px;
        background: #f6f6f6;
        margin: 0 auto;

        .cagetroy {
            width: 272px;
            background: #fff;
            border-radius: 10px;
            padding: 0px 0px 5px 0px;
            box-sizing: content-box;

            .title {
                color: #333333;
                font-size: 18px;
                font-weight: 600;
                padding: 20px;
                font-weight: 600;
            }

            .line {
                background: #E6E7EB;
                height: 1px;
                width: 95%;
                margin: 0 auto;
            }

            ::v-deep .el-submenu__title i {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .cagetroyContent {
            width: 908px;
            margin-left: 21px;
            background: #fff;
            padding: 0px 32px 32px 32px;

            .contentTitle {
                height: 47px;
                padding: 16px 0px 16px 0px;
                border-bottom: 1px solid #f6f6f6
            }

            .list {
                font-size: 14px;
                color: #333333;
                margin-top: 16px;
                position: relative;
                padding-left: 10px;
                cursor: pointer;

                &::after {
                    top: 6px;
                    left: 0;
                    content: '';
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    background: #00B968;
                    border-radius: 3px;
                }
            }


        }
    }


    ::v-deep .el-menu {
        width: 272px;
        font-size: 14px;
        font-weight: 600;
        border-right: none;
    }

    ::v-deep .el-menu-item.is-active {
        background: #00B968;
        color: #fff;
        font-weight: 600;
    }

    ::v-deep .el-submenu .el-menu-item {}

    ::v-deep .el-button {
        width: 81px;
        height: 32px;
        font-size: 14px;
    }

}
</style>