<template>
  <!-- swiper -->
  <div class="Swiper_pageSwiper_page">
    <h1>{{ $t("supply.popularproducts") }}</h1>
    <div style="position: relative">
      <div class="banshi" v-loading="loading">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="item in swiperList"
              :key="item.id"
              class="swiper-slide"
              @click="OpenInfo(item)"
            >
              <img :src="item.picture" alt="" />
              <div>
                <h3>{{ item.name }}</h3>
                <!-- <span> {{ $store.state.lang.currency.symbol }}  {{ item.expectedPrice }}</span> -->
                <span>
                  <template v-if="item.priceType == 1">
                    {{ $store.state.lang.currency.symbol }}
                    {{ item.expectedPrice }}
                  </template>
                  <template v-if="item.priceType == 2">
                    {{ $store.state.lang.currency.symbol }}
                    {{ item.expectedPrice + "~" + item.exptHighPrice }}
                  </template>
                  <template v-if="item.priceType == 3">
                    {{ $t("release.requirement.negotiateDirectly") }}
                  </template>
                </span>
              </div>
            </div>
          </div>
          <template>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入

export default {
  props: {
    swiperList: {
      type: Array,
    },
  },
  watch: {
    swiperList: {
      handler(newVal, OldVal) {
        // console.log(newVal)
        // this.loading = false;
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.getSwiper();
  },
  methods: {
    getSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: true, // 无缝
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        paginationClickable: true,
        slidesPerView: 5, // 一组三个
        spaceBetween: 20, // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
      });
    },
    OpenInfo(item) {
      this.$router.push({
        path: this.$route.path,
        query: {
          productCode: item.productCode, //页面参数
          lang: this.$store.state.lang.language.value, //语言
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Swiper_pageSwiper_page {
  background: #fff;
  padding: 30px 0 20px;
  margin-top: 20px;
  border-radius: 10px;
  h1 {
    padding-left: 23px;
    color: #333333;
    font-size: 20px;
  }
}
.banshi {
  width: 1228px;
  margin: 12px auto 0;
  // padding: 70px 0;
  height: 319px;

  .swiper-slide {
    position: relative;
    height: 323px;
    width: 272px;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 230px;
    }
    div {
      padding: 12px;
      box-sizing: border-box;
      h3 {
        color: #333333;
        font-size: 14px;
        width: 100%;
        height: 37px;
        line-height: 1.3em;
        display: inline-block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      span {
        width: 100%;
        font-weight: bold;
        color: #333333;
        font-size: 20px;
        margin-top: 12px;
        display: inline-block;
        white-space:nowrap;
      overflow:hidden ;
      text-overflow:ellipsis;
      }
    }
  }
  .swiper-container {
    position: unset;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 70px;
    height: 70px;
  }
  .swiper-button-next {
    right: 13px;
  }
  .swiper-button-prev:after {
    font-size: 0;
    width: 70px;
    height: 70px;
    background: url("@/assets/images/supply/swiper1.png");
  }
  .swiper-button-next:after {
    font-size: 0;
    width: 70px;
    height: 70px;
    background: url("@/assets/images/supply/swiper2.png");
  }
}
</style>
