/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^1[2,3,4,5,6,7,8,9]\d{9}$/.test(s)
  // return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * 身份证
 * @param {*} s
 */
export function isIDBumber(s) {
  return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 整数
 * @param {*} s
 */
export function isInteger(s) {
  return Number.isInteger(Number(s))
}

/**
 * 整数 和小数
 * @param {*} s
 */
export function isIntOrDouble(s) {
  return /^[0-9]+\.?[0-9]*$/.test(s)
}

/**
 * 密码  正则验证密码 必须包含大小写字母数字,长度大于六
 * @param {*} s
 */
export function isPassword(s) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,20}$/.test(s)
}

/**
 * 不包含 数字和特殊符号
 * @param {*} s
 */
export function notHavNumAndSymbol(s) {
  console.log(s, '----')
  // console.log(/^(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/])$/.test(s))
  return /^[\u0391-\uFFE5A-Za-z]+$/.test(s)
}
/**
 * 只能 数字和特殊符号
 * @param {*} s
 */
export function isNumAndLetter(s) {
  console.log(s, '----')
  // console.log(/^(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/])$/.test(s))
  return /^[0-9a-zA-Z]+$/.test(s)
}
/**
 * 整数 和 字母
 * @param {*} s
 */
export function isIntOrLetter(s) {
  return /^[A-Za-z0-9]+$/.test(s)
}

export function inputMethod(value) {
  //console.log('value:',value)
  //密码为八位及以上并且大小写字母数字特殊字符三项都包括
  var strongRegex = new RegExp("^(?=.{6,})(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
  //密码为八位及以上并且大小写字母、数字、特殊字符三项中有两项，强度是中等
  var mediumRegex = new RegExp("^(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*\\W))|((?=.*[0-9])(?=.*\\W))|((?=.*[A-Z])(?=.*\\W))).*$", "g");
  var enoughRegex = new RegExp("(?=.{6,}).*", "g");
  var note
  var str2
  if(strongRegex.test(value)) {
    //console.log('强密码-----',value)
    str2=2
    // note=this.$t('staging.createPassword.PasswordTips')+'<i>当前密码强度：强</i>'
  } else if (mediumRegex.test(value)){
    //console.log('中等密码-----',value)
    // note=this.$t('staging.createPassword.PasswordTips')+'<i>当前密码强度：中等</i>'
    str2=1
  } else if(enoughRegex.test(value)){
    //console.log('弱密码-----',value)
    str2=0
    // note=this.$t('staging.createPassword.PasswordTips')+'<i>当前密码强度：弱</i>'
  } else {
    str2=3
    // note=this.$t('staging.createPassword.PasswordTips')
    //console.log('密码-----',value)
  }
  return {
    grade:str2,
    // content:note
  }
}
