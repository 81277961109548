<template>
  <ul class="Nav">
    <li
      :class="{ active: navActive === index }"
      v-for="(item, index) in NavList"
      :key="item.value"
      @click="handleNavClick(index)"
    >
      {{ $t(item.name) }}
    </li>
  </ul>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      NavList: (state) => state.home.NavList,
      navActive: (state) => state.home.navActive,
    }),
  },
  watch: {
    "$route.path": {
      handler: function (newVal, oldVal) {
        let index = this.NavList.findIndex((item) => {
          return item.value == '/'+newVal.split('/')[1];
          // return item.value === newVal.split('/')[1]
        });
    
        if (index === -1) {
          if (this.$route.params.nav == "Recycle") {
            index = 2;
          }
          if (this.$route.params.nav == "supply") {
            index = 1;
          }
          // index = 0
        }

        this.setNavActive(index);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("home", ["setNavActive"]),
    handleNavClick(index) {
      this.$router.push(this.NavList[index].value);
      this.setNavActive(index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.Nav {
  width: $contentWidth;
  margin: 0 auto;
  height: 42px;
  line-height: 42px;
  background: #fff;
  li {
    margin-right: 40px;
    font-size: 14px;
    color: #333333;
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    &:last-child {
      margin: 0;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      background: #fff;
    }
  }
  .active {
    color: #00b968;
    font-weight: bold;
    &::after {
      background: #00b968;
    }
  }
}
</style> 
