<template>
    <div class="labeicon">
        <h2>{{$t('home.labelIcon.Millions')}}</h2>
        <ul>
            <li 
            v-for="item in list" 
            :key='item.code'
            @click="labelCk(item)"
            >
                <div>
                <img :src="getImagePath(item.iconUrl)" alt=" " />
                </div>
                <p>{{ $getName(item)}}</p>
            </li>
        </ul>
    </div>
</template>
<script>
import { getHeadCategoryList } from "@/api/home";
export default {

    data(){
        return{
            list:[]
        }
    },
    mounted () {

        getHeadCategoryList()
            .then((res)=>{
                if(res.code=='200'){
                    this.list=res.data
                }
           
        })
    },
    methods:{
        labelCk(item){
             this.$router.push({
            path: "supply/supply",
            query: {
                select: item.code, //页面参数
                lang: this.$store.state.lang.language.value, //语言
            },
        });
            console.log(item)
        },
            //拼接图片地址
    getImagePath(filename) {
      
      var Url;
      if (filename != "") {
        Url = require("@/assets/images/home/label/"+filename );
      } 
       return Url;
    },
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
        .labeicon{
            width: $contentWidth;
            margin: 74px auto 0;
            h2{
                text-align: center;
                color: #000000;
                font-size: 28px;
            }
            ul{
               li{
                   width: 140px;
                   display: inline-block;
                   cursor: pointer;
                   margin-right: 65px;
                   margin-top: 30px;
                   div{
                       width: 100%;
                       height: 140px;
                       background: #FFFFFF;
                       border-radius: 50%;
                       display: flex;
                        align-items: center;
                        justify-content: center;
                       img{
                           width: 112px;
                           height: 112px;
                       }
                   }
                   p{
                       color: #000000;
                       font-size: 16px;
                       text-align: center;
                       margin-top: 15px;
                   }
                    

                 &:nth-child(7n){
                     margin-right: 0;
                 }  
               }
            }
        }
</style>
