<template>
  <div class="langs">
    <!-- 语言切换 -->
    <el-popover
      popper-class="langContent"
      placement="bottom"
      title=""
      width="230"
      trigger="hover"
    >
      <ul class="langlist">
        <li
          v-for="item in langList"
          :key="item.value"
          :class="item.value === language.value ? 'active' : ''"
          @click="tabs(item)"
        >
          {{ item.text }}
        </li>
      </ul>
      <div slot="reference">{{ language.text }}</div>
    </el-popover>
  </div>
</template>

<script>
import { getlanguages } from "@/api/home";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      langList: [], //语言列表
    };
  },
  computed: {
    ...mapState("lang", ["language"]),
  },
  mounted() {
    getlanguages().then((res) => {
      this.langList = this.convertObjectToArray(res.data);
    });
  },
  methods: {
    ...mapMutations("lang", ["setlanguage"]),
    tabs(item) {
      this.setlanguage(item);
      this.$i18n.locale = item.value; //切换
      // 可以在这里添加语言切换的逻辑
    },
    // 拆解接口返回的数据
    convertObjectToArray(obj) {
      const result = [];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          result.push({
            text: obj[key],
            value: key.charAt(0).toUpperCase() + key.slice(1),
          });
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss" >
.langContent {
  padding: 10px 0 !important;
  border: none !important;
  ul {
    li {
      padding-left: 16px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}
</style>

<style lang="scss" scoped>
.langs {
  display: inline-block;
  span {
    display: inline-block;
  }
}
.langlist {
  li {
    line-height: 30px;
    cursor: pointer;
    &:hover {
      background: #f4f4f4;
      color: #00b968;
    }
  }
  .active {
    background: #00b968;
    color: #ffffff;
  }
}
</style>