<template>
  <div class="list" >
    <div class="title">
      <h4>{{ $t(title) }}</h4>
      <router-link :to="url">{{ $t("home.list.More") }}</router-link>
    </div>
    <div v-loading="loading" element-loading-background="#F6F6F6">
       <ul >
      <li
        class="item"
        v-for="item in list"
        :key="item.id"
        @click="openItem(item)"
      >
        <img :src="item.picture" alt="" />
        <div>
          <span>{{ item.name }}</span>

          <h4 >
              <template v-if="item.priceType==1">
                  {{ $store.state.lang.currency.symbol }} {{ item.expectedPrice }}
              </template>
                <template v-if="item.priceType==2">
                  {{ $store.state.lang.currency.symbol }} {{ item.expectedPrice +'~'+ item.exptHighPrice }}
                </template>
                <template v-if="item.priceType==3">
                  {{$t('release.requirement.negotiateDirectly')}}
              </template>
            </h4>

     
          <p v-if="!send" class="ellipsis2">
            <img :src="$getImagePath(item.countryFlag)"  v-if="item.countryFlag"/>{{ item.localCountry }}
          </p>
          <div class="btn" @click.stop="dialogTableVisible=true,info=item" v-else>{{ $t("staging.homePage.SendInquiry") }}
          </div>
        </div>
      </li>
    </ul>
    </div>
  </div>
</template>
<script>
import { Homelatests } from "@/api/home";
import {recommend} from "@/api/webuser";
import { getToken } from '@/utils/cookie'
export default {
  props: {
    title: {
      type: String,
    },
    url: {
      type: String,
    },
    send: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      list: [],
      loading: true,
    };
  },
  mounted() {

    if (this.send){
      recommend().then((res)=>{
        if (res.code == "200") {

          this.list = res.data.data;
          this.loading = false;
        }
      })
    }else {
      Homelatests({}, this.type)
        .then((res) => {
          if (res.code == "200") {
            if(res.data.data.length>10){
                this.list = res.data.data.splice(0,10);
            }else{
               this.list = res.data.data
            }
           
            this.loading = false;
          }
        })
    }

  },
  methods: {
    handleClose() {
      this.dialogTableVisible = false;
    },
      getToken() {
      return getToken(); // 调用getToken函数获取token的值
    },
    // 点击跳转详情
    openItem(item) {
      console.log(item)

      if (!this.send) {
        this.$router.push({
          path: this.url + "/supplyDetail",
          query: {
            productCode: item.productCode, //页面参数
            lang: this.$store.state.lang.language.value, //语言
          },
        });
      }else {
        let newUrl = this.$router.resolve({
          path: (item.type=="1"?"/supply/supply":"/supply/Recycle") + "/supplyDetail",
          query: {
            productCode: item.productCode, //页面参数
            lang: this.$store.state.lang.language.value, //语言
          },
        });
        window.open(newUrl.href, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.list {
  width: $contentWidth;
  margin: 40px auto 0;
  max-height: 864px;
  display: table;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;

    h4 {
      color: #333333;
      font-size: 24px;
    }

    a {
      width: 78px;
      height: 27px;
      background: #00b968;
      border-radius: 19px;
      text-align: center;
      line-height: 27px;
      color: #ffffff;
      font-size: 14px;
    }
  }

  ul {
    display: table;
    width: 100%;
    max-height: 844px;

    li {
      width: 260px;
      margin-right: 20px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 20px;
      display: inline-block;

      &:nth-child(5n) {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: 272px;
      }

      div {
        padding: 12px;
        box-sizing: border-box;
        height: 128px;

        span {
          color: #333333;
          font-size: 14px;
          width: 100%;
          height: 37px;
          line-height: 1.3em;
          display: inline-block;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }

        h4 {
          color: #333333;
          font-size: 20px;
          margin-top: 12px;
            white-space:nowrap;
      overflow:hidden ;
      text-overflow:ellipsis; 
        }

        p {
          margin-top: 15px;
          color: #999999;
          font-size: 12px;

          img {
            width: 18px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
          }
        }

        .btn {
          background: #00b968;
          border-radius: 18px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          padding: 6px 10px;
        }
      }
    }
  }
}
</style>
