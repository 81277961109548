<template>
  <!-- 公共头部 -->
  <div class="contnet_head">
    <taBbar />
    <indexhead />
    <Nav />
  </div>
</template>
<script>
import taBbar from '@/components/tabBar'
import indexhead from "@/components/indexhead"; //logo / 搜索
import Nav from "@/components/Nav"; //导航

export default {
  components: {
    taBbar,
    indexhead,
    Nav,
    
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.contnet_head {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
 
  .headt {
    border-bottom: 1px solid #e6e7eb;
  }
}
</style>