<template>
  <div class="survey">

    <Head />
    <div class="surveyTitle">{{ title }}</div>
    <div class="surveyContent">
      <!-- <div class="title">Feijiu.com Help Center Satisfaction Survey</div> -->
      <div class="caption">{{ caption }}</div>
      <el-radio v-model="isSatisfied" @input="select" label="1">{{ $t('Help.feedBack.satisfied') }}</el-radio>
      <el-radio v-model="isSatisfied" @input="select" label="2">{{ $t('Help.feedBack.unsatisfied') }}</el-radio>

      <div class="satisBox" v-if="isSatisfied === '1'">
        <div class="caption">{{ satisTitle }}</div>
        <el-radio v-model="selectReson" label="1">{{ $t('Help.feedBack.satis1') }}</el-radio>
        <el-radio v-model="selectReson" label="2">{{ $t('Help.feedBack.satis2') }}</el-radio>
        <el-radio v-model="selectReson" label="3">{{ $t('Help.feedBack.satis3') }}</el-radio>
        <div>
          <el-radio v-model="selectReson" label="4">{{ $t('Help.feedBack.satis4') }}</el-radio>
          <input type="text" v-model="otherReasons">
        </div>
      </div>

      <div class="satisBox" v-if="isSatisfied === '2'">
        <div class="caption">{{ unsatisfiedTitle }}</div>
        <el-radio v-model="selectReson" label="1">{{ $t('Help.feedBack.unSatis1') }}</el-radio>
        <el-radio v-model="selectReson" label="2">{{ $t('Help.feedBack.unSatis2') }}</el-radio>
        <el-radio v-model="selectReson" label="3">{{ $t('Help.feedBack.unSatis3') }}</el-radio>
        <el-radio v-model="selectReson" label="4">{{ $t('Help.feedBack.unSatis4') }}</el-radio>

      </div>

      <div class="caption">{{ cue }}</div>
      <div class="inputBox">
        <el-input class="input" v-model="contentWord" type="textarea" maxlength="1000"  show-word-limit
          placeholder=" Please input your answer here"></el-input>
      </div>

      <div class="submit">
        <el-button type="primary" style="font-weight: 600;" @click="submit">{{ $t('Help.submit.submit') }}</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import Head from '../components/head.vue'
import { surveyApi } from '@/api/help'
export default {
  components: {
    Head
  },
  metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: 'Feijiu.com Feedback',
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.helpCenter.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.helpCenter.description"),
                    },
                ],
            };
        },
    },
  data() {
    return {
      title: 'Feijiu.com ' + this.$t('Help.feedBack.feedBack'),
      caption: '1.' + this.$t('Help.feedBack.title'),
      satisTitle: '2.' + this.$t('Help.feedBack.satisTitle'),
      unsatisfiedTitle: '2.' + this.$t('Help.feedBack.unsatisfiedTitle'),
      cue: '2.' + this.$t('Help.feedBack.cue'),
      isSatisfied: '',
      selectReson: '',
      contentWord: '',
      otherReasons: ''
    }
  },
  
  /*
  组件创建后
  经常在它里面 调用methods中的方法 请求服务器中的数据 并且把请求到的数据 转存到data中 供template模板渲染使用
  */
  created() {
  },
  /*
  挂载后
  如果要操作当前组件的dom 最早 只能在mounted阶段执行
  */
  mounted() {

  },

  methods: {
    select(e) {
      this.cue = '3.' + this.$t('Help.feedBack.cue')
    },
    //意见反馈提交
    submit() {
      if (this.isSatisfied) {
        var that = this
        surveyApi({
          satisfactionLevel: this.isSatisfied,
          reasonsForSatisfaction: this.selectReson,
          contentWord: this.contentWord,
          otherReasons: this.otherReasons,
          webUserId: '0'
        }).then((res) => {
          if (res.code == '200') {
            that.contentWord = ''
            that.$message({
              message: that.$t('Help.feedBack.feedSuccess'),
              type: "success",
            });
          }
        })
      } else {
        this.$message({
          message: 'Please select',
          type: "warning",
        });
      }

    },
  },
}
</script>

<style scoped lang="scss">
.survey {
  width: 100%;
  background: #F6F6F6;

  .surveyTitle {
    font-size: 30px;
    width: 100%;
    text-align: center;
    height: 110px;
    line-height: 110px;
    color: #000000;
    font-weight: 600;
  }

  .surveyContent {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    display: flex;
    //纵向排列
    flex-direction: column;
    padding: 40px 30px 40px 30px;

    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
    }

    .caption {
      margin-top: 22px;
      color: #333333;
      font-size: 16px;
    }

    .satisBox {
      display: flex;

      flex-direction: column;

    }

    .inputBox {
      margin-top: 30px;
      background: #F6F6F6;
      width: 100%;
      border-radius:10px ;
      .input {
        margin-top: 10px;
        height: 229px;
        border-radius: 10px;

        ::v-deep .el-textarea__inner {
          height: 229px;
          background: #F6F6F6;
          border: none;

        }
      }

    }

    .submit {
      width: 100%;
      height: 48px;
      text-align: right;
      margin-top: 50px;

    }

  }

  ::v-deep .el-radio {
    height: 37px;
    line-height: 37px;
  }

  ::v-deep .el-button {
    width: 310px;
    height: 48px;
    background: #00B968;
    color: #fff;

  }
}</style>