<template >
  <div :style="{zoom:zoom}">
    <div id="app" >
      <el-header>
        <img style="cursor: pointer;" width="107" height="32" src="@/assets/images/logo.png" alt="logo" class="logo" @click="$router.push('/')"/>
        <div style="display: flex">
          <div style="cursor: pointer;width: 120px" class="topText" v-if="false">{{ $t("staging.IM") }}</div>
          <div class="topText" style="cursor: pointer;width: 120px" @click="$router.push({ path:'/helpCenter/survey'})">{{ $t("staging.Feedback") }}</div>
          <div class="topText" style="width: 120px; cursor: pointer;">
            <language/>
          </div>
          <div class="topText" style="display: flex;align-items: center">
            <div class="circle-image">
              <img :src="info&&info.iconUrl?info.iconUrl:require('@/assets/images/staging/staging_head.png')"
                   alt="Circle Image">
            </div>
            <div>
              hello! {{ info ? info.userName : "" }}
            </div>

          </div>
          <div style="cursor: pointer;color: #00B968;width: 120px;text-align: center; font-size: 18px;
  font-family: PingFangSC, PingFang SC;font-weight: 400;" @click="logout">
            {{ $t('home.top.Signout') }}
          </div>
        </div>
      </el-header>
      <el-container :style="{height:height}">
        <el-aside width="250px" :style="{height: height}">
          <div style="background-color: #FFFFFF;height: 100%;padding-top: 25px">
            <div v-for="(item,index) in routesList" @click="selectLeft(item,index)"
                 style="display: flex;align-items: center;cursor: pointer; ">
              <div style="width: 9px;height: 53px;background-color: #00B968" v-if="index==selected"></div>
              <div style="display: flex;align-items: center;margin-top: 10px;margin-bottom: 10px" :style="{marginLeft:index==selected?'32px':'41px'}">

                <img width="22" height="22" :src="index==selected?require('@/assets/images/staging/'+item.meta.icon+'1.png')
            :require('@/assets/images/staging/'+item.meta.icon+'.png')">
                <div style="margin-left: 10px;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 18px;" :style="{color:selected==index?'#00B968':'#000000'}">
                  {{ $t("staging." + item.meta.title) }}
                </div>
              </div>
            </div>
          </div>

        </el-aside>
        <el-main :style="{height: height}">
          <!-- 二级路的出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>

</template>

<script>
import language from "@/components/language.vue";
import {getUserInfo} from "@/api/webuser";
import {removeToken, getToken, addLocalMessage, getMessage} from "@/utils/cookie";


export default {
  name: "index",
  components: {
    language,
  },
  data() {
    return {
      selected: 0,
      routesList: [],
      info: null,
      zoom:'100%',
      height:"",
    }
  },
  watch: {
    "$route.path": {
      handler(newVal, oldVal) {
        console.log("sadasdasdasd", newVal)
        this.routesList.forEach((item, index) => {
          if (item.path == newVal) {
            this.selected = index
          }
        })
      }
    }
  },
  created() {
    this.$router.options.routes.forEach((v, index) => {
      if (v.meta && v.meta.isStaging) {
        this.routesList.push(v)
      }
    })
    this.routesList.forEach((item, index) => {
      if (item.path == this.$route.path) {
        this.selected = index
      }
    })
    this.getInfo()
  },
  mounted() {
    // this.zoom=window.innerWidth/1920*100+'%'
    // this.height=(window.innerHeight/(window.innerWidth/1920))+'px'
  },
  methods: {

    logout() {
      removeToken()
      this.$router.push("/")
    },
    getInfo() {
      getUserInfo().then((res) => {
        this.info = res.data
        addLocalMessage({'email':res.data.email})
      })
      console.log(getMessage())
    },
    selectLeft(row, i) {
      this.selected = i
      this.$router.push({
        path: row.path
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
#app {
  min-width: $contentWidth;
  width: 100%;
  min-height: 100vh;
  background: #F0F1F2;
}
.el-header {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.el-aside {
  text-align: center;
  line-height: 60px;
  margin: 0px 10px 0px 0px;
  padding: 70px 0px 0px 0px;
  font-size: 18px;
  min-height: 100vh;

}

.el-main {
  margin: 0px 0px 0px 0px;
  padding: 70px 0px 0px 0px;
  min-height: 100vh;

}

.circle-image {
  width: 38px; /* 根据需求调整大小 */
  height: 38px; /* 根据需求调整大小 */
  margin-right: 10px;
}

.circle-image img {
  border-radius: 50%; /* 将边角变成半径相等的圆形 */
  object-fit: cover; /* 保持图像比例不失真地裁剪到容器内 */
  width: 100%; /* 确保图片铺满容器 */
  height: 100%; /* 确保图片铺满容器 */
}
.topText{
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #000000;
}
</style>
