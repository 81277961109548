<template>
    <div class="helpCenterContent">
    <Head />
    <div style="background:#fff; border-top:1px solid #e6e7eb">
        <Nav />
    </div>
    
    <div class="imgBox">
         <div style="width: 100%;" class="bgIcon">
            <img class="img" src="@/assets/images/helpCenter/bg.jpg" alt="">
        </div>
        <div class="search">
            <HelpSearch />
        </div>
    </div>
    <Learning />
    <HotTopics />
    </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
// 顶部用户名组件
import Head from './components/head.vue'
// 搜索框
import HelpSearch from './components/helpSearch.vue'
//
import Learning from './components/learning.vue'

import HotTopics from './components/hotTopics.vue'

export default {
    components: {
        Nav,
        Head,
        HelpSearch,
        Learning,
        HotTopics
    },
    
    metaInfo() {
        //  动态meta
        return this.dynamicMetaInfo;
    },
    computed: {
        dynamicMetaInfo() {
            return {
                title: this.$t("tdk.helpCenter.title"),
                meta: [
                    {
                        name: "keyWords",
                        content: this.$t("tdk.helpCenter.keywords"),
                    },
                    {
                        name: "description",
                        content: this.$t("tdk.helpCenter.description"),
                    },
                ],
            };
        },
  
  },
    data() {
        return {

        }
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
      
    }
}

</script>

<style lang="scss" scoped>
.helpCenterContent {
    width: 100%;

    background: #F6F6F6;
        
    .imgBox {
        width: 100%;
        // background: url('@/assets/images/helpCenter/bg.jpg')no-repeat center 100%;
        // background-size: 100% 100%;
        position:relative;

        display: flex;
        align-items: center;
        justify-content: center;
        .bgIcon {
            .img {
            width: 100%;
        }
        }
        .search{
            position: absolute;
        }
       
    }
   

}
</style>
