<template>
  <!-- 选择国家 -->
  <div>
    <el-popover
      ref="popover"
      popper-class="nationContent"
      placement="bottom"
      title=""
      width="360"
      :visible.sync="visible"
      @click.native.stop=""
    >
      <p class="title">Supply or procuremen</p>
      <el-select
        v-model="selectedNation"
        class="nation_select"
        placeholder="请选择"
        filterable
        @blur="handleBlur"
      >
        <el-option
          v-for="(item, index) in JSONData"
          :key="index"
          :label="$getName(item)"
          :value="item.code"
          :icon="$getImagePath(item.flagUrl)"
        >
          <img
            :src="$getImagePath(item.flagUrl)"
            alt=""
            style="
              display: inline-block;
              vertical-align: middle;
              margin-right: 3px;
              width: 27px;
            "
          />
          <span>{{ $getName(item) }}</span>
        </el-option>
      </el-select>
     
      <el-button type="success" class="Save" @click="save">{{
        $t("staging.createPassword.Save")
      }}</el-button>
      <div slot="reference" style="display: flex">
        <img
          :src="$getImagePath(nation.flagUrl)"
          alt=""
          style="width: 23px; height: 16px; margin-right: 5px"
        />{{ $getName(nation) }}
      </div>
    </el-popover>
  </div>
</template>
<script>
import { getAllCountries } from "@/api/home";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      selectedNation: 'USA', //初始化美国
      JSONData: [],
      visible: false,
    };
  },
  computed: {
    ...mapState("lang", ["nation", "language"]),

    formattedNation() {

        return this.nation.code == 'USA' ? 'USA' : this.nation.code;
      },
  },
  created(){
    this.selectedNation=this.nation.code == 'USA' ? 'USA' : this.nation.code
  },
  mounted() {
    this.selectData();
  },

  methods: {
    ...mapMutations("lang", ["setnation"]),
    handleBlur() {
      this.visible = true;
    },
    // 点击
    save() {
      this.visible = false;
      const selectedCountry = this.JSONData.find(
        (item) => item.code === this.selectedNation
      ); // console.log(selectedCountry)
      this.$refs.popover.doClose();
      this.setnation(selectedCountry);
    },
    // 国家数据
    selectData() {
      getAllCountries().then((res) => {
        this.JSONData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nation_select {
  width: 100%;
}
.title {
  margin-bottom: 10px;
  color: #333333;
  font-size: 14px;
}
.Save {
  width: 100%;
  height: 38px;
  background: #00b968;
  border-radius: 19px;
  margin-top: 10px;

  &:hover {
    background: #00b968;
  }
}
</style>