<template>
    <!-- 输入框内容 -->
    <div class="accountContent">
        <div class="bgcolor"></div>

        <div class="content-box">
            <div class="title">{{ $t('Register.creatAccount') }}</div>

            <div class="account">{{ $t('Register.yourName') }}</div>
            <el-input v-model="form.nickName" :placeholder="$t('Register.nameTips')"></el-input>

            <div class="account">{{ $t('Login.email') }}</div>
            <el-input v-model="form.email"   maxlength="64" :placeholder="$t('Login.email')"></el-input>

            <div class="account">{{ $t('Login.password') }}</div>
            <el-input v-model="form.password"  :type="showPassword1 ? 'password' : 'text'"  :placeholder="$t('Register.passwordTips')">
                <img slot="suffix" class="el-input__icon" :src="showPassword1 ? require('@/assets/images/login/eye.png')
                        : require('@/assets/images/login/eye1.png')"
                        style="width: 24px;height: 24px; margin-right: 10px;margin-top: 15px;cursor: pointer;"
                        @click="handleToggleShowPwd1" ></img>
            </el-input>
            <div class="account">{{ $t('Register.passwordTry') }}</div>

            <el-input v-model="form.passwordTwc" :type="showPassword2 ? 'password' : 'text'" :placeholder="$t('Register.passwordTry')"
                style="padding-right: 30px;">
                <img slot="suffix" class="el-input__icon" :src="showPassword2 ? require('@/assets/images/login/eye.png')
                        : require('@/assets/images/login/eye1.png')"
                        style="width: 24px;height: 24px; margin-right: 10px;margin-top: 15px; cursor: pointer;"
                        @click="handleToggleShowPwd2"></img>
            </el-input>
            <el-button type="primary" class="continueBtn" round @click="nextClick">{{ $t('Register.verifyEmail')
            }}</el-button>
            <div class="notice">
                <span>{{ $t('Login.cue1') }}</span>
                <span style="color: #005CFF; cursor: pointer;"  @click="tiaojianClick"> {{ $t('Login.cue2') }} </span>
                <span>{{ $t('Login.cue3') }}</span>
                <span style="color: #005CFF; cursor: pointer;" @click="xieyiClick" > {{ $t('Login.cue4') }}</span>
            </div>

        </div>

    </div>
</template>
<script>


export default {
    components: {

    },
    data() {
        return {
            form: {
                email: '',
                nickName: '',
                password: '',
                passwordTwc: ''
            },
            showPassword1: true,
            showPassword2: true
        }
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
        nextClick() {
            console.log('提交的数据', this.form)
            if (this.form.email.length == 0 ) {
                this.$message({
                    message: this.$t('LoginTips.emailTips'),
                    type: "warning",
                });
            } else if(!this.form.email.includes('@')){
                 this.$message({
                    message:'Please check your email',
                    type: "warning",
                });
            }else if (this.form.nickName.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.nameTips'),
                    type: "warning",
                });
            } else if (this.form.password.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.passwordTips'),
                    type: "warning",
                });
            } else if (this.form.passwordTwc.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.passwordAgain'),
                    type: "warning",
                });
            } else if (this.form.passwordTwc != this.form.password) {
                this.$message({
                    message: this.$t('LoginTips.passwordWrong'),
                    type: "warning",
                });
            } else if (this.form.passwordTwc.length < 6 || this.form.password.length < 6) {
                this.$message({
                    message: this.$t('Register.passwordTips'),
                    type: "warning",
                });
            } else {

                this.$emit('next', this.form)
            }
        },
            // 使用条件
            tiaojianClick() {
                let page = this.$router.resolve({
                path: '/terms/termsofUse',
            })
            window.open(page.href, "_blank");        },
        // 隐私协议
        xieyiClick(){
            let page = this.$router.resolve({
                path: '/terms/privacypolicy',
            })
            window.open(page.href, "_blank");
        },
        handleToggleShowPwd1() {
            this.showPassword1 = !this.showPassword1;
        },
        handleToggleShowPwd2() {
            this.showPassword2 = !this.showPassword2;
        }
    }
}

</script>

<style lang="scss" scoped>
::v-deep .el-input__suffix {
    // background-color: red;
    margin-right: 35px;
    margin-top: 5px;
}

.accountContent {
    width: 470px;
    margin-top: 31px;
    border-radius: 10px;
    height: 630px;
    border: 1px solid #DDDDDD;
    margin-bottom: 181px;
    box-sizing: content-box;
    position: relative;

    .bgcolor {
        background: linear-gradient(to bottom left, #E7FFF5 0%, white 100%);
        width: 470px;
        height: 150px;
        border-radius: 8px;
        z-index: 0;
        position: absolute;

    }

    .content-box {
        position: absolute;
        top: 0px;
        width: 470px;
        height: 630px;
        z-index: 10;

        .title {
            font-size: 28px;
            font-family: Arial Black;
            font-weight: 600;
            color: #000000;
            line-height: 32px;
            margin-top: 30px;
            margin-left: 40px;
        }

        .account {
            font-size: 14px;
            font-family: Arial Black;
            font-weight: 600;
            color: #333333;
            line-height: 16px;
            margin-top: 30px;
            margin-left: 40px;
        }


        .continueBtn {
            width: 390px;
            height: 48px;
            margin-left: 40px;
            margin-top: 30px;
            background: #00B968;
        }

        .notice {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            width: 390px;
            margin-left: 40px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }


}

// 做输入框穿透效果 设置输入框frame
::v-deep .el-input__inner {
    width: 390px;
    height: 48px;
    margin-left: 40px;
    margin-top: 10px;

}

</style>
