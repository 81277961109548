// 引入加密包
import Crypto from 'crypto-js'
// 偏移量，转换成utf8格式字符串，一般长度是16位(由后端提供)
const iv = Crypto.enc.Utf8.parse('0311871066668631')
 
// 解密（使用CBC模式）
export function Decrtpt(value,aesKey) {
  // 秘钥,转换成utf8格式字符串，用于加密解密，一般长度是16位（由后端提供）
  const key = Crypto.enc.Utf8.parse(aesKey)
  // 使用外部包中的AES的解密方法
	// value(解密内容)、key(密钥)
  let decrypt = Crypto.AES.decrypt(value, key, {
    iv,							// 偏移量
    mode: Crypto.mode.CBC,		// 模式（五种加密模式，各有优缺）
    padding: Crypto.pad.Pkcs7	// 填充
  })
  // 转成utf8格式字符串，并返回出去
  let decryptedStr = decrypt.toString(Crypto.enc.Utf8)
  return decryptedStr
}
 
//加密（使用CBC模式）
export function Encrypt(value,aesKey) {
  // 秘钥,转换成utf8格式字符串，用于加密解密，一般长度是16位（由后端提供）
  const key = Crypto.enc.Utf8.parse(aesKey)
  // 使用外部包中的AES的加密方法
  // value(加密内容)、key(密钥)
  let encrypt = Crypto.AES.encrypt(value, key, {
    iv,							// 偏移量
    mode: Crypto.mode.CBC,		// 模式（五种加密模式）
    padding: Crypto.pad.Pkcs7	// 填充
  })
  // 将加密的内容转成字符串返回出去
  return encrypt.toString()
}