<template>
    <div class="forgetPassword">
        <img class="logo" src="@/assets/images/login/logo.png" alt="">
        <PasswordStep1 v-show="this.$route.path === '/login/forgetPassword/email'" @oneClick="oneClick" />
        <PasswordStep2 v-show="this.$route.path === '/login/forgetPassword/verify'" @twoClick="twoClick"
            @sendCode="sendCode" />
        <PasswordStep3 v-show="this.$route.path === '/login/forgetPassword/creat'" @threeClick="threeClick" />
        <!-- 底部文案 -->
        <div class="bottom-box">@2023, Feijiu.com. All
            rights reservea.</div>
    </div>
</template>
<script>
// 页面组件
import PasswordStep1 from './components/passwordStep1.vue'
import PasswordStep2 from './components/passwordStep2.vue'
import PasswordStep3 from './components/passwordStep3.vue'

// 接口
import { forgetPasswordSendCodeApi, verifyEmailCodeApi, resetPwdApi } from '@/api/login'
import { Loading } from 'element-ui';
import {inputMethod} from "@/utils/validate";

export default {
    components: {
        PasswordStep1, PasswordStep2, PasswordStep3
    },
    data() {
        return {
            email: '',
            options: {
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            }
        }
    },

    mounted() {
        // 判断当前路由是否
        if (this.$route.path != '/login/forgetPassword/creat' && this.$route.path != '/login/forgetPassword/verify' && this.$route.path != '/login/forgetPassword/email') {
            this.$router.push('/')
        }

    },

    beforeUpdate() {

    },
    destroyed() {
    },
    methods: {
        // 发送邮件
        oneClick(email) {
            // 增加loading
            Loading.service(
                this.options
            );
            this.email = email
            let loadingInstance = Loading.service(this.options);

            forgetPasswordSendCodeApi({
                'email': email,
            }).then((res) => {
                loadingInstance.close();
                if (res.code == '200') {
                    // this.$router.push('/login/forgetPassword/verify')
                    this.$router.push({
                        path: "/login/forgetPassword/verify",
                        query: {
                            email: email //页面参数
                        },
                    });
                }
            })
        },
        sendCode() {
            forgetPasswordSendCodeApi({
                'email': this.$route.query.email,
            }).then((res) => {
                if (res.code == '200') {
                    this.$message({
                        message: this.$t('LoginTips.msmCodeSuccess'),
                        type: "success",
                    });
                }
            })
        },
        // 验证 验证码是否正确
        twoClick(msmCode) {
            Loading.service(
                this.options
            );
            let loadingInstance = Loading.service(this.options);

            verifyEmailCodeApi({
                'email': this.$route.query.email,
                'captcha': msmCode,
                'isResetPwd': true
            }).then((res) => {
                loadingInstance.close();
                if (res.code == '200') {
                    // this.$router.push('/login/forgetPassword/creat')
                    this.$router.push({
                        path: "/login/forgetPassword/creat",
                        query: {
                            email: this.$route.query.email //页面参数
                        },
                    });
                }
            })
        },
        threeClick(form) {
            Loading.service(
                this.options
            );
            let loadingInstance = Loading.service(this.options);

            var that = this
            resetPwdApi({
                'account': this.$route.query.email,
                'pwd': form.pwd,
                'pwdTwc': form.pwdTwc,
                'strength':inputMethod(form.pwdTwc).grade

            }).then((res) => {
                loadingInstance.close();
                if (res.code == '200') {
                    // console.log('修改成功去登陆')

                    this.$message({
                        message: this.$t('LoginTips.passwordSuccess'),
                        type: "success",
                    });
                    that.$router.push('/login/signIn')
                }
                // else {
                //     that.$message(res.msg)
                // }


            })
        }

    }
}

</script>
 
<style lang="scss" scoped>
.forgetPassword {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    //纵向排列
    flex-direction: column;
    //居中
    align-items: center;

    //logo
    .logo {
        margin-top: 160px;
        width: 152px;
        height: 44px;
    }

    //底部文案
    .bottom-box {
        width: 100%;
        height: 75px;
        line-height: 75px;
        text-align: center;
        background: #F4F4F4;
        color: #666666;
    }

}
</style>
