import request from '@/utils/request'

// 热门新闻列表
export function getNewsTopList(data) {
  return request({
    url: '/v1/web/api/news/newsTopList',
    method: 'post',
    data: data
  })
}
//新闻列表页面
export function getNewsList(data) {
  return request({
    url: '/v1/web/api/news/newsList',
    method: 'post',
    data: data
  })
}
//新闻分类列表
export function getNewsCategoryList(data) {
  return request({
    url: '/v1/web/api/news/newsCategoryList',
    method: 'post',
    data: data
  })
}
//新闻详情
export function getNewsDetail(data) {
  return request({
    url: '/v1/web/api/news/newsDetail',
    method: 'post',
    data: data
  })
}

// 展会
export function getExhibitionList(data) {
  return request({
    url: '/v1/web/api/exhibition/exhibitionList',
    method: 'post',
    data: data
  })
}
// 展会详情
export function getExhibitionDetail(data) {
  return request({
    url: '/v1/web/api/exhibition/exhibitionDetail',
    method: 'post',
    data: data
  })
}
// 展会推荐列表
export function getExhibitionTopList(data) {
  return request({
    url: '/v1/web/api/exhibition/exhibitionTopList',
    method: 'post',
    data: data
  })
}
