import Vue from "vue"
import VueI18n from 'vue-i18n';
import store from '@/store'
//引入自定义语言配置
import Zh from './lang/Zh'
import En from './lang/En'
import De from './lang/De'
import Es from './lang/Es'
import Fr from './lang/Fr'
import Por from './lang/Por'
import Ru from './lang/Ru'
import Tr from './lang/Tr'


//引入UI框架语言配置---elementui
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import deLocale from 'element-ui/lib/locale/lang/de'
import esLocale from 'element-ui/lib/locale/lang/es'
import frLocale from 'element-ui/lib/locale/lang/fr'
import porLocale from 'element-ui/lib/locale/lang/pt-br'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import trLocale from 'element-ui/lib/locale/lang/tr-TR'


ElementLocale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
Vue.use(VueI18n); // 全局注册国际化包

let i18n = new VueI18n({
  locale:store.state.lang.language.value|| 'Zh',
  messages: {
    'Zh': {
      ...Zh,
      ...zhLocale
    },
    'En': {
      ...En,
      ...enLocale
    },
    'De': {
      ...De,
      ...deLocale
    },
    'Es': {
      ...Es,
      ...esLocale
    },
    'Fr': {
      ...Fr,
      ...frLocale
    },
    'Pt': {
      ...Por,
      ...porLocale
    },
    'Ru': {
      ...Ru,
      ...ruLocale
    },
    'Tr': {
      ...Tr,
      ...trLocale
    }
  },
  silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
  globalInjection: true, // 全局注入
  fallbackLocale: 'Zh', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文

})

export default i18n;
