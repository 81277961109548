<template>
  <div class="cagetory">
    <div class="cell-box" v-for="(item, index) in list" :key="index">
      <button class="button" :class="{active:item.categoryCode === selectItem}" @click="buttonClick(item)">{{item.categoryName+'('+ item.num+')'}}</button>
    </div>
  </div>
</template>
<script>


import {getNewsCategoryList} from "@/api/news";

export default {
  components: {},
  data() {
    return {
      list: [],
      selectItem:''
    }
  },
  mounted() {
    this.getData()
  },
  destroyed() {
  },
  methods: {
    getData() {
      getNewsCategoryList().then((res) => {
        this.list = res.data.list;
      })
    },
    buttonClick(res) {
      this.selectItem = res.categoryCode
      this.$emit('categoryClick', res.categoryCode);
    }
  }
}

</script>

<style lang="scss" scoped>
.cagetory {
  width: 472px;
  padding: 0px 10px 10px 20px;
  background: #F6F6F6;
  display: flex;
  flex-wrap: wrap;

  .button {
    width: 211px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #00B968;
    color: #00B968;
    cursor: pointer;
  }

  .active {
    color: #fff;
    background: #00B968;
  }
}
</style>
