<template>
  <!-- 面包屑 -->
  <div class="breadcrumb-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in pathArr" :key="index" :to="item.path ? { path: item.path } : null">

        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pathArr: [],
    };
  },
  computed: {},

  methods: {},
  watch: {
    "$route.path": {
      handler: function (newVal, oldVal) {
        var path = newVal;
        var pathList = path.split("/");

        // 去除空串
        pathList = pathList.filter(function (e) {
          return e;
        });
        var pathArray = [];
        var pathStr = "";
        var cPath = []; //未经处理的数据

        if (pathList[1] == "Recycle" || pathList[1] == "supply") {
          //供求数据修改
          cPath = pathList;
          pathList = [pathList[1], pathList[pathList.length - 1]];
        }

        for (let index = 0; index < pathList.length; index++) {

          var element = pathList[index];
          var capitalizedFirst = element[0].toUpperCase();
          var rest = element.slice(1).toLowerCase();

          if (pathList[0] == "Recycle" || pathList[0] == "supply") {
            // 供求详情面包屑处理

            pathStr = '/' + cPath[0] + "/" + cPath[1];

            if (element == "supplyDetail") {
              pathStr = "";
            }
          } else {

            pathStr = pathStr + "/" + element;
            //最后 一级不做路径跳转
            if (index + 1 == pathList.length) {
              pathStr = ''
            }
          }

          // 拼接跳转路径
          // pathStr = pathStr + '/' + element
          // 将拼接之后的path 和 展示 name 放到字典中
          var dic = { path: pathStr, name: capitalizedFirst + rest };
          pathArray.push(dic);
        }
        this.pathArr = pathArray;
        // console.log('路径拼接好的参数及值',this.pathArr)
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.breadcrumb-box {
  width: $contentWidth;
  margin: 0 auto;
  height: 24px;
  padding: 0px 0px 10px 0px;
}
</style> 
