<template>
  <div>
    <breadCrumbs />
    <!-- failurestate -->
    <div :class="failurestate ? '  page-content' : 'page-content'">
      <div class="content_t">
        <div class="content_t_c">
          <div class="content_t_c_l">
            <!-- 主图 -->
            <magnifier-zoom :imgList="info.images" />
            <!-- 分享 -->
            <div class="share_content">
              <div class="Favorites" @click="collect">
                <i
                  :class="
                    FavoritesActive
                      ? 'iconfont icon-xiai'
                      : ' iconfont icon-hear'
                  "
                ></i>
                {{ $t("supply.Favorites") }}
              </div>
              <div class="Share">
                <i class="el-icon-share"></i>
                {{ $t("supply.Share") }}
                <ul>
                  <li @click="shareFacebook">
                    <img src="@/assets/images/supply/share1.png" alt="" />
                  </li>
                  <li @click="shareTwitter">
                    <img src="@/assets/images/supply/share2.png" alt="" />
                  </li>
                  <li @click="shareInstagram">
                    <img src="@/assets/images/supply/share3.png" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content_t_c_r">
            <h2>
              {{ info.name }}
            </h2>
            <div class="Price" text="Ended">
              <p>{{ $t("supply.price") }}</p>

              <span>
                <template v-if="info.priceType == 1">
                  {{ $store.state.lang.currency.symbol }}
                  {{ info.expectedPrice }}
                </template>
                <template v-if="info.priceType == 2">
                  {{ $store.state.lang.currency.symbol }}
                  {{ info.expectedPrice + "~" + info.exptHighPrice }}
                </template>
                <template v-if="info.priceType == 3">
                  {{ $t("release.requirement.negotiateDirectly") }}
                </template>
              </span>
            </div>

            <div class="specifications">
              <p>
                <span>{{ $t("supply.Totalvolume") }}</span>
                <span v-if="info.unit"
                  >{{ info.number }} {{ info.unit.value }}</span
                >
              </p>
            </div>
            <!-- <div class="specifications">
              <p>
                <span>{{ $t("supply.Description") }}</span>
                <span>{{ info.description }}</span>
              </p>
            </div> -->
            <div class="specifications">
              <p>
                <template v-if="$route.params.nav == 'Recycle'">
                  <span>{{ $t("supply.Location") }}</span>
                </template>
                <template v-else>
                  <span>{{ $t("supply.supplyLocation") }}</span>
                </template>

                <span v-if="info && info.localCountry">
                  <!-- <img
                  style="margin-right:5px"
                    v-if="info.localCountry.code"
                    :src="$getImagePath(info.localCountry.code + '.jpg')"
                    alt=""
                  /> -->
                  {{ info.localCountry.value }}
                </span>
              </p>
              <p v-if="$route.params.nav != 'Recycle'">
                <span>{{ $t("supply.Regions") }}</span>
                <span>
                  <em
                    style="font-style: normal"
                    v-for="i in info.supplyAreas"
                    :key="i.code"
                  >
                    {{ i.value }}</em
                  >
                </span>
              </p>
              <p>
                <!-- 供应 -->
                <template v-if="$route.params.nav != 'Recycle'">
                  <span>{{ $t("supply.Method1") }}</span>
                  <span>{{
                    info.deliveryMethod != 3
                      ? $t(Method1[info.deliveryMethod])
                      : $t(Method1[1]) + " & " + $t(Method1[2])
                  }}</span>
                </template>
                <!-- 求购 -->
                <template v-else>
                  <span>{{ $t("supply.Method1") }}</span>
                  <span>{{
                    info.deliveryMethod != 3
                      ? $t(Method[info.deliveryMethod])
                      : $t(Method[1]) + " & " + $t(Method[2])
                  }}</span>
                </template>
              </p>
              <p v-if="$route.params.nav == 'Recycle'">
                <span>{{ $t("supply.Supplier") }}</span>
                <span>{{ info.userName }}</span>
              </p>
              <p v-else>
                <span>{{ $t("supply.Recycler") }}</span>
                <span>{{ info.userName }}</span>
              </p>
            </div>
            <div class="Contact_btn">
              <el-button @click="Supplier">{{
                $t("supply.ContactSupplier")
              }}</el-button>
              <!-- <el-button @click="chat">{{ $t("supply.ChatNow") }}</el-button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="content_b">
       
          <!-- 描述 -->
          <div class="describe">
            <h2>{{ $t("supply.Description") }}</h2>
            <div v-html="info.description" class="rich"></div>
          </div>

           <template v-if="swiperList">
          <SwiperList :swiperList="swiperList" />
          </template>

        <div class="Publish">
          <router-link to="/publish/publishSummary">{{
            $t("release.requirement.publish").toUpperCase()
          }}</router-link>
        </div>
      </div>
    </div>

    <Message
      :Show="dialogTableVisible"
      @handleClose="handleClose"
      :infoList="info"
    />

    <!-- 右侧快捷按钮 -->
    <div class="fast_btn" v-if="rightNav">
      <ul>
        <li @click="Supplier">
          <i class="iconfont icon-xin"></i>
          <span> Contact Supplier</span>
        </li>
        <!-- <li @click="chat">
          <i class="iconfont icon-liaotian"></i>
          <span> Chat now</span>
        </li> -->
        <li @click="openNewPage('/helpCenter/survey')">
          <i class="el-icon-edit"></i>
          <span> Feed back</span>
        </li>
      </ul>
      <ul>
        <li @click="scrollToTop">
          <i class="el-icon-arrow-up"></i>
          <span>TOP</span>
        </li>
      </ul>
    </div>

    <ul class="right_message">
      <li @click="$router.push('/staging/inquiry')">
        <div class="">
          <i class="iconfont">&#xe80d;</i>
          <span>Inquiries</span>
        </div>
        <i class="el-icon-arrow-right"></i>
      </li>
      <!-- <li>
        <div class="" @click="chat">
          <i class="el-icon-chat-line-square"></i>
          <span>Messenger</span>
        </div>
        <i class="el-icon-arrow-right"></i>
      </li> -->
    </ul>
  </div>
</template>

<script>
import breadCrumbs from "@/components/breadCrumbs";
import MagnifierZoom from "./components/magnifier-zoom";
import SwiperList from "./components/SwiperList";
import Message from "./components/message";
import { getToken } from "@/utils/cookie";
import {
  gedetail,
  gedetailList,
  getFavoriteState,
  sendFavorite,
  sendBrownse,
  sendShare,
} from "@/api/supply";
export default {
  metaInfo() {
    //  动态meta
    return this.dynamicMetaInfo;
  },
  computed: {
    dynamicMetaInfo() {
      let title = ""; // 初始化 title 变量
      if(this.$route.params.nav=='supply'){
        title = this.$t("tdk.supply.detail.title");
      }else{
          title = this.$t("tdk.supply.detail.title1");
      }
      return {
        title,
        meta: [
          {
            name: "keyWords",
            content: this.$t("tdk.supply.detail.keywords"),
          },
          {
            name: "description",
            content: this.$t("tdk.supply.detail.description"),
          },
        ],
      };
    },
  },

  components: {
    breadCrumbs,
    MagnifierZoom,
    SwiperList,
    Message,
  },
  data() {
    return {
      dialogTableVisible: false, //询盘弹出层
      info: {}, //页面数据
      FavoritesActive: false, //收藏选中
      rightNav: false, //右侧按钮
      Method: {
        //邮寄方式
        1: "supply.pickup",
        2: "supply.Delivery1",
      },
      Method1: {
        //邮寄方式
        1: "supply.pickup",
        2: "supply.Delivery1",
      },
      failurestate: true, //修改页面状态
      swiperList: [], //轮播图数据
      share: {
        //分享参数
        url: "www.feijiu.net",
        text: "测试title",
      },
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleThrottledScroll);
    this.gerInfo();
    this.getFavorite();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleThrottledScroll);
  },
  methods: {
    openNewPage(url) {
      window.open(url, "_blank");
    },
    // 弹出层
    Supplier() {
      if (getToken()) {
        this.dialogTableVisible = true;
      } else {
        this.$router.push("/login/signIn");
      }
    },
    chat() {
      this.$message("The function will be launched soon, please wait.");
      // if (getToken()) {

      // } else {
      //   this.$router.push("/login/signIn");
      // }
    },
    handleClose() {
      this.dialogTableVisible = false;
    },

    // 收藏
    collect() {
      if (getToken()) {
        this.FavoritesActive = !this.FavoritesActive;
        sendFavorite({
          productCode: this.$route.query.productCode,
        }).then((res) => {});
      } else {
        this.$router.push("/login/signIn");
      }
    },
    // 返回顶部
    scrollToTop: throttle(function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100),
    //滚动条
    handleThrottledScroll: throttle(function () {
      const scrolledDistance = window.scrollY;
      if (scrolledDistance >= 400) {
        this.rightNav = true;
      } else {
        this.rightNav = false;
      }
    }, 100), // 设置节流时间为 100ms
    // 分享
    shareFacebook() {
      this.end_Share();
      // 携带参数
      function popupwindow(url, title) {
        return window.open(
          "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
          +"&t=" + encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share.url, this.share.text);
    },

    shareTwitter() {
      this.end_Share();
      //携带参数
      function popupwindow(url, title) {
        return window.open(
          "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share.url, this.share.text);
    },

    shareInstagram() {
      this.end_Share();
      //携带参数
      function popupwindow(url, title) {
        return window.open(
          "https://www.instagram.com/share?text=" +
            encodeURIComponent(url) +
            "&hashtags=" +
            encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share.url, this.share.text);
    },

    // 获取详情数据
    gerInfo() {
      // 详情接口
      gedetail({}, this.$route.query.productCode).then((res) => {
        if (res.code == "200") {
          this.info = res.data;
          this.share = {
            //分享参数
            url: this.$route.fullPath,
            text: res.data.name,
          };
        }
      });
      // 详情列表
      gedetailList({}, this.$route.query.productCode).then((res) => {
        if (res.code == "200") {
          this.swiperList = res.data.data;
        }
      });
      // 记录页面浏览时长
      sendBrownse({
        productCode: this.$route.query.productCode,
      }).then((res) => {
        if (res.code == "200") {
        }
      });
    },
    // 收藏查询
    getFavorite() {
      getFavoriteState({
        productCode: this.$route.query.productCode,
      }).then((res) => {
        if (res.code == "200") {
          this.FavoritesActive = parseInt(res.data.state);
        }
      });
    },
    // 点击分享调用接口
    end_Share() {
      sendShare({
        productCode: this.$route.query.productCode,
      }).then((res) => {});
    },
  },
};
// 节流函数
function throttle(func, delay) {
  let timer = null;
  return function () {
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, arguments);
        timer = null;
      }, delay);
    }
  };
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.page-content {
  .content_t {
    width: 100%;
    background: #fff;
    .content_t_c {
      width: $contentWidth;
      padding: 36px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .content_t_c_l {
        width: 430px;
        .share_content {
          width: 260px;
          margin: 20px auto 0;
          display: flex;
          font-size: 14px;

          .Favorites {
            width: 96px;
            border-right: 1px solid #e6e6e6;
            display: flex;
            align-items: center;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
          .active {
            i {
              color: red;
              background: red;
            }
          }
          .Share {
            i {
              font-size: 14px;
              margin-right: 4px;
            }
            align-items: center;
            margin-left: 20px;
            width: 130px;
            display: flex;
            ul {
              margin-left: 7px;
              display: flex;
              width: 71px;
              justify-content: space-between;
              li {
                cursor: pointer;
              }
            }
          }
        }
      }
      .content_t_c_r {
        width: 925px;
        .Price {
          width: 100%;
          background: #f5f7fa;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          margin-top: 15px;
          position: relative;

          p {
            color: #999999;
            font-size: 14px;
            margin-bottom: 10px;
          }
          span {
            color: #e64645;
            font-size: 24px;
            font-weight: normal;
            font-weight: bold;
          }
        }
        .specifications {
          border-bottom: 1px solid #e9eef4;
          padding-bottom: 10px;

          p {
            width: 100%;
            display: flex;
            span {
              font-size: 14px;
              color: #888888;
              width: 230px;
              display: inline-block;
              margin-top: 20px;
              img {
                display: inline-block;
                width: 32px;
                height: 32px;
                vertical-align: middle;
                object-fit: contain;
              }
              &:last-child {
                width: 500px;
                color: #000000;
              }
            }
          }

          &:nth-last-child(2) {
            padding-bottom: 0;
            border: none;
          }
        }
        .Contact_btn {
          width: 100%;
          button {
            width: auto;
            min-width: 200px;
            height: 54px;
            border: 1px solid #00b968;
            color: #fff;
            background: #00b968;
            border-radius: 27px;
            font-size: 20px;
            margin-top: 30px;
            // &:last-child {
            //   color: #00b968;
            //   background: #fff;
            // }
          }
        }
      }
    }
  }
  .content_b {
    width: $contentWidth;

    .describe {
      border-radius: 10px;
      margin-top: 20px;
      background: #fff;
      padding: 0 24px;
      box-sizing: border-box;
      overflow: hidden;
      padding-bottom: 20px;
      h2 {
        margin-top: 30px;
        color: #333333;
        font-size: 20px;
        margin-bottom: 12px;
      }
      .rich {
        width: 100%;
        display: table;
        ::v-deep iframe {
          width: 100%;
          height: 590px;
        }
      }
      img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .Publish {
      margin-top: 20px;
      width: 100%;
      height: 120px;
      background: url("@/assets/images/home/messge2.png") no-repeat center;
      background-size: 100% 100%;
      a {
        float: right;
        width: 137px;
        height: 39px;
        line-height: 39px;
        background: #ffffff;
        border-radius: 19px;
        text-align: center;
        color: #1e9654;
        font-size: 15px;
        font-weight: normal;
        margin-top: 38px;
        margin-right: 90px;
        font-weight: bold;
      }
    }
  }
}
// 状态
.failurestate {
  .Price {
    &::after {
      content: attr(text);
      background: #aeaeae;
      border-radius: 0px 10px 0px 10px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 7px 20px;
      color: #ffffff;
    }
  }
  .Contact_btn {
    button {
      background: #aeaeae !important;
      color: #ffffff !important;
      border: 1px solid #aeaeae !important;
      pointer-events: none !important;
      &:last-child {
        background: none !important;
        color: #aeaeae !important;
      }
    }
  }
}

// 右侧导航
.fast_btn {
  position: fixed;
  right: 0;
  width: 78px;
  bottom: 40%;
  z-index: 99999;
  ul {
    padding: 15px 19px;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px 0px 0px 10px;
    text-align: center;
    background: #fff;

    box-sizing: border-box;
    li {
      font-size: 12px;
      border-bottom: 1px solid #d1d1d1;
      cursor: pointer;
      padding: 10px 0;
      i {
        font-size: 20px;
      }
      span {
        display: block;
        margin-top: 5px;
      }
      &:hover {
        color: #00b968;
      }
      &:last-child {
        border: none;
      }
    }
    &:last-child {
      margin-top: 20px;
      padding-top: 6px;
      padding-bottom: 7px;
      li {
        padding-bottom: 0;
        padding-top: 0;
        span {
          font-size: 20px;
        }
      }
    }
  }
}
// 右侧底部留言
.right_message {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 188px;
  z-index: 9999;
  li {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    position: relative;
    right: -144px;
    transition: 0.6s all;
    margin-bottom: 10px;
    span {
      font-size: 16px;
    }
    div {
      i {
        font-size: 20px;
        margin-right: 9px;
        font-weight: 600;
      }
    }
    &:hover {
      right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
