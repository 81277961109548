import request from '@/utils/request'

// 查询国家列表
export function getAllCountries(data) {
    return request({
      url: '/v1/web/api/country/getAllCountries',
      method: 'post',
      data: data
    })
}
// 查询语言列表
export function getlanguages(data) {
  return request({
    url: '/v1/web/api/web/header/languages',
    method: 'GET',
    data: data
  })
}

// 查询货币列表
export function getcurrencies(data) {
  return request({
    url: '/v1/web/api/web/header/currencies',
    method: 'GET',
    data: data
  })
}

// 首页LabeIcon / search下拉
export function getHeadCategoryList(data) {
  return request({
    url: '/v1/web/api/web/category/getHeadCategoryList',
    method: 'post',
    data: data
  })
}
// 首页列表
export function Homelatests(data,type) {
  return request({
    url: '/v1/web/api/web/product/'+type+'/latests',
    method: 'post',
    data: data
  })
}

// 意见-发送反馈
export function sendFeedback(data) {
  return request({
    url: '/v1/web/api/feedback/sendFeedback',
    method: 'post',
    data: data
  })
}
// 订阅
export function subscribeLatest(data) {
  
  return request({
    url: '/v1/web/api/subscribe/subscribeLatest',
    method: 'get',
    params: data
  })
}

// 搜索框补全搜索
export function SearchAsync(data,keyword) {
  return request({
    url: '/v1/web/api/web/header/searchPre?keyword='+keyword,
    method: 'POST',
    data: data
  })
}