<template>
  <div>

    <el-pagination
      v-bind="paginationProps"
      @current-change="handleCurrentChange"
    >
      <span slot="total" class="pagination-total">共 {{ total }} 条</span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    paginationProps() {
      return {
        total: this.total,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      };
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
      // 处理页码变化的逻辑，比如请求新数据等
      // emit事件或调用父组件方法传递当前页码
      this.$emit('page-change', this.currentPage);
    },
  },
};
</script>
<style lang="scss" scoped>
    .el-pagination{
        width: 620px;
        margin: 30px auto 0;
    }
    ::v-deep .el-pagination__rightwrapper{
 
      float: left;
    }
</style>