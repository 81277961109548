<template>
    <div class="newsContent">
        <div class="cell-box" :v-if="showList" v-for="(item, index) in newsList" :key="index" @click="cellBoxClick(item)">
            <!-- 图片 -->
            <div class="img-box" v-if="item.coverImage">
                <img :src="item.coverImage" referrer="no-referrer|origin|unsafe-url"
                    referrerPolicy="no-referrer" class="img" >
            </div>
            <!-- 类型、主标题 、副标题、时间、发布者-->
            <div class="detail-box">
                <div class="type">{{item.categoryName}}</div>
                <div class="title">{{item.title}}</div>
                <div class="detailTitle">{{item.abstracts}}</div>
                <div class="date-box">
                    <img src="@/assets/images/home/news_date.png" class="dateImg" alt="">
                    <span class="dateTitle">{{item.createTime | formatDate}}</span>
                    <img src="@/assets/images/home/news_look.png" class="lookImg" alt="">
                    <span class="dateTitle">{{item.browseNum}}</span>
                </div>
                <!-- <div class="by-box">
                    <img src="@/assets/images/home/news_by.png" class="byImg" alt="">
                    <span class="dateTitle">{{item.author}}</span>
                </div> -->
            </div>
        </div>
        <div  style=" font-size: 16px;color: #666666; font-weight: 500;height:500px;text-align: center;line-height: 500px; width: 988px;" v-if="!showList">There is no news in this category.Please try another category.</div>
        <pagination
          v-if="newsList.length"
          :total="total"
          :page-size="pageSize"
          @page-change="handlePageChange"
        ></pagination>
  
    </div>
</template>
<script>


import {getNewsList} from "@/api/news";
import Pagination from "@/page/supply/components/Pagination";

import moment from "moment"; //引入moment
export default {
  props:["categoryCode"],
  
    components: {
      Pagination

    },
    data() {
        return {
            newsList:'',
            showList:true,
            pageNum:1,
            pageSize:10,
            total:0
        }
    },
    filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  watch: {
    categoryCode: {
      handler: function (newval, oldVal) {
        this.pageNum = 1
        this.getData()
     }
    },
  },
    mounted() {
      this.getData()
    },

    destroyed() {
    },
    methods: {
      getData() {
        getNewsList({
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          categoryCode:this.categoryCode,
        }).then((res) => {
          this.newsList = res.data.list;
          this.total = res.data.total
          if(this.newsList.length>0) {
            this.showList = true
          } else {
            this.showList = false

          }
        //   console.log('进入此方法',this.newsList)

        })
      },
      handlePageChange(data) {
            this.pageNum = data
            this.getData()
        },
        cellBoxClick(data) {
            this.$router.push({
              path:'/news/newsDetail',
              query:{
                id:data.id
              }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.newsContent {
    width: 910px;
    .cell-box {
        width: 910px;
        height: 291px;
        margin-bottom: 10px;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        cursor: pointer;
        .img-box {
            width: 251px;
            height: 251px;
            margin-left: 20px;
            margin-top: 20px;
            background: #ddd;
            flex-shrink: 0;
            .img {
                width: 100%;
                height: 100%;
            }

        }

        .detail-box {
            padding: 20px;
            height: 291px;
            position: relative;
            .type {
                height: 27px;
                border-radius: 19px;
                line-height: 27px;
                color: #fff;
                background: #00B968;
                display: inline-block;
                padding: 0px 12px;
            }

            .title {
                margin-top: 20px;
                font-size: 20px;
                font-family: Arial Black;
                font-weight: 600;
                color: #000000;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            .detailTitle {
                margin-top: 12px;
                font-size: 14px;
                color: #999999;
                overflow: hidden;
                display: -webkit-box;
                /* 设置最大显示行数 */
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
            .date-box {
                display: flex;
                position: absolute;
                bottom: 20px;
                height:18px ;
                .dateImg {
                    width: 18px;
                    height: 18px;
                }
                .dateTitle {
                    font-size: 14px;
                    color: #666666;
                    margin-left: 2px;
                    height: 18px;
                    line-height: 18px;
                    width: 80px;
                }
                .lookImg {
                    width: 18px;
                    height: 18px;
                    margin-left: 40px;
                }

            }
            .by-box {
                position: absolute;
                height: 18px;
                // width: 100px;
                bottom: 20px;
                display: flex;
                .byImg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    

}</style>
