<template>
    <div class="container">
        <img class="logo" src="@/assets/images/login/logo.png" alt="">
        <AccountStep1 v-show="this.$route.path === '/login/creatAccount/creat'" @next="next" />
        <AccountStep2 v-show="this.$route.path === '/login/creatAccount/verify'" @register="register"
            @sendCode="sendCode" />
        <!-- 底部文案 -->
        <div class="bottom-box">@2023, Feijiu.com. All
            rights reservea.</div>


    </div>
</template>
<script>
// 页面组件
import AccountStep1 from './components/accountStep1.vue'
import AccountStep2 from './components/accountStep2.vue'
// 接口相关
import { mapState, mapMutations } from "vuex"; //定义
import { sendCaptcha, registerApi } from '@/api/login'
// import {  } from '@/api/login'
import { Loading } from 'element-ui';
import {inputMethod} from "@/utils/validate";
import { setToken,setNickname} from '@/utils/cookie'


export default {
    components: {
        AccountStep1, AccountStep2
    },
    computed: {
        ...mapState("home", ["userparm"]), //data
    },
    data() {
        return {
            // 默认为第一步
            form: {},
            options: {
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            }

        }
    },
    mounted() {
        // 判断当前路由是否
        if (this.$route.path != '/login/creatAccount/verify' && this.$route.path != '/login/creatAccount/creat') {
            this.$router.push('/')
        }
    },
    destroyed() {
    },
    methods: {
        ...mapMutations("home", ["setUser"]), //方法

        next(form) {
            // 增加loading
            Loading.service(
                this.options
            );
            this.setUser(form);
            var that = this
            let loadingInstance = Loading.service(this.options);

            //发送验证码
            sendCaptcha({
                'email': form.email,
                'nickName': form.nickName,
                'password': form.password,
                'passwordTwc': form.passwordTwc,
                'strength':inputMethod(form.password).grade
            }).then((res) => {
                // console.log('发送验证码输出内容', res)
                loadingInstance.close();
                if (res.code == '200') {
                    // this.setUser(that.form);
                    this.$router.push({
                        path: '/login/creatAccount/verify',
                        // query: {
                        //     oneParm:JSON.stringify(that.form)
                        // }
                    })
                }

            })
        },
        // 再次发送验证码
        sendCode() {
            sendCaptcha({
                'email': this.userparm.email,
                'nickName': this.userparm.nickName,
                'password': this.userparm.password,
                'passwordTwc': this.userparm.passwordTwc,
                'strength':inputMethod(this.userparm.password).grade
            }).then((res) => {
                if (res.code == '200') {

                }
                // else {
                //     this.$message({
                //         message: res.message,
                //         type: "warning",
                //     });
                // }
            })
        },

        // 注册接口
        register(msmCode) {
      
            var that = this
            registerApi({
                'email': this.userparm.email,
                'captcha': msmCode

            }).then((res) => {
                if (res.code == '200') {
                  
                    // that.$router.push('/login/signIn')
                    var data = res.data
                    let token = data.token
                    // 缓存用户名称
                    var nickName =  data.nickName
                    setNickname(nickName)
                    setToken(token)
                    this.$router.push('/')
                    that.$message({
                        message: that.$t('LoginTips.registerSuccess'),
                        type: "success",
                    });
                }

            })
        },
    }
}

</script>
 
<style lang="scss" scoped>
.container {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    //纵向排列
    flex-direction: column;
    //居中
    align-items: center;

    //logo
    .logo {
        margin-top: 160px;
        width: 152px;
        height: 44px;

    }

    //底部文案
    .bottom-box {
        width: 100%;
        height: 75px;
        line-height: 75px;
        text-align: center;
        background: #F4F4F4;
        color: #666666;
    }

}
</style>
