<template>
  <div class="Search">
    <el-autocomplete
      v-model="input"
      :fetch-suggestions="querySearchAsync"
      :placeholder="$t('home.SearchVal')"
      @select="handleSelect"
      class="input-with-select"
      :popper-class="noData ? 'platform-auto-complete' : ''"
    >
      <el-select
        v-model="select"
        slot="prepend"
        :placeholder="$t('Help.search.all')"
        class="with-select"
        @change="optionSelect"
      >
        <el-option :label="$t('Help.search.all')" value=""></el-option>

        <el-option
          v-for="item in optionVal"
          :key="item.code"
          :label="$getName(item)"
          :value="item.code"
        ></el-option>
      </el-select>

      <el-button
        slot="append"
        icon="el-icon-search"
        class="with-button"
        @click="btnSearch"
        >{{ $t("home.Search") }}</el-button
      >
    </el-autocomplete>
  </div>
</template>
<script>
import { getHeadCategoryList, SearchAsync } from "@/api/home";
export default {
  data() {
    return {
      noData: false,
      input: this.$route.query.searchKey || "", //input数据
      select: this.$route.query.selectKey || this.$t("Help.search.all"), //select 选中参数
      optionVal: [], //select拉下数据
    };
  },

  mounted() {
    getHeadCategoryList().then((res) => {
      if (res.code == "200") {
        this.optionVal = res.data;
      }
    });
  },
    watch: {
  
    "$route.query.selectKey": {
     immediate: true,
      //监听search select 调用接口 并且赋值给分类选中
      handler(newData, loadData) {
        
        this.select = newData;
       
      },
    },
     "$route.query.select": {
     immediate: true,
      //监听search select 调用接口 并且赋值给分类选中
      handler(newData, loadData) {
        // this.input = '';
       
      },
    },
  },
  methods: {
    //点击搜索
    btnSearch() {
      if (this.input == "") {
        return this.$message({
          message: "Please enter minimum 2 characters to start your search.",
          type: "warning",
        });
      }
      console.log(this.$route.params.nav);
      var url = "";
      if (this.$route.params.nav == "Recycle") {
        url = "/supply/Recycle";
      } else {
        url = "/supply/supply";
      }
      this.$router.replace({
        path: url,
        query: {
          searchKey: this.input == "All" ? " " : this.input, //搜索参数
          selectKey: this.select, //select选中code
        },
      });
    },
    // 点击selecr
    optionSelect() {
      console.log(this.select);
    },
    // 模糊列表点击
    handleSelect(item) {
      this.$router.push({
        path: "/supply/supply/supplyDetail",
        query: {
          productCode: item.code, //页面参数
          lang: this.$store.state.lang.language.value, //语言
        },
      });
      // this.btnSearch()
    },
    querySearchAsync(queryString, cb) {
     
      if (queryString == "") {
        return cb([]);
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        SearchAsync(
          {
            categoryCode: this.select == "All" ? "" : this.select, //下拉框参数
            type: this.$route.params.nav=="Recycle" ? "demand" : 'supply', // 供应还是求购,supply|demand
          },
          queryString //搜索参数
        ).then((res) => {
          if (res.code === "200") {
            if (res.data.length) {
              cb(res.data);
              this.noData = false;
            } else {
              cb([{ value: "No matching data" }]);
              this.noData = true;
            }
          } else {
            // 请求失败，展示暂无数据
            cb([{ value: "No matching data" }]);
            this.noData = true;
          }
        });
      }, 3000 * Math.random());
    },
  },
};
</script>

<style lang="scss">
.platform-auto-complete {
  .el-autocomplete-suggestion__wrap {
    padding: 5px 0;
    ul li {
      pointer-events: none; // 阻止可点击事件
      .default {
        text-align: center;
        color: #999;
      }
      &:hover {
        background-color: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.Search {
  .input-with-select {
    height: 48px;
    box-sizing: border-box;
    border: 2px solid #00b968;
    overflow: hidden;
    border-radius: 27px;
    width: 100%;
    line-height: 47px;
    background: #00b968;
    display: flex;
    ::v-deep input {
      height: 48px;
      line-height: 48px;
      border: none;
    }

    .with-select {
      width: 120px;
      font-size: 16px;

      color: #333333;
      ::v-deep input {
        text-align: center;
      }
    }
    ::v-deep .el-input-group__append {
      border: none;
      .with-button {
        width: 158px;
        color: #fff;
        font-size: 16px;
        background: #00b968;
        height: 48px;
        border-radius: 0;
        padding: 0;
        border: none;
        box-sizing: border-box;
      }
    }
  }
}
::v-deep  .el-input__suffix{
      right: 14px !important; 
}
::v-deep .el-input-group__prepend {
  background: #fff !important;
  border: none;
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 22px;
    background: #cacaca;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translatey(-50%);
  }
}
</style>