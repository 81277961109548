import request from '@/utils/request'

// 获取分类的树形结构
export function getCategoryTree() {
  return request({
    url: '/v1/web/api/web/category/getCategoryTree',
    method: 'post'
  })
}
// 获取一级分类列表
export function getHeadCategoryList() {
  return request({
    url: '/v1/web/api/web/category/getHeadCategoryList',
    method: 'post'
  })
}


// 邮箱验证
export  function verifyCode (code) {
  return request({
    url: '/v1/web/api/web/header/verifyCode/'+code,
    method: 'get',

  })
}