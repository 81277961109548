<template>
  <!-- 滚动下来之后的Nav -->
  <div class="headt">
    <div class="headt_content">
      <div class="headt_l" @click="$router.push('/')">
        <img src="@/assets/images/login/logo.png" alt="" />
        <p>{{ $t("home.top.Enterprise") }}</p>
      </div>
      <div class="headt_c">
        <Search />
      </div>
      <div class="headt_r">
        <el-button
          type="primary"
          @click="$router.push('/publish/publishSummary')"
          >{{ $t("home.Publish") }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Search from "@/components/Search"; //搜索
export default {
  components: {
    Search,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.headt {
  .headt_content {
    width: $contentWidth;
    margin: 0 auto;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .headt_l {
      cursor: pointer;
      display: flex;
      img {
        width: 151px;
        height: 45px;

        margin-right: 12px;
      }
      p {
        position: relative;
        color: #333333;
        font-size: 16px;
        padding-left: 13px;
        font-weight: 600;
        width: 204px;
        line-height: 20px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 40px;
          background: #a4a4a4;
        }
      }
    }
    .headt_c {
      width: 647px;
    }
    .headt_r {
      button {
        border-radius: 24px;
        background: #00b968;
        width: 273px;
        height: 48px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
