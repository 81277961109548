import Cookies from 'js-cookie'

const TokenKey = 'token'
const Nickname = 'nickname'

export function getToken() {
  return Cookies.get(TokenKey)
}

//默认有效期，会话级别
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  Cookies.remove(Nickname)
  return Cookies.remove(TokenKey)
}


//缓存用户nikeName
export function setNickname(nickname) {
  return Cookies.set(Nickname, nickname)
}
// 从缓存拿用户名字
export function getNickname() {
  return Cookies.get(Nickname)
}
//移除用户名字缓存
export function removeNickname() {
  return Cookies.remove(Nickname)
}



// 缓存个人信息
export function addLocalMessage(dataMessage) {
  console.log(dataMessage)
  if (dataMessage) {

    localStorage.setItem('myMessage', JSON.stringify(dataMessage))
  }

}
// 获取个人信息
export function getMessage() {
  let getStorage = localStorage.getItem('myMessage')
  var parame = JSON.parse(getStorage ? getStorage : '{}')
  return parame
}

// 移除个人信息
export function removeMessage() {
  localStorage.removeItem('myMessage')

}