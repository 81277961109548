<template>
  <div class="Page_Content_c Location" >
    <h2>{{ $t(title) }}</h2>
    <div class="Location_Content" v-loading="loading">
      <el-checkbox-group v-model="type" @change="emitSelectedType">
        <el-checkbox v-for="(item, index) in list" :key="index" :label="item">
          <template #default>
            <span :title="$getName(item)">
              <img
                :src="$getImagePath(item.flagUrl)"
                :alt="item.flagUrl"
                v-show="item.flagUrl"
              />
              {{ $getName(item) }}
            </span>
          </template>
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>

export default {
    props:{
        title:{ 
            type:String,
        },
        list:{
            type:Array
        }
    },
    watch: { 
        list: {
            handler(newData,oldData){
               if(newData.length) this.loading=false
            },
            immediate: true, 
        }
    },
  data() {
    return {
      type: [], //选中
      loading:true, //初始化
    };
  },

  methods: {
    emitSelectedType() {
        this.$emit('Ck_Box', this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.Page_Content_c {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  h2 {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 16px;
    margin-bottom: 15px;
    color: #333333;
    font-size: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.Location {
  .Location_Content {
    height: 198px;
    overflow: auto;
    label {
      display: block;
      color: #333333;
      font-size: 12px;
      margin-bottom: 5px;
      width: 100%;
      overflow: hidden;
    }
    img {
      width: 15px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>