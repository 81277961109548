<template>
    <!-- 输入框内容 -->
    <div class="content">
        <div class="title">{{ $t('Password.creatNewPassword') }}</div>
        <!-- 首次输入密码 -->
        <div class="account">{{ $t('Password.newPassword') }}</div>
        <el-input v-model="pwd"  :type="showPassword1 ? 'password' : 'text'" :placeholder="$t('Register.passwordTips')">
            <img slot="suffix" class="el-input__icon" :src="showPassword1 ? require('@/assets/images/login/eye.png')
                        : require('@/assets/images/login/eye1.png')"
                        style="width: 24px;height: 24px; margin-right: 10px;margin-top: 15px;cursor: pointer;"
                        @click="handleToggleShowPwd1"></img>
        </el-input>
        <!-- 校验密码 -->
        <div class="account">{{ $t('Register.passwordTry') }}</div>
        <el-input v-model="pwdTwc" :type="showPassword2 ? 'password' : 'text'" :placeholder="$t('Register.passwordTips')">
            <img slot="suffix" class="el-input__icon" :src="showPassword2 ? require('@/assets/images/login/eye.png')
                        : require('@/assets/images/login/eye1.png')"
                        style="width: 24px;height: 24px; margin-right: 10px;margin-top: 15px;cursor: pointer;"
                        @click="handleToggleShowPwd2"></img>
                    </el-input>
        <el-button type="primary" class="continueBtn" @click="threeClick" round>{{ $t('Password.saveAndLogin') }}</el-button>
    </div>
</template>
<script>


export default {
    components: {

    },
    data() {
        return {
            step: 1,
            pwd: '',
            pwdTwc: '',
            showPassword1: true,
            showPassword2: true



        }
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
        threeClick() {

            if (this.pwd.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.passwordTips'),
                    type: "warning",
                });
            } else if (this.pwdTwc.length == 0) {
                this.$message({
                    message: this.$t('LoginTips.passwordAgain'),
                    type: "warning",
                });
            } else if (this.pwdTwc != this.pwd) {
                this.$message({
                    message: this.$t('LoginTips.passwordWrong'),
                    type: "warning",
                });
            } else if (this.pwdTwc.length < 6 || this.pwd.length < 6) {
                this.$message({
                    message: this.$t('Register.passwordTips'),
                    type: "warning",
                });
            } else {
                this.$emit('threeClick', { pwd: this.pwd, pwdTwc: this.pwdTwc })
            }


        },
        handleToggleShowPwd1() {
            this.showPassword1 = !this.showPassword1;
        },
        handleToggleShowPwd2() {
            this.showPassword2 = !this.showPassword2;
        }
    }
}

</script>
 
<style lang="scss" scoped>
.content {
    width: 470px;
    margin-top: 31px;
    background: linear-gradient(192deg, #E7FFF5 0%, #FFFFFF 100%);
    border-radius: 10px;
    border: 1px solid #DDDDDD;
    margin-bottom: 451px;

    .title {
        font-size: 28px;
        font-family: Arial, Arial;
        font-weight: normal;
        color: #000000;
        line-height: 32px;
        margin-top: 30px;
        margin-left: 40px;
    }

    .account {
        font-size: 14px;
        font-family: Arial, Arial;
        font-weight: normal;
        color: #333333;
        line-height: 16px;
        margin-top: 30px;
        margin-left: 40px;
    }


    .continueBtn {
        width: 390px;
        height: 48px;
        margin-left: 40px;
        margin-top: 30px;
        background: #00B968;
        margin-bottom: 30px;
    }

}

// 做输入框穿透效果 设置输入框frame
::v-deep .el-input__inner {
    width: 390px;
    height: 48px;
    margin-left: 40px;
    margin-top: 10px;
}

::v-deep .el-input__suffix {
    // background-color: red;
    margin-right: 35px;
    margin-top: 5px;
}
</style>
