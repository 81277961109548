import axios from 'axios'
import {
  Message
} from 'element-ui'
import {
  getToken
} from '@/utils/cookie'
import md5 from 'js-md5'
import {
  Base64
} from 'js-base64'
import {
  Encrypt
} from '@/utils/aes'
import {
  rsaEncrypt
} from '@/utils/rsa'
import qs from 'qs'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['Accept-Language'] = 'zh-CN'

// 取值
const storedState = localStorage.getItem('store');
if (storedState) {
  store.replaceState(Object.assign({}, store.state, JSON.parse(storedState)));
}


axios.defaults.headers['Biz-Lang'] = store.state.lang.language.value
axios.defaults.headers['Biz-Currency'] = store.state.lang.currency.code
axios.defaults.headers['Biz-ShipTo'] = store.state.lang.nation.code

// console.log('接口地址',process.env)
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 10000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      //是post提交
      if (config.headers['imageType']) {
        config.headers['Content-Type']='multipart/form-data;charset=utf-8'


        //图片或文件格式，不做处理
        if (getToken()) {
          //如果有userUuid,那么加到业务参数中
          config.headers['X-Access-Token'] = getToken()
        }
      } else {
        //json数据，加验签
        var oldData = {}
        if (config.data) {
          oldData = qs.parse(config.data)
        }
        if (getToken()) {
          //如果有userUuid,那么加到业务参数中
          config.headers['X-Access-Token'] = getToken()
        }
        // console.log('参数',oldData)
        //获取当前时间戳
        var timestamp = new Date().getTime()
        //生成AES Key
        var aesKey = Base64.encode('jupai' + timestamp)
        //业务参数aes加密
        var reqContent = encodeURIComponent(Encrypt(JSON.stringify(oldData), aesKey))
        //md5签名
        var md5Sign = md5(reqContent).toUpperCase()
        //UrlDecode 解密公钥
        var rsaSign = encodeURIComponent(rsaEncrypt(aesKey))
        //参数
        var params = {
          //  version: process.env.VUE_APP_VERSION,
          osType: process.env.VUE_APP_OSTYPE,
          reqContent: reqContent,
          md5Sign: md5Sign,
          rsaSign: rsaSign,
          timeStamp: timestamp,
          // // 语言
          // langs:store.state.lang.language.value,
          // // 货币
          // currency:store.state.lang.currency.text,
          // // 国家
          // nation:store.state.lang.nation.value,

        }
        // var payload = Object.assign({}, params, config.data);
        config.data = params

      }
    }else{
      if (getToken()) {
        //如果有userUuid,那么加到业务参数中
        config.headers['X-Access-Token'] = getToken()
      }
    }

    return config
  },
  (error) => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // loadingInstance.close()
    Message.closeAll()

    if (response.status == 200) {
      if (response.data.code == 200) {
        return response.data
      } else if (response.data.code == 555 || response.data.code == 401) {
        return response.data
      } else {
        // 在预渲染环境下不显示警告消息
        if (!window.__PRERENDER_INJECTED) {
          Message({
            message: response.data.message,
            type: "warning"
          })
        }
        return response.data
      }
    } else {
      return Promise.reject('error')
    }
  },
  (error) => {
    Message.closeAll()
    console.log('err' + error) // for debug

    // 在预渲染环境下只处理超时错误
    if (!window.__PRERENDER_INJECTED && (error.message.includes('timeout') || error.code === 'ERR_NETWORK')) {
      Message.error('The request timed out. Please try again later')
    }

    return Promise.reject(error)
  }
)

export default service
