import store from '@/store';



// 判断是哪国语言显示相对应的文案
export function getName(item) {
  let languageName = "";
  switch (store.state.lang.language.value) {// 默认使用英语名称
    case "En":
      languageName = item.nameEn;
      break;
    case "De":
      languageName = item.nameDe;
      break;
    case "Es":
      languageName = item.nameEs;
      break;
    case "Fr":
      languageName = item.nameFr;
      break;
    case "Pt":
      languageName = item.namePt;
      break;
    case "Ru":
      languageName = item.nameRu;
      break;
    case "Tr":
      languageName = item.nameTr;
      break;
    case "Zh":
      languageName = item.nameZh;
      break;
    default:
      languageName = item.nameEn;
      break;
  }
  return languageName;
}


// 根据传进来的参数展示对应的国旗
export function getImagePath(filename) {

  var Url;
  if (filename != "") {
    Url = require("@/assets/images/flagNew/" + filename);
  } else {
    // 如果传进来的是个空展示这个国家的国旗 需要后期弄一个默认国旗
    Url = require("@/assets/images/flagNew/PNG.png");
  }

  return Url;
}
