<template>
  <!-- head -->
  <div class="head">
    <div class="top">
      <div class="headt_l">
        <img src="@/assets/images/home/logo.png" alt="" />
        <p>{{ $t("home.top.Enterprise") }}</p>
      </div>
      <!-- <img src="@/assets/images/home/logo.png" alt="logo" class="logo" /> -->
      <div class="right">
        <ul>
          <li>
            <img src="@/assets/images/home/user.png" alt="user" width="20" height="20"  />
            <div style="max-width:150px">
              <userSign />

            </div>
          </li>
          <li>
            <img src="@/assets/images/home/lang.png" alt="lang" width="20" height="20" />
            <div><language /></div>
          </li>
          <li>
            <div>
              <Currency />
            </div>
          </li>
          <!-- <li>
            <img src="@/assets/images/home/location.png" alt="location"  width="20" height="20" />
            <div>
              <Nation />
            </div>
          </li> -->
        </ul>
        <div class="issue"  @click="$router.push('/publish/publishSummary')">{{ $t("home.Publish") }}</div>
      </div>
    </div>
    <!--  nav列表 -->
    <div class="button">
      <ul>
        <li
          :class="{ active: navActive === index }"
          v-for="(item, index) in NavList"
          :key="item.value"
          @click="handleNavClick(index)"
        >
          {{ $t(item.name) }}
        </li>
        <!-- <li >Supplies</li>
        <li>Procurement Requirem</li>
        <li>Help Center</li>
        <li>News</li>
        <li>Exhibition</li>
        <li>About Us</li> -->
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import userSign from "@/components/userSign.vue"; //注册登录
import language from "@/components/language.vue"; //語言切換
import Currency from "@/components/currency"; //货币
import Nation from "@/components/nation"; //国家
export default {
  components: {
    userSign,
    language,
    Currency,
    Nation,
  },
  computed: {
    ...mapState({
      NavList: (state) => state.home.NavList,
      navActive: (state) => state.home.navActive,
    }),
  },
  watch: {
    "$route.path": {
      handler: function (newVal, oldVal) {
        let index = this.NavList.findIndex((item) => {
          return item.value === newVal;
          // return item.value === newVal.split('/')[1]
        });
        if (index === -1) {
          if (this.$route.path == "/supply/Recycle/supplyDetail") {
            index = 2;
            return;
          }
          if (this.$route.path == "/supply/supply/supplyDetail") {
            index = 1;
            return;
          }
          // index = 0
        }
        this.setNavActive(index);
      },
      immediate: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("home", ["setNavActive"]),
    handleNavClick(index) {
      this.setNavActive(index);

      console.log(index);
      this.$router.push(this.NavList[index].value);
    },
  },
};
</script>
<style lang="scss" scoped >
@import "@/styles/variables";

.head {
  width: 1380px;
  margin: 21px auto 0;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headt_l {
      cursor: pointer;
      display: flex;
      img {
        width: 124px;
        height: 36px;
        margin-right: 12px;
      }
      p {
        position: relative;
        color: #fff;
        font-size: 16px;
        padding-left: 13px;
        font-weight: 600;
        width: 204px;
        line-height: 18px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 36px;
          background: #a4a4a4;
        }
      }
    }

    .right {
      width: 701px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        width: 380px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          display: flex;
          color: #fff;
          cursor: pointer;
          align-items: center;
          img {
            display: inline-block;
            vertical-align: bottom;
            margin-right: 6px;
          }
        }
      }
      .issue {
        width: 273px;
        height: 48px;
        line-height: 48px;
        background: #00b968;
    border-radius: 24px;
        text-align: center;
        font-size: $primaryTextSize;
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
  .button {
    margin-top: 30px;
    color: #ffffff;
    font-size: $primaryTextSize;
    text-align: right;
    ul {
      li {
        display: inline-block;
        margin-left: 40px;
        cursor: pointer;
        &.active {
          color: $importantColor;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
